import * as React from "react";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {Ticket} from "../../../models/ExperienceModel";
import getSymbolFromCurrency from "currency-symbol-map";
import {isNumber} from "util";
interface IProps {
    showAnimation?: boolean;
    experienceType?: string;
};

export default function (props: IProps) {
    const {expState, setExpState} = useContext(ExperienceContext);
    const {t} = useTranslation();
    const addTicket = (ticket: Ticket) => {
        if (canBookMore()) {
            const ticketIndex = expState.tickets.findIndex(x => x.id == ticket.id);
            if (ticketIndex >= 0) {
                const tickets = expState.tickets;
                tickets[ticketIndex].amountToBook += 1;
                setExpState({
                    ...expState,
                    tickets
                })
            } else {
                const tickets = expState.tickets;
                tickets.push({...ticket, amountToBook: 1})
                setExpState({
                    ...expState,
                    tickets: tickets
                })
            }
        }
    };
    const minusTicket = (ticket: Ticket) => {
        const ticketIndex = expState.tickets.findIndex(x => x.id == ticket.id);
        if (ticketIndex >= 0) {
            const tickets = expState.tickets;
            tickets[ticketIndex].amountToBook -= 1;
            if(tickets[ticketIndex].amountToBook == 0) {
               tickets.splice(ticketIndex, 1)
            }
            setExpState({
                ...expState,
                tickets
            })
        }
    };
    const ticketsToBook = (ticketId: string): number => {
        const ticketIndex = expState.tickets.findIndex(x => x.id == ticketId);
        if (ticketIndex >= 0) {
            return expState.tickets[ticketIndex].amountToBook;
        }
        return 0;
    };
    const totalPrice = (ticketId: string): number => {
        const ticketIndex = expState.tickets.findIndex(x => x.id == ticketId);
        if (ticketIndex >= 0) {
            return expState.tickets[ticketIndex].price * expState.tickets[ticketIndex].amountToBook;
        }
        return 0;
    };
    const canBookMore = (): boolean => {
        if (!expState.experience.maxParticipant || expState.experience.maxParticipant < 0) {
            return true;
        }
        let totalTickets = expState.tickets.reduce((a, b) => {
            return a + b.amountToBook;
        }, 0);

        if(expState.activityBooking.activityDate) {
            totalTickets += expState.activityBooking.activityDate.bookingsNumber;
        }

        return totalTickets < expState.experience.maxParticipant;
    };
    const goBack = () => {
        setExpState({
            ...expState,
            activityBooking: {...expState.activityBooking, activityDate: undefined},
            tickets: []
        })
    };

    let ticketsPrice = 0;
    const getTicketsPrices = () => {
        expState.experience.tickets.forEach((x: Ticket) => {
            ticketsPrice += (x.price || 0);
        })
    };
    getTicketsPrices();

    return (
        <div className='booking-tickets'>
            <div className="flex fl-direction-ro fl-justify-between fl-wrap">
                <div className='booking-tickets__header'>
                    <div className="booking-tickets__header--title">
                        <h3>{t('services.and.activities.tickets')}</h3>

                        <div className={'line'}></div>

                        <div className={'ticket-info'}>{t('services.and.activities.select.tickets')}</div>
                    </div>
                </div>
            </div>

            {
                expState.experience.tickets.map((ticket, index) => {
                    let [readMoreDescription, setReadMoreDescription] = useState(ticket.translation.description.length > 135);
                    return (
                        <div key={`ticket-${index}`} className={`booking-ticket ${ticketsToBook(ticket.id) > 0 ? 'booking-ticket__active-border' : ''}`}
                             style={{
                                 borderColor: expState.buttonColor && ticketsToBook(ticket.id) > 0 ? `var(--link-color)` : '',
                             }}>

                            <span className={`booking-ticket__before-border ${ticketsToBook(ticket.id) > 0 ? 'active' : ''}`}
                                  style={{
                                      background: expState.buttonColor ? `var(--link-color)` : '',
                                      color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                                  }}
                            ></span>

                            <div className="booking-ticket__title">
                                {ticket.translation.name}
                            </div>
                            <div className={`booking-ticket__description ${!readMoreDescription ? 'more' : ''}`} dangerouslySetInnerHTML={{ __html: readMoreDescription ? ticket.translation.description.substring(0, 135) + '...' : ticket.translation.description}}></div>

                            {
                                ticket.translation.description.length > 135 &&
                                <div className={'more-btn-wrapper'}>
                                    <button onClick={() => setReadMoreDescription(!readMoreDescription)} className={`${!readMoreDescription ? 'less' : ''}`}>
                                        { readMoreDescription ? t('read.more') : t('read.less')}
                                    </button>
                                </div>
                            }

                            <div className='booking-ticket__bottom' style={{marginTop: !readMoreDescription ? '40px' : ''}}>
                                <div className="booking-ticket__bottom-options" id="parent-dom-id">
                                    {/*TBD*/}
                                    {/*<div className={props.showAnimation || true ? "animation-tickets" : ''}*/}
                                    {/*     style={{*/}
                                    {/*         borderColor: expState.buttonColor ? `var(--link-color)` : '',*/}
                                    {/*     }}/>*/}

                                    <a onClick={() => minusTicket(ticket)} className="ticket-icons ticket-icons__remove material-icons">remove</a>

                                    <span className="booking-ticket__bottom-ticket-count">{ticketsToBook(ticket.id)}</span>

                                    <a
                                        onClick={() => addTicket(ticket)}
                                        style={{
                                            background: expState.buttonColor ? `var(--link-color)` : '',
                                            color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                                        }}
                                        className="ticket-icons material-icons">add</a>
                                </div>

                                <div className="booking-ticket__bottom-infos fl-direction-co">
                                    {
                                        (ticket.price || 0) > 0 &&
                                        <div className="flex">
                                            <div className="booking-ticket__bottom-item-price">
                                                <span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>
                                                {(ticket.price?.toFixed(2) || 0)}
                                            </div>

                                            {/*<div className="mrr-20px">*/}
                                            {/*    <div className="booking-ticket__bottom-item-title">{t('price')}</div>*/}
                                            {/*    <div className="booking-ticket__bottom-item-price"><span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>{(ticket.price?.toFixed(2) || 0)}</div>*/}
                                            {/*</div>*/}

                                            {/*<div>*/}
                                            {/*    <div className="booking-ticket__bottom-item-title">{t('total')}</div>*/}
                                            {/*    <div className="booking-ticket__bottom-item-price"><span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>{(totalPrice(ticket.id)?.toFixed(2) || 0)}</div>*/}
                                            {/*</div>*/}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};
