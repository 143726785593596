import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";
import {Breadcrumbs} from "models/portal/Portal";


const initialState: Breadcrumbs = {} as Breadcrumbs;

export default (state = initialState, action: PortalAction) => {
    switch (action.type) {
        case PortalActionTypes.PUT_BREADCRUMBS:
            const breadcrumb = (action.payload as Breadcrumbs)

            return { ...breadcrumb };
        default:
            return state;
    }
}
