import * as React from "react";
import { useState } from "react";
import {match, useHistory} from "react-router";
import {Location} from "history";
import {connect, useDispatch, useSelector} from "react-redux";
import PersonsColumn from "../components/profile-page/PersonsColumn";
import {People} from "../models/People";
import {Link, useLocation} from "react-router-dom";
import {toSeoUrl} from "../helpers/GeneralHelper";
import {ExperienceMini, ExperienceModel, Ticket} from "../models/ExperienceModel";
import {getLocale} from "../store/app/selectors";
import "../assets/styles/home-page.css"
import "../assets/styles/experience.css"
import "../assets/styles/guide.css"
import "../assets/css/helpers.css"
import {useEffect} from "react";
import moment from "moment";
import {fetchGuideService} from "../services/ExperienceService";
import {fetchExperienceAction} from "../store/actions/ExperienceActions/ExperienceActionCreators";
import {getQueryParam} from "../helpers/ExperienceHelper";
import {Helmet} from "react-helmet-async";
import Loader from "../components/common/Loader";
import {HotelVisited2, PeopleVisited} from "../services/GigglyticsService";
import {fetchGuideAction} from "../store/actions/GuideActions/GuideActionCreators";
import {ActivityBooking, BookingForm, ResourceBooking} from "../ExperienceContext";
import {useTranslation} from "react-i18next";
interface IGuideProps {
    guide: People
    match: match,
    location: Location
};
function Guide(props: IGuideProps) {
    const {t} = useTranslation();
    const locale = useSelector(getLocale);
    const [experiencesShown, setExperiencesShown] = useState(6);
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false
    });
    const [guideState, setGuideState] = useState({
        guide: props.guide,
        activityBooking: {} as ActivityBooking,
        resourceBooking: {} as ResourceBooking,
        tickets: [] as Ticket[],
        bookingForm: {} as BookingForm,
        redirectTo: getQueryParam(props.location.search, 'redirectTo'),
        buttonColor: getQueryParam(props.location.search, 'buttonColor'),
        fontColor: getQueryParam(props.location.search, 'fontColor'),
        whiteBg: getQueryParam(props.location.search, 'whiteBg'),
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const locationObj = useLocation();

    const getLocation = () => {
        if(__BROWSER__) {
            return location;
        }

        return locationObj;
    }
    const showMoreExperiencesShown = () => {
        setExperiencesShown(experiencesShown + 4);
    };
    // const staffClicked = (person: any) => {
    //     setState(person);
    //     setPersonClicked(true);
    // };

    useEffect(() => {
        moment.locale(locale);
        const loadGuide = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });

            const guideResult: any = await fetchGuideService((props.match.params as any).guideId, locale);
            if (guideResult === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (guideResult === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                dispatch(fetchGuideAction(guideResult));
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        dispatch(fetchExperienceAction({} as ExperienceModel));

        if (!state.loading && Object.keys(props.guide).length == 0 && __BROWSER__) {
            loadGuide();
        }

        if (Object.keys(props.guide).length > 0 && (props.match.params as any).guideId != props.guide.id && !state.loading) {
            dispatch(fetchGuideAction({} as People));
        }
    }, [props.guide, props.match.params]);

    const goBack = () => {
        if (guideState.redirectTo) {
            const divId = getQueryParam(props.location.search, 'divId');
            location.assign(guideState.redirectTo + `#${divId}`)
        } else {
            dispatch(fetchExperienceAction({} as ExperienceModel));
            if (props.location.state) {
                history.goBack();
            } else {
                const hotelCodeQueryParam = getQueryParam(props.location.search, 'hc');
                history.push({
                    pathname: `${locale == 'en' ? '' : '/'+locale}/${hotelCodeQueryParam ? hotelCodeQueryParam : props.guide.hotel.code}`,
                });
            }
        }
    };

    if ((Object.keys(props.guide).length == 0 || (props.match.params as any).guideId != props.guide.id || state.loading) && !state.notFound && !state.error) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.notFound) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Experience Not Found`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Guide Not Found</h1>
            </div>
        )
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Hotel Data</h1>
            </div>
        )
    }

    if(__BROWSER__) {
        const hc = getQueryParam(props.location.search, 'hc');
        PeopleVisited(props.guide.id, hc as any);
        if (hc) {
            HotelVisited2(hc);
        }
    }

    const leftPersonArray = [];
    const rightPersonArray = [];

    if(props.guide.relatedPeople) {
        for(let i = 0; i < (props.guide.relatedPeople as People[]).length; i++) {
            if (i % 2 == 0) {
                leftPersonArray.push((props.guide.relatedPeople as People[])[i]);
            } else {
                rightPersonArray.push((props.guide.relatedPeople as People[])[i]);
            }
        }

    }
    return (
        <div className="guide-page">
            <div className="profile__left-section">
                <div className="profile__image-container">
                    <div
                        className="profile__blur-img"
                        style={{ backgroundImage: `url('${props.guide.profilePicture}')` }}/>

                    <div className="profile__main-info flex fl-align-c fl-direction-co">
                        <div className="profile__profile-picture">
                            <img src={props.guide.profilePicture} alt="" />
                            <h3>{`${props.guide.firstName} ${props.guide.lastName}`}</h3>
                            {
                                props.guide.translation && props.guide.translation.title &&
                                <p className="profile__job-title">{props.guide.translation.title}</p>
                            }
                        </div>

                        <div
                            className={props.guide.totalExperiences > 0 ? "profile__more-info flex fl-align-c" : "d-none"}>
                            <div className="profile__transparent-logo">
                                <img
                                    src={
                                        "https://giggle-images.s3.eu-central-1.amazonaws.com/static-images/logo-bg-transparent.png"
                                    }
                                    alt="giggle-logo"
                                />
                            </div>
                            <p>{props.guide.totalExperiences + " " + t('amount.of.experiences')}</p>
                        </div>
                    </div>
                </div>

                <div className="profile__description">
                    <p>{props.guide.translation && props.guide.translation.description}</p>
                    {/* <button>Read more</button> */}
                </div>

                {
                    props.guide.relatedExperiences.length > 0 &&
                    <div className="related-experiences">
                        <div className="related-experiences__wrapper">
                            <h3 className="related-experience-header">
                                {t('experiences with')} {props.guide.firstName}
                            </h3>

                            <div className="related-experiences__items">
                                {
                                    props.guide.relatedExperiences.map((experience: ExperienceMini, index: number) => {
                                        if (experiencesShown < (index + 1)) return;
                                        return (
                                            <Link
                                                key={`rel-exp-${experience.id}`}
                                                to={`${locale == 'en' ? '' : '/'+locale}/experiences/uncategorized/${experience.id}/${toSeoUrl(experience.translation.title)}${getLocation().search}`}
                                                className="related-experiences__items__box">
                                                <img src={experience.imageUrl} loading={'lazy'} alt={`${experience.translation.title} image`}/>
                                                <h4 className="related-experiences__items__box--title">
                                                    {experience.translation.title}
                                                </h4>
                                                <div className="related-experiences__items__box--shadow"/>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                            {
                                props.guide.relatedExperiences.length > experiencesShown &&
                                <div className="related-experiences__wrapper__btn-wrapper">
                                    <button onClick={showMoreExperiencesShown}>{t('load.more')}</button>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

            <div className="profile__right-section flex fl-direction-co">
                <div className="staff-header">
                    <h2>{t('more.staff')}</h2>
                </div>
                <div className="persons-column-wrapper">
                    <PersonsColumn
                        key={"personColumnLeft"}
                        isLeft={true}
                        peopleList={leftPersonArray}
                    />
                    <PersonsColumn
                        key={"personColumnRight"}
                        isLeft={false}
                        peopleList={rightPersonArray}
                    />
                </div>
            </div>

            <a onClick={goBack} className="experience-close experience-close-fade">
                <span className="material-icons experience-close--button">close</span>{t('close.experience')}
            </a>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        guide: state.guide,
    }
}

export default connect(mapStateToProps)(Guide);
