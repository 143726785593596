import * as React from 'react';
import '../../assets/css/v2/masonry-card.css';
import '../../assets/css/v2/masonry-categories-card.css';
import {useTranslation} from "react-i18next";
import WormImge from './../../assets/images/portal-worm-giggle-cards.svg'

interface IMasonryCategoriesCardProps {
    experience?: any,
};

const MasonryBlueCard = (props: IMasonryCategoriesCardProps) => {
    const { t } = useTranslation();

    return (
        <div className={'masonry-card'}>
            <div className={'masonry-card__blue'}>
                <span className='masonry-card__blue__worm' style={{backgroundImage: `url('${WormImge}')`}}></span>

                <h2 className='masonry-card__blue__title'>{t('blue.box.title')}</h2>

                <p className='masonry-card__blue__description'>{t('blue.box.description')}</p>

                <a className='masonry-card__blue__link' href={t('blue.box.button.link')} target='_blank'>{t('blue.box.button.text')}</a>
            </div>
        </div>
    );
}

export default MasonryBlueCard;
