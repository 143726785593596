import axios, {AxiosError} from "axios";
import {API} from "../config";

export const fetchWeeklyExperiences = async (
    hotelCode: string,
    languageCode: string,
    startDate: string,
    endDate: string,
    streamId?: string,
    dispatch?: any
) => {

    try {
        const params: any = {
            hotelCode,
            languageCode,
            startDate,
            endDate,
            streamId,
        };

        const response = await axios.get(`${API}/api/v2/stream/weekly/experiences`, {
            params
        });

        if (dispatch) {
            // dispatch(fetchHotelAction(response.data))
        } else {
            return response.data;
        }
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    // dispatch(hotelNotFoundAction())
                }
            }
            return error.response.status;
        }
        if (dispatch) {
            // dispatch(hotelLoadingErrorAction())
        }
    }
};

export const fetchWeeklyLikeStreams = async (
    hotelCode: string,
    languageCode: string,
    startDate: string,
    endDate: string,
    dispatch?: any
) => {

    try {
        const params: any = {
            hotelCode,
            languageCode,
            startDate,
            endDate,
        };

        const response = await axios.get(`${API}/api/v2/stream/weeklies/experiences/app`, {
            params
        });

        if (dispatch) {
            // dispatch(fetchHotelAction(response.data))
        } else {
            return response.data;
        }
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    // dispatch(hotelNotFoundAction())
                }
            }
            return error.response.status;
        }
        if (dispatch) {
            // dispatch(hotelLoadingErrorAction())
        }
    }
};

export const fetchStreamService = async (streamId: string, languageCode: string, offset:number, limit:number) => {
    try {
        const response = await axios.get(`${API}/api/v2/stream/${streamId}/experiences`, {
            params: {
                languageCode,
                offset,
                limit,
            }
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError) {
            let errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    return 404;
                }
            }
        }
        return 500;
    }
};
