import * as React from "react";
import HotelInformations from "./HotelInformations";
import {useTranslation} from "react-i18next";
import {People} from "../../models/People";
import {useSelector} from "react-redux";
import {getLocale} from "../../store/app/selectors";

interface IHomeHeaderProps {
    hotelCode: string,
    hotelName: string,
    hotelColor: string,
    peopleList: People[],
    coverImageUrl?: string
};

export default function (props: IHomeHeaderProps) {
    const { t } = useTranslation();
    const locale = useSelector(getLocale);

    return (
        <div
            style={{ backgroundImage: `url('${props.coverImageUrl || ''}')` }}
            className='home-page__background'>
            <div className='home-page__background-shadow' />

            <HotelInformations t={t} hotelName={props.hotelName} hotelCode={props.hotelCode} locale={locale} peopleList={props.peopleList} hotelColor={props.hotelColor}/>
        </div>
    );
}
