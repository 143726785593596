import * as React from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import '../../assets/css/v2/header.css';
import giggleLogo from "./../../assets/giggle-logo-rgb-lumi.svg";
import searchIcon from "./../../assets/icons/overview-page/search.svg";
import {useEffect, useState} from "react";
import {
    fetchAllCategories,
    fetchAllDestinations,
    fetchSearchByQuery,
    fetchSearchSuggestions
} from "services/PortalService";
import {getLocale} from "store/app/selectors";
import { SearchModel, RegionDestination} from "models/portal/Portal";
import {Link} from "react-router-dom";
import {fetchSearchSuggestionsAction} from "store/actions/PortalActions/PortalActions";
import {useLocation} from "react-router";
import useComponentVisible from "helpers/useComponentVisible";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";


interface IProps {
    region: RegionDestination,
    regionParam?: string,
    destinationParam?: string,
    searchSuggestions: SearchModel,
};

function Header(props: IProps) {
    const locale = useSelector(getLocale);
    const location = useLocation();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchResult, setSearchResult] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showBrowseCategories, setShowBrowseCategories] = useState<boolean>(false);
    const [allCategories, setAllCategories] = useState([]);
    const [allDestinations, setAllDestinations] = useState([]);
    const [showBrowseRegions, setShowBrowseRegions] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SearchModel | null>(null);
    const { ref, isComponentVisible: showSearch, setIsComponentVisible: setShowSearch } = useComponentVisible(false);

    useEffect(() => {
        const getSearchSuggestions = async () => {
            const searchSuggestions = await fetchSearchSuggestions(locale);

            if (searchSuggestions === 404) {}
            else if (searchSuggestions === 500) {}
            else {
                dispatch(fetchSearchSuggestionsAction(searchSuggestions as SearchModel));
            }

            setLoading(false);
        }

        if (Object.keys(props.searchSuggestions).length == 0) {
            setLoading(true);
            getSearchSuggestions();
        }

    }, [props.searchSuggestions]);

    const handleInputSearchChange = (event: any) => {
        const { value } = event.target;

        setSearchInputValue(value);

        // if (value.length >= 3) {
        //     setSearchResult(true);
        // } else {
        //     setSearchResult(false);
        // }
    };

    useEffect(() => {
        if (searchInputValue.length === 0) {
            setSearchResults(null);
        }
    }, [searchInputValue]);

    useEffect(() => {
        const doSearch = async () => {
            const searchResults = await fetchSearchByQuery(searchInputValue, locale)
            setSearchResults(searchResults as SearchModel);
        };
        const delayDebounceFn = setTimeout(() => {
            if (searchInputValue.length >= 3) {
                doSearch();
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchInputValue])

    const onShowBrowseCategories = () => {
        if (allCategories.length === 0) {
            fetchAllCategories(locale).then(res => {
                setAllCategories(res as any);
            });
        }
        setSearchInputValue('');
        setShowBrowseRegions(false)
        setShowBrowseCategories(true);
    }

    const onShowBrowseDestinations = () => {
        if (allDestinations.length === 0) {
            fetchAllDestinations(locale).then(res => {
                setAllDestinations(res as any);
            });
        }
        setSearchInputValue('');
        setShowBrowseRegions(true)
        setShowBrowseCategories(false);
    }

    const onBrowseBack = () => {
        setShowBrowseRegions(false)
        setShowBrowseCategories(false);
    }

    const handleFocus = () => {
        setShowSearch(true);
    }

    const handleFocusOut = () => {
        setShowSearch(false);
        setSearchInputValue('');
        setSearchResult(false);
    }

    const getCategoryLink = (entity: any): string => {
        return `/${locale}/experiences/c/${entity.code}`;
        if (props.regionParam && props.destinationParam) {
            return `/${locale}/experiences/r/${props.regionParam}/${props.destinationParam}/c--${entity.code}`;
        } else if (location.pathname.includes('/r/')) {
            return `/${locale}/experiences/r/${props.regionParam}/c--${entity.code}`;
        } else if (location.pathname.includes('/d/')) {
            return `/${locale}/experiences/d/${props.destinationParam}/c--${entity.code}`;
        } else {
            return `/${locale}/experiences/c/${entity.code}`;
        }
    }

    const getDestinationLink = (entity: any): string => {
        return `/${locale}/experiences/r/${entity.parentUrl}/${entity.code}`;
    }

    const getRegionDestinationImageLink = (entity: any): string => {
        const entityDetails = JSON.parse(entity.details ?? '{}');

        return entityDetails.imageUrl ?? '';
    }

    const isSectionShown = (section: string): boolean => {
        switch (section) {
            case 'loading': return !searchResults && !showBrowseCategories && !showBrowseRegions && props.searchSuggestions.categories.length == 0;
            case 'search-suggestions': return !searchResults && !showBrowseCategories && !showBrowseRegions;
            case 'browse-categories': return showBrowseCategories && !showBrowseRegions;
            case 'browse-destinations': return showBrowseRegions && !showBrowseCategories;
            case 'search-results': return !!searchResults;
            default: return false;
        }
    }

    // if (!Object.keys(props.searchSuggestions).length) {
    //     return <></>;
    // }

    return (
        <div className={'overview-page__header'} ref={ref}>
            <div className="logo">
                <a href="https://giggle.tips">
                    <img src={giggleLogo} alt=""/>
                </a>
            </div>

            <div className="search">
                <div className={'search__wrapper'}>
                    <img src={searchIcon} className={'search__icon'}/>
                    <input value={searchInputValue} className={'search__field'}
                           type="text"
                           placeholder={t('discover.experiences')}
                           onChange={handleInputSearchChange}
                           onFocus={handleFocus}
                    />

                    {
                        showSearch &&
                        <div className={'search__result'}>
                            {
                                loading &&
                                <>
                                    <div className={'search__result__section'} style={{display: 'flex', justifyContent: 'center'}}>
                                        <br/>
                                        <br/>
                                        <Loader style={{marginTop: '30px'}}></Loader>
                                    </div>
                                </>
                            }

                            {
                                (isSectionShown('search-suggestions') && !loading) &&
                                <>
                                    <div className={'search__result__section'}>
                                        <div className={'search__header'}>
                                            <h3 className={'search__result__section__title'}>{t('find.next.holiday.experience')}</h3>

                                            <a onClick={onShowBrowseCategories} className={'link'}>{t('browse.all.categories')}</a>
                                        </div>

                                        <br/>
                                        <div className={'search__result__section__list'}>
                                            {
                                                props.searchSuggestions.categories.map((category) => {
                                                    return(
                                                        <Link to={getCategoryLink(category)} className={'search__result__section__list__item'}
                                                              style={{backgroundImage: `url('${category.image}')`}}>
                                                            <span className={'title'}>{category.name}</span>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className={'search__footer'}>
                                            <a href={void(0)} onClick={onShowBrowseCategories} className={'link bottom'}>{t('browse.all.categories')}</a>
                                        </div>
                                    </div>
                                    <div className={'search__result__section'}>
                                        <div className={'search__header'}>
                                            <h3 className={'search__result__section__title'}>{t('get.inspired.with.favorie.destination')}</h3>

                                            <a onClick={onShowBrowseDestinations} className={'link'}>{t('all.destinations')}</a>
                                        </div>

                                        <br/>
                                        <div className={'search__result__section__list'}>
                                            {
                                                props.searchSuggestions.regions.map((region) => {
                                                    return(
                                                        <Link to={`/${locale}/experiences/r/${region.code}`} className={'search__result__section__list__item'}
                                                              style={{backgroundImage: `url(${getRegionDestinationImageLink(region)})`}}>
                                                            <span className={'title'}>{region.name}</span>
                                                        </Link>
                                                    )
                                                })
                                            }
                                            {
                                                props.searchSuggestions.destinations.map((destination) => {
                                                    return(
                                                        <Link to={`/${locale}/experiences/r/${destination.parentUrl}/${destination.code}`} className={'search__result__section__list__item'}
                                                              style={{backgroundImage: `url(${getRegionDestinationImageLink(destination)})`}}>
                                                            <span className={'title'}>{destination.name}</span>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className={'search__footer'}>
                                            <a href={void(0)} onClick={onShowBrowseDestinations} className={'link bottom'}>{t('all.destinations')}</a>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (isSectionShown('search-results') && !loading) &&
                                <>
                                    <div className={'search__result__section'} style={{marginTop: '24px'}}>
                                        <div className={'search__result__section__result-list'}>
                                            {
                                                searchResults.categories.map((category) => {
                                                    return(
                                                        <Link to={getCategoryLink(category)} className={'search__result__section__result-list__item'}>
                                                            <div className={'category-image'}>
                                                                <img src={category.image} alt=""/>
                                                            </div>
                                                            <div className={'title'}>
                                                                <span className={'name'}>{category.name}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                            {
                                                searchResults.regions.map((entity) => {
                                                    return(
                                                        <Link to={`/${locale}/experiences/r/${entity.code}`} className={'search__result__section__result-list__item'}>
                                                            <div className={'category-image'}>
                                                                <img src={getRegionDestinationImageLink(entity)} alt=""/>
                                                            </div>
                                                            <div className={'title'}>
                                                                <span className={'name'}>{entity.name}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                            {
                                                searchResults.destinations.map((entity) => {
                                                    return(
                                                        <Link to={getDestinationLink(entity)} className={'search__result__section__result-list__item'}>
                                                            <div className={'category-image'}>
                                                                <img src={getRegionDestinationImageLink(entity)} alt=""/>
                                                            </div>
                                                            <div className={'title'}>
                                                                <span className={'name'}>{entity.name}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }

                                            {
                                                (
                                                    searchResults.categories.length === 0
                                                    && searchResults.regions.length === 0
                                                    && searchResults.destinations.length === 0
                                                ) &&
                                                <div className={'search__result__section__result-list__no-result'}>
                                                    <span className={'search__result__section__result-list__no-result__label'}>Do you need inspiration for your next experience?</span>
                                                    <a onClick={onShowBrowseCategories} className={'search__result__section__result-list__no-result__link'}>Browse all experience categories</a>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (isSectionShown('browse-categories') && !loading) &&
                                <>
                                    <div className={'search__result__section'} style={{marginTop: '24px'}}>
                                        <div className={'search__header'}>
                                            <h3 className={'search__result__section__title'}>Giggle Categories</h3>

                                            <a onClick={onBrowseBack} className={'link'}>Show all suggestions</a>
                                        </div>
                                        <br/>
                                        {
                                            allCategories.length === 0 &&
                                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                                <br/>
                                                <Loader></Loader>
                                            </div>
                                        }
                                        <div className={'search__result__section__result-list'}>
                                            {
                                                allCategories.filter((x: any) => x.totalCount > 3).map((category) => {
                                                    return(
                                                        <Link to={getCategoryLink(category)} className={'search__result__section__result-list__item'}>
                                                            <div className={'category-image'}>
                                                                <img src={category.image} alt=""/>
                                                            </div>
                                                            <div className={'title'}>
                                                                <span className={'name'}>{category.name}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (isSectionShown('browse-destinations') && !loading) &&
                                <>
                                    <div className={'search__result__section'} style={{marginTop: '24px'}}>
                                        <div className={'search__header'}>
                                            <h3 className={'search__result__section__title'}>Giggle Destinations</h3>

                                            <a onClick={onBrowseBack} className={'link'}>Show all suggestions</a>
                                        </div>
                                        <br/>
                                        {
                                            allDestinations.length === 0 &&
                                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                                <br/>
                                                <Loader></Loader>
                                            </div>
                                        }
                                        <div className={'search__result__section__result-list'}>
                                            {
                                                allDestinations.filter((x: any) => x.totalCount > 3).map((destination) => {
                                                    return(
                                                        <Link to={getDestinationLink(destination)} className={'search__result__section__result-list__item'}>
                                                            <div className={'category-image'}>
                                                                <img src={getRegionDestinationImageLink(destination)} alt=""/>
                                                            </div>
                                                            <div className={'title'}>
                                                                <span className={'name'}>{destination.name}</span>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className={'navigation'}>
                <ul className={'nav'}>
                    <li className={'nav__item active'}>
                        <a href="https://giggle.tips/en/experiences">
                            {t('explore')}
                        </a>
                    </li>
                    <li className={'nav__item'}>
                        <a href="https://hotel.giggle.tips/">{t('sign.up')}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        region: state.region,
        searchSuggestions: state.search
    }
}

export default connect(mapStateToProps)(Header);
