import axios, {AxiosError} from "axios";
import {API} from "config";
import {
    fetchHotelAction,
    hotelLoadingErrorAction,
    hotelNotFoundAction
} from "store/actions/HotelActions/HotelsActionCreators";
import {SearchModel} from "models/portal/Portal";

export const fetchPlainHotelService = async (
    hotelCode: string,
    dispatch?: any
) => {
    try {
        const response = await axios.get(`${API}/api/hotels/by-code/${hotelCode}`);

        if (dispatch) {
            dispatch(fetchHotelAction(response.data))
        }
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (dispatch) {
            dispatch(hotelLoadingErrorAction())
        }
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    dispatch(hotelNotFoundAction())
                }
            }
            return error.response.status;
        } else {
            return 500;
        }
    }
};


export const fetchHostExperiencesService = async (hotelCode: string, languageCode: string, page:number, limit:number) => {
    try {
        const response = await axios.get(`${API}/api/portal/list/${hotelCode}`, {
            params: {
                languageCode,
                page,
                limit,
            }
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError) {
            let errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    return 404;
                }
            }
        }
        return 500;
    }
};

export const fetchExperiences = async (
    languageCode: string,
    page: number,
    limit: number
) => {
    try {
        const params: any = {
            languageCode,
            page,
            limit,
        };
        const response = await axios.get(`${API}/api/portal/experiences`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchRegionExperiences = async (
    region: string,
    languageCode: string,
    page: number,
    limit: number
) => {
    try {
        const params: any = {
            languageCode,
            page,
            limit,
        };
        const response = await axios.get(`${API}/api/portal/region/${region}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchDestinationExperiences = async (
    code: string,
    languageCode: string,
    page: number,
    limit: number,
    regionCode?: string,
) => {
    try {
        const params: any = {
            languageCode,
            page,
            limit,
        };

        if (regionCode) {
            params[regionCode] = regionCode;
        }

        const response = await axios.get(`${API}/api/portal/destination/${code}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchCategoryExperiences = async (
    code: string,
    languageCode: string,
    page: number,
    limit: number,
    regionCode?: string,
    destinationCode?: string,
) => {
    try {
        const params: any = {
            languageCode,
            page,
            limit,
        };
        if (destinationCode) {
            params[destinationCode] = destinationCode;
        }
        if (regionCode) {
            params[regionCode] = regionCode;
        }

        console.log(params)
        const response = await axios.get(`${API}/api/portal/category-experiences/${code}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchCategorySimilars = async (
    code: string,
    languageCode: string,
) => {
    try {
        const params: any = {
            languageCode
        };
        const response = await axios.get(`${API}/api/sub-category/${code}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchDestinationSimilars = async (
    code: string,
    languageCode: string,
) => {
    try {
        const params: any = {
            languageCode
        };
        const response = await axios.get(`${API}/api/destinations/regions/by-code/${code}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchRegionDestinations = async (
    code: string,
    languageCode: string,
) => {
    try {
        const params: any = {
            languageCode
        };
        const response = await axios.get(`${API}/api/regions/destinations/by-code/${code}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchRegion = async (
    code: string,
) => {
    try {
        const response = await axios.get(`${API}/api/regions/by-code/${code}`);
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchDestination = async (
    code: string,
) => {
    try {
        const response = await axios.get(`${API}/api/destinations/by-code/${code}`);
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};


export const fetchSearchSuggestions = async (
    lang: string,
): Promise<SearchModel | number> => {
    try {
        const response = await axios.get(`${API}/api/portal/suggestions?languageCode=${lang}`);
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchAllCategories = async (
    lang: string,
): Promise<any[]> => {
    try {
        const response = await axios.get(`${API}/api/portal/categories?languageCode=${lang}`);
        return response.data;
    } catch (e) {
        return []
    }
};

export const fetchAllDestinations = async (
    lang: string,
): Promise<any[]> => {
    try {
        const response = await axios.get(`${API}/api/portal/destinations?languageCode=${lang}`);
        return response.data;
    } catch (e) {
        return []
    }
};

export const fetchSearchByQuery = async (
    query: string,
    lang: string,
): Promise<SearchModel | number> => {
    try {
        const response = await axios.get(`${API}/api/portal/search/region-and-destination?q=${query}&languageCode=${lang}`);
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};
