// const dotenv = require('dotenv')

//API Configurations
// const REMOTE_HOST_DEV = "0b3n51xkya.execute-api.us-east-1.amazonaws.com/dev"
// const REMOTE_HOST_PROD = "66p6l7f98j.execute-api.us-east-1.amazonaws.com/prod"
const REMOTE_HOST_PROD = "4mosbrr6n0.execute-api.us-east-1.amazonaws.com/prod"
const LOCAL_HOST = "localhost:5000"

// const HOST = "0b3n51xkya.execute-api.us-east-1.amazonaws.com/dev";
// const HOST = "4mosbrr6n0.execute-api.us-east-1.amazonaws.com/prod";
// const HOST = "66p6l7f98j.execute-api.us-east-1.amazonaws.com/prod";
// const HOST = "localhost:5000";

// export const API = "https://" + REMOTE_HOST_DEV + "/app/"
export const API = "https://" + REMOTE_HOST_PROD
// export const API = "http://" + LOCAL_HOST ;
// export const API = process.env.HOST;


// export const CSS_LINK = "";
// export const CSS_LINK = "https://d27mylpt7bebkr.cloudfront.net/static-images/widget/dev/widget.css";
export const CSS_LINK = "https://d27mylpt7bebkr.cloudfront.net/static-images/widget/prod/widget.css";
