// @flow
import * as React from "react";

import {People} from "../../models/People";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {getLocale} from "../../store/app/selectors";
import {toSeoUrl} from "../../helpers/GeneralHelper";
import {useLocation} from "react-router-dom";

interface SinglePerson {
    peopleList: any;
    guide: People;
    bigHeightLeft: any;
    bigHeightRight: any;
    isLeft: boolean;
}

export default function (props: SinglePerson) {
    const history = useHistory();
    const locale = useSelector(getLocale);
    const locationObj = useLocation();

    const getLocation = () => {
        if(__BROWSER__) {
            return location;
        }

        return locationObj;
    }
    const staffClicked = () => {
        history.push({
            pathname: `${locale == 'en' ? '' : '/' + locale}/guides/${props.guide.id}/${toSeoUrl(props.guide.firstName + ' ' + props.guide.lastName, false)}${getLocation().search}`,
        });
    };

    return (
        <div
            style={{
                height:
                    (props.bigHeightLeft && props.isLeft) ||
                    (props.bigHeightRight && !props.isLeft)
                        ? "280px"
                        : "200px",
                marginRight: props.isLeft ? "10px" : "",
            }}
            className="profile__image-container--staff"
            onClick={staffClicked}
        >
            {
                props.guide.totalExperiences > 0 &&
                <div className="profile__nr-of-experiences">
                    <img
                        src={
                            "https://giggle-images.s3.eu-central-1.amazonaws.com/static-images/logo-bg-transparent.png"
                        }
                        alt="giggle-logo"
                    />
                    <p>{props.guide.totalExperiences}</p>
                </div>
            }

            <div className="profile__blur-img blur-image-staff"
                 style={{backgroundImage: `url('${props.guide.profilePicture}')`}}/>
            <div className="profile__main-info--staff flex fl-align-c fl-direction-co">
                <div className="profile__profile-picture--staff">
                    <img src={props.guide.profilePicture} alt=""/>
                    <h5>{`${props.guide.firstName} ${props.guide.lastName}`}</h5>
                </div>
                {
                    props.guide.translation && props.guide.translation.title &&
                    <p className="profile__job-title--smaller">{props.guide.translation.title}</p>
                }
            </div>
        </div>
    );
}
