import { ExperienceModel } from "models/ExperienceModel";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {useContext, useEffect} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {getTotalTicketsRequested} from "../../../helpers/ExperienceHelper";
interface IExperienceBookingConfirmationProps {
    experience: ExperienceModel,
    notAvailable?: boolean
};
import PoweredByLogo from '../../../assets/icons/experience-detail/giggle-poweredby-neg.svg';
import HostPlaceHolder from "../../../assets/icons/experience-detail/placeholder-provider.svg";

export default function (props: IExperienceBookingConfirmationProps) {
    const {expState, closeExperience} = useContext(ExperienceContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (expState.redirectTo) {
            setTimeout(() => {
                location.assign(expState.redirectTo as string);
            }, 10000)
        }
    }, []);

    const getActivityLabel = (which: number): string => {
        const hasRegDeadline = !((!expState.experience.registrationDeadlineTime && !expState.experience.registrationDeadlineDay) || expState.experience.registrationDeadlineTime == null);
        const dontConfirmActiviesMinpcp =
            expState.hotel.dontConfirmActiviesMinpcp
            && expState.experience.minParticipant > 1
            && (expState.activityBooking.activityDate?.bookingsNumber || 0) + getTotalTicketsRequested(expState.tickets) < expState.experience.minParticipant
            && !expState.hotel.stripeEnabled;

        switch (which) {
            case 1:
                return t(`conf.sent.activity${dontConfirmActiviesMinpcp && hasRegDeadline ? '.unconfirmed' : ''}`);
            case 2:
                return t(`conf.contact.activity${dontConfirmActiviesMinpcp && hasRegDeadline ? '.unconfirmed' : ''}`);
            case 3:
                return t(`conf.send.a.copy.activity${dontConfirmActiviesMinpcp && hasRegDeadline ? '.unconfirmed' : ''}`);
            default:
                return '';
        }

    };
    return (
        <div className="confirmation-message">

            <div className={'confirmation-message__image-placeholder'} style={{backgroundImage: `url('${HostPlaceHolder}')`}}>
                <div className={'placeholder-middle'}>
                    <span className={'placeholder-middle__logo'} style={{backgroundImage: `url('${expState.hotel.logoUrl}')`}}></span>
                </div>
            </div>

            {
                !props.notAvailable &&
                <div className="confirmation-message__infos">
                    <h3>{props.experience.type === 'ACTIVITY' ? getActivityLabel(1) : t('conf.sent.service')}</h3>
                    <p>{props.experience.type  === 'ACTIVITY' ? getActivityLabel(2) : t('conf.contact.service')}</p>
                    {
                        expState.bookingForm.email &&
                        <p className="confirmation-message__smaller">{props.experience.type === 'ACTIVITY' ? getActivityLabel(3) : t('conf.send.a.copy.service')}</p>
                    }

                    {
                        expState.redirectTo &&
                        <p className="confirmation-message__smaller">{t('auto.redirect')}</p>
                    }
                </div>
            }
            {
                props.notAvailable &&
                <div className="confirmation-message__infos">
                    <h3>{t('conf.notavailable.activity')}</h3>
                    <p>{t('conf.contact.activity.notavailable')}</p>
                    {
                        expState.redirectTo &&
                        <p className="confirmation-message__smaller">{t('auto.redirect')}</p>
                    }
                </div>
            }

            <a href={void(0)}
               className="close-btn"
               style={{
                   background: expState.buttonColor ? `var(--link-color)` : '',
               }}
               onClick={closeExperience}>{t('close.experience')}</a>

            <div className="giggle-logo">
                <img  src={PoweredByLogo} alt=""/>
            </div>

        {/*<div className="confirmation-message__footer">*/}
        {/*    <div className="flex fl-align-b" style={{height: '33px', width: '100%'}}>*/}
        {/*        <img style={{height: '30px'}} src={PoweredByLogo} alt=""/>*/}
        {/*        /!*<p className="experience-footer__powered">powered by</p>*!/*/}
        {/*        /!*<p className="experience-footer__giggle">Giggle.tips</p>*!/*/}
        {/*    </div>*/}
        {/*     /!*<span className="bottom-bar__btn"*!/*/}
        {/*     /!*      style={{*!/*/}
        {/*     /!*          background: expState.buttonColor ? `var(--link-color)` : '',*!/*/}
        {/*     /!*          color: expState.hotel.fontColor ? `var(--link-font-color)` : '',*!/*/}
        {/*     /!*      }}*!/*/}
        {/*     /!*      onClick={closeExperience}>{t('close.experience')}</span>*!/*/}
        {/*</div>*/}
    </div>
    );
};
