import * as React from "react";
import {connect, useSelector} from "react-redux";
import "../assets/styles/deactivated-account.css";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import {Helmet} from "react-helmet-async";
import Hotel from "../models/Hotel";
import {match} from "react-router";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getLocale} from "store/app/selectors";

interface IProps {
    hotel: Hotel,
    match: match
};

function DeactivatedAccount(props: IProps) {
    const locale = useSelector(getLocale);
    const { t } = useTranslation();

    return (
        <div className={'deactivated-account'}>
            <Helmet>
                <title>{`Account deactivated`}</title>
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
            <div className={'deactivated-account__logo'}>
                <img src='https://giggle-images.s3.eu-central-1.amazonaws.com/static-images/logo-large.png' alt=""/>
            </div>
            <br/>
            <h3 className={'deactivated-account__m-title'}>{t('oops, deactivated')}</h3>
            <p className={'deactivated-account__message'}>{t('wed love to see you back')}</p>
            <br/>
            <br/>
            <Link to={`/${locale}/experiences`} className={'homepage__top__link'}>{t('explore.experiences')}</Link>

            <div className="copyright">
                <span className="copyright__powered">Powered by</span>
                <span>
			        <img src='https://giggle-images.s3.eu-central-1.amazonaws.com/static-images/logo-large.png' alt=""/>
		        </span>
                <span className="copyright__giggle">
			        <b>Giggle</b>.tips
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel,
    }
}

export default connect(mapStateToProps)(DeactivatedAccount);
