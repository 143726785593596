import axios, { AxiosError } from "axios";
import {
    fetchHotelAction,
    hotelNotFoundAction,
    hotelLoadingErrorAction
} from "store/actions/HotelActions/HotelsActionCreators";
import {API} from "../config";

export const fetchHotelService = async (
    hotelCode: string,
    languageCode: string,
    dispatch?: any
) => {
    try {
        const params: any = {
            languageCode,
        };
        const response = await axios.get(`${API}/api/v2/hotel/${hotelCode}`, {
            params
        });

        if (dispatch) {
            dispatch(fetchHotelAction(response.data))
        }
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (dispatch) {
            dispatch(hotelLoadingErrorAction())
        }
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    dispatch(hotelNotFoundAction())
                }
            }
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const getHotelCancelPolicies = async (hotelId: string) => {
    try {
        const response = await axios.get(API + '/api/hotel/cancellation-policies', {
            params: {
                hotelId,
            }
        });
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError) {
            let errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    return 404;
                }
            }
        }
        return 500;
    }
};

export const getHotelTranslations = async (hotelId: string) => {
    try {
        const response = await axios.get(API + '/api/hotel/translations', {
            params: {
                hotelId,
            }
        });
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError) {
            let errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    return 404;
                }
            }
        }
        return 500;
    }
};

export const fetchHotelOnly = async (
    hotelCode: string,
    languageCode: string,
    dispatch?: any
) => {
    try {
        const params = {
            hotelCode,
            languageCode,
        };
        const response = await axios.get(API + "/app/hotel/code/" + hotelCode, {
            params
        });

        const hotel = response.data;
        dispatch(fetchHotelAction(hotel));
    } catch (e) {
        let error: AxiosError = e as AxiosError;

        if (error.isAxiosError) {
            let errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    dispatch(hotelNotFoundAction());
                    return;
                }
            }
        }

        dispatch(hotelLoadingErrorAction());
    }
};

export const fetchHotelsXMLService = async (lang: string) => {
    try {
        const params: any = {
            lang,
        };
        const response = await axios.get(`${API}/api/hotels/xml`, {
            params,
        });

        return response.data;
    } catch (e) {
        return [];
    }
};

export const fetchExperiencesCountService = async (lang: string) => {
    try {
        const params: any = {
            lang,
        };
        const response = await axios.get(`${API}/api/experiences/xml/count`, {
            params,
        });

        return response.data;
    } catch (e) {
        return 0;
    }
};

export const fetchExperiencesXMLService = async (lang: string, page: number) => {
    try {
        const params: any = {
            lang,
            page,
        };
        const response = await axios.get(`${API}/api/experiences/xml`, {
            params,
        });

        return response.data;
    } catch (e) {
        return [];
    }
};

