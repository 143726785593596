import * as React from 'react';
import '../../assets/css/v2/masonry-card.css';
import LogoHolder from '../../assets/rectangle-large.svg';
import {Link, useLocation} from "react-router-dom";
import {toSeoUrl} from "helpers/GeneralHelper";
import {connect, useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import Hotel from "models/Hotel";
import {HostExperience} from "models/portal/Portal";

interface IMasonryCardProps {
    experience: HostExperience;
    hotel: Hotel,
    fromHost?: boolean,

};

const MasonryCard = (props: IMasonryCardProps) => {
    const locale = useSelector(getLocale);
    const location = useLocation();

    const randomCardHeight = () => {
        const cardHeights: number[] = [350, 380, 400, 420, 450];
        const randomIndex = Math.floor(Math.random() * cardHeights.length)

        return cardHeights[randomIndex]
    }

    const getFromPortalPath = (): string => {
        if (__BROWSER__) {
            return location.state?.fromPortalPath ?? window.location.pathname;
        } else {
            return '';
        }
    }

    return (
        <Link key={`experience${props.experience.id}`}
              to={{
                pathname: `${locale == 'en' ? '' : '/' + locale}/experience/${props.experience.id}/${toSeoUrl(props.experience.title)}`,
                state: { experienceId: props.experience.id, fromPortalPath: getFromPortalPath() }
            }}
        >

            <div className={'masonry-card'} style={{height: `${randomCardHeight()}px`, backgroundImage: `url(${props.experience.image})`}}>
                {props.experience.guideImageUrl &&
                    <div className={'masonry-card__user'} style={{backgroundImage: `url(${props.experience.guideImageUrl})`}}></div>
                }

                {
                    (props.experience.hotelLogo && !props.fromHost) &&
                    <div className={'masonry-card__hotel'} style={{backgroundImage: `url('${LogoHolder}')`, borderTopRightRadius: '2px'}}>
                        <div className={'masonry-card__hotel__logo'} style={{backgroundImage: `url('${props.experience.hotelLogo}')`}}></div>
                    </div>
                }

                <div className={'masonry-card__content'}>
                    <h2 className={'masonry-card__content__title'}>{props.experience.title}</h2>

                    <span className={'masonry-card__content__subtitle'}>
                        {(props.experience.price || 0) > 0 && props.experience.price + '€ • '}
                        {
                            props.experience.hotelName &&
                            <span>{props.experience.hotelName}</span>
                        }

                    </span>
                </div>
            </div>
        </Link>
    );
}

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel
    }
}
export default connect(mapStateToProps)(MasonryCard);
