import {useEffect, useState} from "react";
import {CalendarDay} from "models/ExperienceModel";

export const toSeoUrl = (url: string, isCategory: boolean = false) => {
    if ((isCategory && !url) || (isCategory && url == '')) {
        return 'uncategorized';
    }
    return url.toString()               // Convert to string
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
};

export const useProgressiveImage = (src: string) => {
    const [sourceLoaded, setSourceLoaded] = useState('');

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setSourceLoaded(src)
    }, [src]);

    return sourceLoaded
};

export const getButtonColor = (color: string) => {
    if (color) {
        // const colorHex = color.substring(1);      // strip #
        const rgb = parseInt(color, 16);   // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff;  // extract red
        const g = (rgb >>  8) & 0xff;  // extract green
        const b = (rgb >>  0) & 0xff;  // extract blue

        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        if (luma < 40) {
            return 'ffffff'
        }
    }
    return color;
};

export const getImageSharingUrl = (imageUrl: string, hasSharingImage: any) => {
    if (!hasSharingImage) {
        return imageUrl;
    }

    var pcs = imageUrl.split('.');
    var lastPc = pcs.pop();
    return pcs.join('.') + '-sharing.' + lastPc;
};

export const initializeCalendarDays = (): CalendarDay[] => {
    const calendarDays: CalendarDay[] = [];

    for(let i = 1; i <= 36; i++) {
        calendarDays.push({
            dayLabel: undefined,
            dayTimes: []
        })
    }

    return calendarDays;
};

export const monthDaysConst = [
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] },
    { dayLabel: undefined, dayTimes: [] }
] as const;

