import * as React from "react";
import HomeHeader from "../components/home-page/HomeHeader";
import {useEffect} from "react";
import HomeFooter from "../components/home-page/HomeFooter";
import Hotel from "../models/Hotel";
import {match} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import "../assets/styles/home-page.css"
import WeeklyStream from "../components/home-page/WeeklyStream";
import Stream from "../components/home-page/Stream";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {useState} from "react";
import {getLocale} from "../store/app/selectors";
import {fetchHotelService} from "../services/HotelService";
import {fetchWeeklyExperiences, fetchWeeklyLikeStreams} from "../services/StreamService";
import {fetchHotelAction} from "../store/actions/HotelActions/HotelsActionCreators";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import Loader from "../components/common/Loader";
import {HotelVisited} from "../services/GigglyticsService";
import DeactivatedAccount from "pages/DeactivatedAccount";
import {fetchExperienceAction} from "store/actions/ExperienceActions/ExperienceActionCreators";
import {ExperienceModel} from "models/ExperienceModel";
import {toSeoUrl} from "helpers/GeneralHelper";

interface IHomeProps {
    hotel: Hotel,
    match: match
};

function Home(props: IHomeProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false
    });

    useEffect(() => {
        dispatch(fetchExperienceAction({} as ExperienceModel));
    }, []);

    useEffect(() => {
        moment.locale(locale);
        const loadHotel = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });
            const date = moment();
            const startDate = date.format('yyyy-MM-DD HH:mm:ss');
            const endDate = date.add(7, 'days').format('yyyy-MM-DD');
            const [hotelResult, weeklyExperiences, weeklies]: any = await Promise.all([
                fetchHotelService((props.match.params as any).hotelCode, locale),
                fetchWeeklyExperiences((props.match.params as any).hotelCode, locale, startDate, endDate),
                fetchWeeklyLikeStreams((props.match.params as any).hotelCode, locale, startDate, endDate)
            ]);

            if (hotelResult === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (hotelResult === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                (hotelResult as Hotel).weeklyExperiences = weeklyExperiences;
                (hotelResult as Hotel).weeklies = weeklies;
                dispatch(fetchHotelAction(hotelResult));
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        if (!state.loading && Object.keys(props.hotel).length == 0 && __BROWSER__) {
            loadHotel();
        }

        if (Object.keys(props.hotel).length > 0 && !props.hotel.streams && !state.loading) {
            dispatch(fetchHotelAction({} as Hotel));
        }
    }, [props.hotel]);

    if ((Object.keys(props.hotel).length == 0 || !props.hotel.streams || state.loading) && !state.notFound) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.notFound) {
        return <DeactivatedAccount/>;

        // @ts-ignore
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Hotel Not Found`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Hotel Not Found</h1>
            </div>

            // <DeactivatedAccount/>
        )
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Hotel Data</h1>
            </div>
        )
    }

    if (!props.hotel.active) {
        return <DeactivatedAccount/>;
    }

    if(__BROWSER__) {
        // Gigglytics - Hotel visited
        HotelVisited(props.hotel.id as string);
    }

    return (
        <div className="main">
            <Helmet>
                <title>{`${props.hotel.name} ${t('events')}`}</title>
                <meta name="robots" content="noindex,nofollow"/>
                <link rel="canonical" href={`https://giggle.tips/${locale}/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://giggle.tips/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="de" href={`https://giggle.tips/de/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="en" href={`https://giggle.tips/en/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="it" href={`https://giggle.tips/it/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="fr" href={`https://giggle.tips/fr/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="es" href={`https://giggle.tips/es/hosts/${props.hotel.code}`} />
            </Helmet>

            <HomeHeader
                hotelName={props.hotel.name}
                hotelCode={props.hotel.code}
                peopleList={props.hotel.peopleList}
                coverImageUrl={props.hotel.coverImageUrl}
                hotelColor={props.hotel.color}
            />

            <div className="home-page__streams">
                {
                    props.hotel.streams.map((stream, index) => {
                        if (stream.isWeeklyProgramme) {
                            return <WeeklyStream
                                key={`wstream-${index}`}
                                stream={stream}
                                hotelCode={props.hotel.code}
                                currency={props.hotel.currency}
                                weeklyExperiences={props.hotel.weeklyExperiences}
                                t={t}/>
                        }
                    })
                }

                {
                    props.hotel.streams.map((stream, index) => {
                        if (!stream.isWeeklyProgramme) {
                            if (!stream.weeklyProgramStyle) {
                                return <Stream
                                    key={`stream-${index}`}
                                    stream={stream}
                                    currency={props.hotel.currency}
                                    hotelCode={props.hotel.code}/>
                            } else {
                                return <WeeklyStream
                                    key={`wstream-${index}`}
                                    stream={stream}
                                    hotelCode={props.hotel.code}
                                    currency={props.hotel.currency}
                                    weeklyExperiences={props.hotel.weeklies[stream.id]}
                                    t={t}/>
                            }
                        }
                    })
                }
            </div>

            <HomeFooter autoTranslateLangs={props.hotel.autoTranslateLangs} currentUrl={props.match.url}/>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel,
    }
}

export default connect(mapStateToProps)(Home);
