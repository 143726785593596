import {Stream} from "../../models/Stream";
import * as React from "react";
import {ActivityDate, WeeklyExperiences} from "../../models/ExperienceModel";
import ChevronLeft from "../../assets/icons/chevron-left-white.svg";
import ChevronRight from "../../assets/icons/chevron-right-white.svg";
import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getLocale} from "../../store/app/selectors";
import {toSeoUrl} from "../../helpers/GeneralHelper";
import Loader from "../common/Loader";
import {fetchWeeklyExperiences} from "../../services/StreamService";
import getSymbolFromCurrency from "currency-symbol-map";
import {getTimeFromDateString} from "../../helpers/ExperienceHelper";

interface IStreamProps {
    stream: Stream,
    hotelCode: string,
    currency: string,
    weeklyExperiences: WeeklyExperiences,
    t: any
};

export default function (props: IStreamProps) {
    const locale = useSelector(getLocale);
    let scrollerRef = useRef<HTMLDivElement>(null);
    const [loadingExperiences, setLoadingExperiences] = useState(false);

    useEffect(() => {
        if (props.stream && (props.stream.scrollLeft as number) > 0) {
            scroll(props.stream.scrollLeft as number, true);
        }
    }, []);

    const scrollLeft = () => {
        scroll(-700);
    };

    const scrollRight = () => {
        scroll(700);
    };

    const scroll = (number: number, immediate: boolean = false) => {
        let scroller = scrollerRef.current;
        if (!scroller) { return; }

        if (scroller.scrollLeft < scroller.scrollWidth) {
            scroller.scrollTo({
                left: scroller.scrollLeft + number,
                behavior: immediate ? 'auto' : 'smooth',
            });
            props.stream.scrollLeft = scroller.scrollLeft;
        }
    };

    const onScroll = () => {
        let scroller: HTMLDivElement | any = scrollerRef.current;
        props.stream.scrollLeft = scroller.scrollLeft;
        if (scroller.clientWidth + scroller.scrollLeft <= (scroller.scrollWidth - 700)) return;

        if (loadingExperiences) { return }
        if (props.stream.experiencesLoadedComplete) { return }

        setLoadingExperiences(true);

        const loadNextExperiences = async () => {
            const existingDates = Object.keys(props.weeklyExperiences);
            const lastDate = moment(existingDates[existingDates.length - 1], 'yyyy-MM-DD');
            const nextDateStart = lastDate.add(1, 'days').format('yyyy-MM-DD');
            const nextDateEnd = lastDate.add(18, 'days').format('yyyy-MM-DD');
            let result = await fetchWeeklyExperiences(props.hotelCode, locale, nextDateStart, nextDateEnd, props.stream.id, false);

            if (result === 404) {
                props.stream.experiencesLoadedComplete = true;
            }
            if (result !== 404 && result !== 500) {
                if (props.weeklyExperiences) {
                    Object.keys(result as any).forEach((x: any) => {
                        // @ts-ignore
                        props.weeklyExperiences[x] = result[x] as any;
                    });
                    props.stream.currentOffset = (props.stream.currentOffset || 0) + 7;
                }
            }

            setLoadingExperiences(false);
        }

        loadNextExperiences();
    };

    const getExperienceTimes = (activityDates: ActivityDate[], date: string) => {
        let activityDate = activityDates.find(x => x.startDate.substr(0, 10) == date);
        if (!activityDate) {
            activityDate = activityDates[0];
        }
        let experienceTime = getTimeFromDateString(activityDate.startDate);
        if (!activityDate.endDate.startsWith('0001')) {
            experienceTime += ' - ' + getTimeFromDateString(activityDate.endDate);
        }
        return experienceTime;
    };

    const weeklyExperiencesObjectKeys = Object.keys(props.weeklyExperiences);
    let hasExperiences = false;
    weeklyExperiencesObjectKeys.map((date: string) => {
        if (props.weeklyExperiences[date].length > 0) {
            hasExperiences = true;
        }
    })
    if (!hasExperiences) {
        return props.stream.isWeeklyProgramme ?
            <div className="stream">
                <div className="stream__top stream__top--wp "></div>
            </div> : <div/>
    }

    return (
        <div className="stream stream-simple">
            <div className="stream__top stream__top--wp ">
                <h2 className="stream__title">{props.stream.translation.name || props.t('weekly programme')}</h2>
            </div>
            <div className="stream__activities" ref={scrollerRef} onScroll={onScroll}>
                {
                    Object.keys(props.weeklyExperiences).map((date: string) => {
                        if (props.weeklyExperiences[date].length > 0)
                        return (
                            <div key={`weeklygroup-${date}`} className="stream__date-groups">
                                <div className="stream__group-title">{moment(date).locale(locale).format('dddd, DD MMMM')}</div>
                                <div className="stream__group-activities">
                                    {
                                        props.weeklyExperiences[date].map((experience, index) => {
                                            return (
                                                <Link key={`experience${experience.id}${index}`}
                                                      to={{
                                                          pathname: `${locale == 'en' ? '' : '/'+locale}/experiences/${toSeoUrl(experience.translation.categoryName, true)}/${experience.id}/${toSeoUrl(experience.translation.title)}?hc=${props.hotelCode}`,
                                                          state: {experienceId: experience.id}
                                                      }}
                                                      className="stream-activity">
                                                        <img src={experience.imageUrl} loading={'lazy'} alt={`${experience.translation.title} image`}/>
                                                        {(experience.minMaxPrice || 0) > 0 && <div className="stream-activity__top">{experience.minMaxPrice.includes('.') ? Number(experience.minMaxPrice).toFixed(2) : experience.minMaxPrice} {getSymbolFromCurrency(props.currency)}</div>}
                                                        <h3 className="stream-activity__title">
                                                            {experience.translation.title}
                                                        </h3>
                                                        <div className="stream-activity__time">{getExperienceTimes(experience.activityDates, date)}</div>
                                                        <div className="stream-activity__shadow"/>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    loadingExperiences &&
                    <div style={{ width: 'fit-content', alignSelf: 'center'}}>
                        <Loader />
                    </div>
                }
            </div>

            <div className="stream__arrow stream__arrow--wp" onClick={scrollLeft}>
                <img src={ChevronLeft} width={30} height={30} alt=""/>
            </div>

            <div className="stream__arrow stream__arrow--wp stream__arrow--right" onClick={scrollRight}>
                <img src={ChevronRight} width={30} height={30} alt=""/>
            </div>
        </div>
    );
}
