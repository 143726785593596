import * as React from "react";
import '../../assets/GiggleCalendar/giggle-calendar.css';
import moment, {Moment} from 'moment';
import {useContext, useEffect, useState} from "react";
import leftArrow from '../../assets/GiggleCalendar/icons/left-arrow.svg'
import rightArrow from '../../assets/GiggleCalendar/icons/right-arrow.svg'
import {ActivityDate, CalendarDay, ResourceCalendarDay} from "../../models/ExperienceModel";
import { monthDaysConst} from "../../helpers/GeneralHelper";
import ExperienceContext from "ExperienceContext";
import {useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import {useTranslation} from "react-i18next";

interface IProps {
    dateClicked: (activityDate: ActivityDate) => void,
    disabledDates?: any;
    times?: any;
    goNextStep: () => void
};

const ResourceGiggleCalendar = (props: IProps) => {
    let weekdays: string[] = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    const weekdaysEn: string[] = ["su", "mo", "tu", "we", "th", "fr", "sa"];
    const locale = useSelector(getLocale);

    if (locale === 'en') {
        weekdays = weekdaysEn;
    }

    const {t} = useTranslation();
    const {expState, setExpState} = useContext(ExperienceContext);
    const today = moment();
    const dayNr: string = today.format("D");
    const month: string = today.format("MM");
    const year: string = today.format("Y");
    const [state, setState] = useState<{
        monthDays: CalendarDay[],
        date: any,
        dayChosen: number | undefined,
        selectedDate: any}>({
        monthDays: [],
        date: moment(),
        dayChosen: undefined,
        selectedDate: null,
    });
    const formatedDisabledDate = props.disabledDates.map((date: Moment) => moment(date).format('DD-MM-YYYY'));

    const populateMonthDays = () => {
        const startDayOfMonth: string = state.date.startOf('month').lang('en').format('dd').toLowerCase();
        const startDayOfMonthIndex: number = weekdays.indexOf(startDayOfMonth);
        const daysInMonth: number = moment(state.date, 'MM').daysInMonth();
        const monthDays = JSON.parse(JSON.stringify(monthDaysConst));

        let dayNumber = 1;
        for (let i = startDayOfMonthIndex; i < daysInMonth + startDayOfMonthIndex; i++) {
            const date = moment(state.date);
            const activityDates = expState.experience.activityDates || [];
            date.set('date', dayNumber)
            monthDays[i] = {
                dayLabel: dayNumber,
                dayTimes: activityDates.filter(x => moment(x.startDate).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'))
            };
            dayNumber++;
        }
        setState({...state, monthDays})
    };

    const getMonthDays = (stateDate: any) => {
        const startDayOfMonth: string = stateDate.startOf('month').lang('en').format('dd').toLowerCase();
        const startDayOfMonthIndex: number = weekdays.indexOf(startDayOfMonth);
        const daysInMonth: number = moment(stateDate, 'MM').daysInMonth();
        const monthDays = JSON.parse(JSON.stringify(monthDaysConst));

        let dayNumber = 1;
        for (let i = startDayOfMonthIndex; i < daysInMonth + startDayOfMonthIndex; i++) {
            const date = moment(stateDate);
            const activityDates = expState.experience.activityDates || [];
            date.set('date', dayNumber)
            monthDays[i] = {
                dayLabel: dayNumber,
                dayTimes: activityDates.filter(x => moment(x.startDate).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'))
            };
            dayNumber++;
        }

        return monthDays;
    };

    useEffect(() => {
        populateMonthDays();
    }, []);

    // useEffect(() => {
    //     if (expState.resourceBooking.dayTime){
    //         props.goNextStep();
    //     }
    // }, [expState.resourceBooking.dayTime])

    const selectDate = (day: number | undefined, isDayDisabled: boolean) => {
        if (isDayDisabled) return;

        if (day) {
            const _day = day;
            const _month = moment(state.date).format('M');
            const _year = moment(state.date).format('Y');
            const _selectedDate = moment(`${_day}-${_month}-${_year}`, 'DD-MM-YYYY');

            setState({
                ...state,
                dayChosen: state.dayChosen != day ? day : undefined,
                selectedDate: _selectedDate
            });
        }
    };

    const onDateClicked = async (timeIndex: number) => {
        await setExpState({
            ...expState,
            resourceBooking: {
                ...expState.resourceBooking,
                dayTime: timeIndex,
                resourceDate: moment(state.selectedDate)
            },
            resourceStep: expState.resourceStep + 1
        });

        // props.goNextStep();
    };

    const onPrev = () => {
        const monthDays = getMonthDays(moment(state.date).subtract(1, 'M'));

        setState({
            ...state,
            date: state.date.subtract(1, 'M'),
            monthDays,
            dayChosen: undefined,
            selectedDate: null,
        });
    };

    const onNext = () => {
        const monthDays = getMonthDays(moment(state.date).add(1, 'M'));

        setState({
            ...state,
            date: state.date.add(1, 'M'),
            monthDays,
            dayChosen: undefined,
            selectedDate: null,
        });
    };

    const isToday = (day: CalendarDay): boolean => {
        return state.date.format("MM") === month &&
            state.date.format("Y") === year &&
            Number(dayNr) === day.dayLabel
    };

    const isDayClicked = (day: ResourceCalendarDay): boolean => {
        return state.dayChosen === day.dayLabel && day.dayLabel != undefined;
    };

    const checkIsDayDisabled = (day: ResourceCalendarDay): boolean => {
        const date = state.date;
        date.set('date', day.dayLabel);

        date.set({
            hour: 23,
            minute: 59
        });
        let diffInMinutes = moment().diff(date, 'minutes');

        if (diffInMinutes >= 0){
            return true;
        }

        return formatedDisabledDate.includes(date.format('DD-MM-YYYY'))
    };

    const getTimeName = (item: number) => {
        switch (item) {
            case 2:
                return 'noon';
            case 3:
                return 'afternoon';
            case 4:
                return 'evening';
            case 5:
                return 'night';
            default: return 'morning';
        }
    }

    const getStyleOfDate = (isDayClicked_: boolean, isToday: boolean, color: string) => {
        let style = {} as any;

        if (isDayClicked_) {
            style.backgroundImage = `linear-gradient(90deg, var(--link-color) 0%, var(--link-color) 100%)`;
            style.color = `var(--link-font-color)`;
        }

        if (isToday) {
            style.color = `#fff`;
        }

        return style;
    }

    return (
        <div className={'giggle-calendar'}>
            <div className={'giggle-calendar__nav'}>
                <span className={`prev`} onClick={onPrev}>
                    <img src={leftArrow} />
                </span>
                <span>{state.date.lang(locale).format("MMMM")} {state.date.format("Y")}</span>
                <span className={'next'} onClick={onNext}>
                    <img src={rightArrow} />
                </span>
            </div>

            {/* <div className={'giggle-calendar__week'}>{ moment.weekdaysShort(true).map(name => <span>{name}</span>)}</div> */}
            <div className={'giggle-calendar__week'}>{ moment.weekdaysShort(true).map(name => <span>{name.replace(/\./g, '')}</span>)}</div>

            <div className={'giggle-calendar__days'}>
                {
                    state.monthDays.map((day: ResourceCalendarDay, index: number) => {
                        const isDayClicked_ = isDayClicked(day);
                        const isDayDisabled = checkIsDayDisabled(day);

                        return (
                            <div
                                className={
                                    `giggle-calendar__days__day ${isDayClicked_ ? 'open' : ''} ${isDayDisabled ? 'disabled_day' : 'active_day'} ${!day.dayLabel ? 'empty_day' : ''}`
                                }
                                style={{height: isDayClicked_ ? `${85 + (Math.ceil(Object.keys(props.times).length / 3) * 70) }px` : 'inherit'}}
                                key={`day-${index}`}
                                onClick={() => selectDate(day.dayLabel, isDayDisabled)}>
                                <span className={`giggle-calendar__days__day__date ${isDayClicked_ ? 'selected' : ''}`}
                                      style={getStyleOfDate(isDayClicked_, isToday(day), expState.hotel.color)}
                                >
                                    {day.dayLabel || ''}

                                    {isDayClicked_ && <span style={{backgroundColor: `var(--link-color)`}} className={'corner'}></span>}
                                </span>
                                {
                                    isToday(day) && !isDayClicked_
                                    && <span className={'giggle-calendar__days__day__line'} style={{backgroundColor: `var(--link-color)`}}></span>
                                }

                                {
                                    isDayClicked_ &&
                                    <div className={'giggle-calendar__days__day__time open'}>
                                        {/* <div className={'giggle-calendar__days__day__time__label'}
                                             style={{
                                                 display: 'flex', justifyContent: 'space-between', paddingRight: '13px'
                                             }}>
                                            <span>{moment(state.selectedDate).locale(locale).format('ddd MMM DD, YYYY')}</span>
                                            <span>{t('select.time.slot')}</span>
                                        </div> */}

                                        <div className="booking-dates__content giggle-calendar__days__day__time__interval2">
                                            {
                                                props.times?.map((time: any, index: number) => {
                                                    return (
                                                        <div key={`activity-date-${index}`}
                                                             className={`booking-date available-seats`}
                                                             style={{flexBasis: `calc((100% / ${Object.keys(props.times).length === 3 ? 3 : 2}) - 6px)'`, height: '64px'}}
                                                             onClick={() => onDateClicked(time)}>
                                                            <div className="booking-date__box">
                                                                <div className="booking-date__box--hour" style={{
                                                                    // color: 'white',
                                                                    textTransform: 'capitalize',
                                                                    fontSize: '15px'
                                                                }}>
                                                                    {t(getTimeName(time))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

export default ResourceGiggleCalendar;
