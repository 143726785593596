import * as React from "react";
import {useContext, useState} from "react";
import ExperienceContext from "ExperienceContext";
import {useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import {useTranslation} from "react-i18next";
import ArrowGray from "../../../assets/icons/left-arrow-gray.svg";
import ResourceExperienceDetailsTickets
    from "components/experience-page/resource/ResourceExperienceDetailsTickets";
import ResourceExperienceCalendar
    from "components/experience-page/resource/ResourceExperienceCalendar";
import {
    getRequestedTicketsTotalPrice,
    getResourceTimes,
    getTotalTicketsRequested, isRegistrationFormValid,
    showBottomBar
} from "helpers/ExperienceHelper";
import getSymbolFromCurrency from "currency-symbol-map";
import ExperienceBookingDetailsForm
    from "components/experience-page/shared/ExperienceBookingDetailsForm";
import {addBookingService} from "services/BookingService";
import ArrowRight from "../../../assets/icons/arrow-e-black.svg";
import ExperienceBookingConfirmation
    from "components/experience-page/shared/ExperienceBookingConfirmation";
import {BookingFormSettings} from "models/ExperienceModel";

interface IProps {}

const ResourceExperienceV2 = (props: IProps) => {
    const {expState, setExpState} = useContext(ExperienceContext);
    const locale = useSelector(getLocale);
    const {t} = useTranslation();
    const stripeOptions: any = {
        locale: locale
    };
    const times = getResourceTimes(expState.experience.resourceEvents);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const bookingFormSettingsInit : BookingFormSettings = {
        name: { show: true, required: true},
        phone: { show: true, required: false},
        email: { show: true, required: false},
        comment: { show: true, required: false},
        hotelName: { show: false, required: false},
        customField: { show: false, required: false, label: {}},
    }
    const [bookingFormSettings, setBookingFormSettings] = useState(expState.hotel.bookingFormSettings ? (JSON.parse(expState.hotel.bookingFormSettings) as BookingFormSettings ) : bookingFormSettingsInit);

    const goNextStep = () => {
        setLoading(true);
        bookExperience();
    };

    const goPrevStep = () => {
        setExpState({...expState, resourceStep: expState.resourceStep - 1})
    };

    const bookExperience = async () => {
        const resourceDate = expState.resourceBooking.resourceDate;
        if (!resourceDate) {
            return;
        }
        const tickets: any = expState.tickets.map(x => {
            return {
                ticketId: x.id,
                amount: x.amountToBook
            }
        });

        let comment = expState.bookingForm.comment;
        // If it's Innsbruck circle, attach hotelname
        if (expState.experience.hotelId === '4beed3f0-3c7f-4580-b9f6-3650fb790fd4' || expState.hotel.showHotelNameField) {
            comment = expState.bookingForm.comment + ` [Hotelname: ${expState.bookingForm.hotelName}]`;
        }
        const data = {
            name: expState.bookingForm.name,
            phone: expState.bookingForm.phone,
            email: expState.bookingForm.email,
            customField: expState.bookingForm.customField,
            comment: comment,
            hotelName: null,
            bookingType: expState.experience.type,
            date: resourceDate.format('yyyyMMDD'),
            experienceId: expState.experience.id,
            hotelCode: expState.hotel.code,
            dontConfirmActiviesMinpcp: false,
            language: locale,
            tickets,
            startTime: expState.resourceBooking.dayTime || 0,
            endTime: 0
        };

        await addBookingService(data);
        setLoading(false);

        // Go to last step
        setExpState({
            ...expState,
            resourceStep: expState.resourceStep + 1
        })
    };

    const onSubmit = () => setIsSubmitted(isRegistrationFormValid(expState.bookingForm, bookingFormSettings));

    const isMobile = () => {
        if(window.innerWidth > 992 ) return false;
        
        const userAgent = window.navigator.userAgent;
        const mobileRegex = /(Android|iPhone|iPod)/i;
        const iPadRegex = /iPad|Macintosh/i;
        const isTouchDevice = navigator.maxTouchPoints > 0;
    
        // Check for mobile devices and iPads
        return mobileRegex.test(userAgent) || (iPadRegex.test(userAgent) && isTouchDevice);
    };
    
    return (
        <div className={`experience-content__exp`} id="parent-experience-id">
            {/* Start top navigation */}
                {
                    ((expState.resourceStep === 2 || expState.resourceStep === 3)) &&
                    <div className={'experience-top__action'}>
                        <a className={'back-link'}
                           href={void(0)}
                           onClick={() => setExpState({...expState, resourceStep: expState.resourceStep - 1})}>
                                    <span className={'icon'}>
                                        <img src={ArrowGray} alt=""/>
                                    </span>

                            <span>{t('close.experience')}</span>
                        </a>
                    </div>
                }
            {/* End top navigation */}

            {/* Start Desktop */}
            {
                expState.resourceStep === 1 &&
                <ResourceExperienceDetailsTickets />
            }

            {
                (expState.resourceStep === 2 && !isMobile()) &&
                <ResourceExperienceCalendar times={times} />
            }

            {
                (expState.resourceStep === 3 && !isMobile()) &&
                <ExperienceBookingDetailsForm
                    goNextStep={goNextStep}
                    goPrevStep={goPrevStep}
                    isSubmitted={isSubmitted}
                    loading={loading}/>
            }

            {
                (expState.resourceStep === 4 && !isMobile()) &&
                <ExperienceBookingConfirmation experience={expState.experience} />
            }

            {/* Start bottom Navigation */}
            {
                (expState.resourceStep === 1 && showBottomBar(expState)) &&
                <div className={'bottom-bar bottom-bar--active'}>
                    <div className="flex fl-direction-row fl-justify-start fl-align-c">
                        <div className={expState.experience.imageUrl ? 'bottom-bar__profile-picture' : 'd-none'}>
                            <img src={expState.experience.imageUrl} alt=""/>
                        </div>
                        <div className="flex fl-direction-co fl-align-s">
                            <div className='bottom-bar__item mrr-20px flex fl-direction-ro fl-align-c'>
                                <div className='bottom-bar__item-name mrr-5px'
                                     style={{
                                         color: `var(--link-color)`,
                                     }}>{getTotalTicketsRequested(expState.tickets)}</div>
                                <div
                                    className='bottom-bar__item-name'
                                    style={{
                                        color: `var(--link-color)`,
                                    }}>{getTotalTicketsRequested(expState.tickets) > 1 ? t('tickets.selected') : t('ticket.selected')}</div>
                            </div>
                            <div className="flex fl-direction-ro fl-align-c">
                                <div className='bottom-bar__item-value mrr-5px'><span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>{getRequestedTicketsTotalPrice(expState.tickets).toFixed(2)}</div>
                                <div className='bottom-bar__item-value'>{t('total')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'bottom-bar__btn'}
                        style={{
                            background: expState.buttonColor ? `var(--link-color)` : '',
                            color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                        }}
                         onClick={() => setExpState({...expState, resourceStep: expState.resourceStep + 1})}>
                        <span className='bottom-bar__btn-span'>{t('next')}</span>
                        <div className="material-icons mrl-10px">arrow_forward</div>
                    </div>
                </div>
            }

            {
                (expState.resourceStep === 3) &&
                <div className={'bottom-navigation'}>
                    <span className={'info'}>{expState.experience.type == "ACTIVITY" ? t('finish.your.registration') : t('finish.your.request')}</span>

                    <a href={void(0)}
                       className={`bottom-navigation__link ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) && 'bottom-navigation__link-inactive'}`}
                       onClick={onSubmit}
                       style={{
                           background: expState.buttonColor ? `var(--link-color)` : '',
                           color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                       }}
                    >
                        <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>

                        <div className="material-icons">arrow_forward</div>
                    </a>
                </div>
            }
            {/* End bottom Navigation */}
            {/* End Desktop */}

            {/* Start Mobile */}
            {
                (expState.resourceStep === 2 && isMobile()) &&
                <div className={'fullscreen-step'}>
                    <div className={'experience-top__action'}>
                        <a className={'back-link'}
                           href={void(0)}
                           onClick={() => setExpState({...expState, resourceStep: expState.resourceStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>
                            <span>{t('close.experience')}</span>
                        </a>
                    </div>

                    <ResourceExperienceCalendar times={times} />
                </div>
            }

            {
                (expState.resourceStep === 2 && isMobile()) &&
                <div className={'fullscreen-step'}>
                    <div className={'experience-top__action'}>
                        <a className={'back-link'}
                           href={void(0)}
                           onClick={() => setExpState({...expState, resourceStep: expState.resourceStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>
                            <span>{t('close.experience')}</span>
                        </a>
                    </div>

                    <ResourceExperienceCalendar times={times} />
                </div>
            }

            {
                (expState.resourceStep === 3 && isMobile()) &&
                <div className={'fullscreen-step'} style={{paddingBottom: '100px'}}>
                    <div className={'experience-top__action'}>
                        <a className={'back-link'}
                           href={void(0)}
                           onClick={() => setExpState({...expState, resourceStep: expState.resourceStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>
                            <span>{t('close.experience')}</span>
                        </a>
                    </div>

                    <ExperienceBookingDetailsForm isSubmitted={isSubmitted} loading={loading} goNextStep={goNextStep} />

                    {/*<div */}
                    {/*    className={`bottom-navigation__link ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) && 'bottom-navigation__link-inactive'}`} */}
                    {/*    onClick={onSubmit}>*/}
                    {/*    <a href={void(0)} onClick={onSubmit}>*/}
                    {/*        <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>*/}

                    {/*        <span className={'icon'} onClick={onSubmit}>*/}
                    {/*                <img onClick={onSubmit} src={ArrowRight} alt=""/>*/}
                    {/*            </span>*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    <div className={`bottom-actions ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) && 'inactive' }`} onClick={onSubmit}>
                        <a href={void(0)}
                           style={{
                               background: expState.buttonColor ? `var(--link-color)` : '',
                           }}
                        >
                            <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>

                            <div className="material-icons">arrow_forward</div>
                        </a>
                    </div>
                </div>
            }

            {
                (expState.resourceStep === 4 && isMobile()) &&
                <div className={'fullscreen-step'} style={{paddingBottom: '100px'}}>
                    <ExperienceBookingConfirmation experience={expState.experience} />
                </div>
            }
            {/* End Mobile */}
        </div>
    );
}

export default ResourceExperienceV2
