import * as React from "react";
import {connect} from "react-redux";
import './../../assets/css/v2/header.css';
import {Breadcrumbs as BreadcrumbModel} from "models/portal/Portal";
import {Link} from "react-router-dom";
import homeIcon from "./../../assets/icons/overview-page/home-icon.svg";
import rightArrowIcon from "./../../assets/icons/overview-page/right-arrow.svg";
import {useEffect, useState} from "react";


interface IProps {
    breadcrumb: BreadcrumbModel,
}

function Breadcrumbs(props: IProps) {
    const [scrolling, setScrolling] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const hasNextCrumb = (type: string) => {
        if (type === 'experiences') {
            return props.breadcrumb.region || props.breadcrumb.experience || props.breadcrumb.destination || props.breadcrumb.category
        } else if (type === 'region') {
            return props.breadcrumb.experience || props.breadcrumb.destination || props.breadcrumb.category
        } else if (type === 'destination') {
            return props.breadcrumb.experience || props.breadcrumb.category
        } else if (type === 'category') {
            return props.breadcrumb.experience || props.breadcrumb.region || props.breadcrumb.destination
        }
    }

    const isBreadcrumbActive = (type: string): boolean => {
        return props.breadcrumb.last === type;
    }

    return (
        <div className={`breadcrumb`}>
            <ul className={'breadcrumb__list'}>
                <li className={'breadcrumb__list__item'}>
                    <Link to={'/'}>
                        <img src={homeIcon} alt=""/>
                    </Link>

                    <img className={'icon'} src={rightArrowIcon} alt=""/>
                </li>
                {
                    props.breadcrumb.experiencesVisited &&
                    <li className={`breadcrumb__list__item ${isBreadcrumbActive('experiences') ? 'active' : ''}`}>
                         <span>
                             <Link to={`/experiences`} href={void (0)}>Experiences</Link>
                         </span>
                        {
                            hasNextCrumb('experiences') &&
                            <img className={'icon'} src={rightArrowIcon} alt=""/>
                        }
                    </li>
                }
                {
                    props.breadcrumb.region &&
                    <li className={`breadcrumb__list__item ${isBreadcrumbActive('region') ? 'active' : ''}`}>
                         <span>
                             <Link to={`/experiences/r/${props.breadcrumb.region.code}`}>{props.breadcrumb.region.name}</Link>
                         </span>
                        {
                            hasNextCrumb('region') &&
                            <img className={'icon'} src={rightArrowIcon} alt=""/>
                        }
                    </li>
                }
                {
                    props.breadcrumb.destination &&
                    <li className={`breadcrumb__list__item ${isBreadcrumbActive('destination') ? 'active' : ''}`}>
                         <span>
                             <Link to={`/experiences/d/${props.breadcrumb.destination.code}`}>{props.breadcrumb.destination.name}</Link>
                         </span>
                        {
                            hasNextCrumb('destination') &&
                            <img className={'icon'} src={rightArrowIcon} alt=""/>
                        }
                    </li>
                }
                {
                    props.breadcrumb.category &&
                    <li className={`breadcrumb__list__item ${isBreadcrumbActive('category') ? 'active' : ''}`}>
                         <span>
                             <Link to={`/experiences/c/${props.breadcrumb.category.translation.urlName}`}>{props.breadcrumb.category.translation.name}</Link>
                         </span>
                        {
                            hasNextCrumb('category') &&
                            <img className={'icon'} src={rightArrowIcon} alt=""/>
                        }
                    </li>
                }
            </ul>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        breadcrumb: state.breadcrumb ?? {} as BreadcrumbModel
    }
}

export default connect(mapStateToProps)(Breadcrumbs);
