export default class TranslationUtils {
    public static getTranslatedField(translations: any[], language: string, field: string): any {
        let translatedField = '';

        for (let e in translations) {
            const translation = translations[e];
            if (!translation) { continue; }
            if (translation.languageCode.toLowerCase() == language.toLowerCase()) {
                translatedField = translation[field];
                break;
            }
        }

        return translatedField;
    }

    public static getTranslatedFieldV2(translations: any[], language: string, field: string): any {
        let translatedField = '';

        for (let e in translations) {
            const translation = translations[e];
            if (!translation) { continue; }
            if (translation.lang.toLowerCase() == language.toLowerCase()) {
                translatedField = translation[field];
                break;
            }
        }

        return translatedField;
    }

}
