import {GuideAction} from "../actions/GuideActions/GuideActions";
import {GuideActionTypes} from "../actions/GuideActions/GuideActionTypes";
import {People} from "../../models/People";

const initialState: People = {} as People;

export default (state = initialState, action: GuideAction) => {
    switch (action.type) {
        case GuideActionTypes.FETCH_GUIDE:
            const guide = (action.payload as People);
            return { ...guide };
        default:
            return state;
    }
}
