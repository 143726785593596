import {Host} from "models/portal/Portal";
import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";


const initialState: Host = {} as Host;

export default (state = initialState, action: PortalAction) => {

    switch (action.type) {

        case PortalActionTypes.FETCH_HOST:
            const hotel = (action.payload as Host)
            const hotelPreloaded = (action.payload as Host)

            return { ...hotel, ...hotelPreloaded };
        default:
            return state;
    }
}
