import React, { useEffect } from 'react';
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import {I18nextProvider} from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLocale } from '../store/app/selectors';

import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import it from './locales/it/translation.json';
import fr from './locales/fr/translation.json';
import es from './locales/es/translation.json';
import sk from './locales/sk/translation.json';
import nl from './locales/nl/translation.json';
import sl from './locales/sl/translation.json';
import ja from './locales/ja/translation.json';
import pt from './locales/pt/translation.json';
import vie from './locales/vie/translation.json';
import zh from './locales/zh/translation.json';
import el from './locales/el/translation.json';
import sv from './locales/sv/translation.json';
import no from './locales/nb/translation.json';

const I18N: React.FC<any> = ({ children }) => {
    const locale = useSelector(getLocale);

    if (__BROWSER__) {
        i18next.use(i18nextXHRBackend);
    }

    i18next.languages = ['de', 'en', 'it', 'fr', 'es', 'sk', 'sl',  'nl', 'ja', 'pt', 'vie', 'zh', 'el', 'sv', 'no'];

    i18next.on('languageChanged', function (lng) {
        // // if the language we switched to is the default language we need to remove the /en from URL
        // if (lng === i18next.options.fallbackLng[0]) {
        //     if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
        //         const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
        //         window.location.replace(newUrl)
        //     }
        // }
    })

    i18next
        .init({
            lng: locale,
            backend: {
                // for all available options read the backend's repository readme file
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
            react: {
                // Must be false until Suspense is supported on the server side
                useSuspense: false,
                wait: true,
            },
            debug: process.env.NODE_ENV === 'development' && __BROWSER__,
            fallbackLng: locale,
            fallbackNS: ['translation'],
            detection: {
                order: ['path'],
                lookupFromPathIndex: 0,
                checkWhitelist: true
            },
            keySeparator: false, // we use content as keys
            // This option is necessary to tell i18next to try loading missing resources via
            // i18next-xhr-backend, otherwise nb calls will be made if resources are defined.
            partialBundledLanguages: true,
            resources: {
                de: { translation: de },
                en: { translation: en },
                it: { translation: it },
                fr: { translation: fr },
                es: { translation: es },
                sk: { translation: sk },
                sl: { translation: sl },
                nl: { translation: nl },
                ja: { translation: ja },
                pt: { translation: pt },
                vie: { translation: vie },
                zh: { translation: zh },
                el: { translation: el },
                sv: { translation: sv },
                no: { translation: no },
            },
        });

    useEffect(() => {
        i18next.changeLanguage(locale);
    }, [locale]);

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default React.memo(I18N);
