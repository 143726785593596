import {ExperienceModel} from "../../models/ExperienceModel";
import Hotel from "../../models/Hotel";
import {ExperienceAction} from "../actions/ExperienceActions/ExperienceActions";
import {ExperienceActionTypes} from "../actions/ExperienceActions/ExperienceActionTypes";

const initialState: ExperienceModel = {} as ExperienceModel;

export default (state = initialState, action: ExperienceAction) => {
    switch (action.type) {
        case ExperienceActionTypes.FETCH_EXPERIENCE:
            const experience = (action.payload as ExperienceModel)
            const experiencePreloaded = (action.payload as Hotel)
            return { ...experience, ...experiencePreloaded };
        default:
            return state;
    }
}
