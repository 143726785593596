import {ActivityDate} from "models/ExperienceModel";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ExperienceDetails from "../shared/ExperienceDetails";
import ActivityExperienceDates from "./ActivityExperienceDates";
import ExperienceTickets from "../shared/ExperienceTickets";
import ExperienceGuidesAndRelated from "../shared/ExperienceGuidesAndRelated";
import ExperienceContext from "../../../ExperienceContext";
import {
    getRequestedTicketsTotalPrice,
    getTotalTicketsRequested, showRegistrationButton, showBottomBar
} from "../../../helpers/ExperienceHelper";
import getSymbolFromCurrency from "currency-symbol-map";
import {Link} from "react-router-dom";
import GiggleCalendar from "components/GiggleCalendar/GiggleCalendar";
import ArrowGray from "assets/icons/left-arrow-gray.svg";

interface IActivityExperienceDetailsTicketsProps {
    goNextStep?: () => void
};

export default function (props: IActivityExperienceDetailsTicketsProps) {
    const {expState, setExpState} = useContext(ExperienceContext);
    const {t} = useTranslation();
    const [showAnimation, setAnimation] = useState(false);
    const bookingEndEl: any = useRef(null);

    useEffect(() => {
        if(__BROWSER__) {
            let scrollingBody = document.getElementById('parent-experience-id') as HTMLElement;
            let scrollingBodyMobile = document.getElementById('parent-experience-popup-id') as HTMLElement;
            // let maxScroll = scrollingBody.scrollHeight - scrollingBody.clientHeight
            const { offsetTop } = bookingEndEl.current;
            scrollingBody.addEventListener('scroll', () => {
                if (scrollingBody.scrollTop > offsetTop - 400) {
                    setAnimation(true)
                }
            });
            scrollingBodyMobile.addEventListener('scroll', () => {
                if (scrollingBodyMobile.scrollTop > offsetTop - 400) {
                    setAnimation(true)
                }
            });
         }
    }, []);

    const navigateToBookingPart = () => {
        bookingEndEl.current.scrollIntoView({behavior: 'smooth'})
    };

    const onDateClicked = (activityDate: ActivityDate) => {
        setExpState({
            ...expState,
            activityBooking: {
                ...expState.activityBooking,
                activityDate
            },
            tickets: (expState.tickets ?? []).map((ticket) => ({...ticket, amountToBook: 0})),
            activityStep: expState.activityStep + 1
        });

        // if (!showRegistrationButton(expState.experience)) {
        //     props.goNextStep();
        //     setExpState({
        // }
    };

    const onNextStep = () => {
        // props.goNextStep();

        setExpState({
            ...expState,
            activityStep: expState.activityStep + 1
        })
    }

    return (
        <>
            <div className="experience__content-top-img"/>

            <ExperienceDetails navigateToBookingPart={navigateToBookingPart}/>

            <div ref={bookingEndEl}/>

            {/*{*/}
            {/*    (expState.experience.activityDates.length > 0 && !expState.activityBooking.activityDate) &&*/}
            {/*    <ActivityExperienceDates dateClicked={onDateClicked} showAnimation={showAnimation} />*/}
            {/*}*/}

            {
                (expState.experience.activityDates.length > 0 && expState.experience.activityDates.length <= 5)
                && <ActivityExperienceDates dateClicked={onDateClicked} showAnimation={showAnimation}/>
            }
            { expState.experience.activityDates.length > 5 &&
                    <div>
                        <div className="experience-booking" style={{paddingBottom: 0}}>
                            <div className="booking-dates">
                                <div className="booking-dates__header">
                                    <h3>{t('availabilities')}</h3>

                                    <div className="line"></div>
                                    {/* {expState.experience.registrationRequired ? <p>{t('choose date')}</p> : <p>{t('select.a.date')}</p> } */}
                                    {expState.experience.registrationRequired && <p>{t('choose date')}</p> }
                                </div>
                            </div>
                        </div>

                        <GiggleCalendar goNextStep={onNextStep} dateClicked={onDateClicked} />
                    </div>

            }


            {/*{*/}
            {/*    (expState.activityBooking.activityDate && expState.experience.tickets && expState.experience.registrationRequired) &&*/}

            {/*    <div className={'fullscreen-step'}>*/}
            {/*        <div className={'fullscreen-step__action'}>*/}
            {/*            <a className={'back-link'} href={void(0)} onClick={() => ''}>*/}
            {/*                <span className={'icon'}>*/}
            {/*                    /!*<img src={ArrowGray} alt=""/>*!/*/}
            {/*                </span>*/}
            {/*                <span>Back</span>*/}
            {/*            </a>*/}
            {/*        </div>*/}

            {/*        <ExperienceTickets showAnimation={showAnimation} experienceType={'ACTIVITY'} />*/}
            {/*    </div>*/}
            {/*}*/}

            <ExperienceGuidesAndRelated
                hotel={expState.hotel}
                category={expState.experience.category ? expState.experience.category.translation.name: 'uncategorized'}
                people={expState.experience.people}
                relatedExperiences={expState.experience.relatedExperiences.filter(x => x.id != expState.experience.id)}/>

            {/*<div className={'bottom-bar ' + (showBottomBar(expState) ? 'bottom-bar--active' : '')}>*/}
            {/*    <div className="flex fl-direction-row fl-justify-start fl-align-c">*/}
            {/*        <div className={expState.experience.imageUrl ? 'bottom-bar__profile-picture' : 'd-none'}>*/}
            {/*            <img src={expState.experience.imageUrl} alt=""/>*/}
            {/*        </div>*/}
            {/*        <div className="flex fl-direction-co fl-align-s">*/}
            {/*            <div className='bottom-bar__item mrr-20px flex fl-direction-ro fl-align-c'>*/}
            {/*                <div className='bottom-bar__item-name mrr-5px'>{getTotalTicketsRequested(expState.tickets)}</div>*/}
            {/*                <div*/}
            {/*                    className='bottom-bar__item-name'>{getTotalTicketsRequested(expState.tickets) > 1 ? t('tickets.selected') : t('ticket.selected')}</div>*/}
            {/*            </div>*/}
            {/*            <div className="flex fl-direction-ro fl-align-c">*/}
            {/*                <div className='bottom-bar__item-value mrr-5px'><span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>{getRequestedTicketsTotalPrice(expState.tickets).toFixed(2)}</div>*/}
            {/*                <div className='bottom-bar__item-value'>{t('total')}</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'bottom-bar__btn'}*/}
            {/*         // style={{*/}
            {/*         //     background: expState.buttonColor ? `var(--link-color)` : '',*/}
            {/*         //     color: expState.hotel.fontColor ? `var(--link-font-color)` : '',*/}
            {/*         // }}*/}
            {/*         onClick={props.goNextStep}>*/}
            {/*        <span className='bottom-bar__btn-span'>{t('next')}</span>*/}
            {/*        <div className="material-icons mrl-10px">arrow_forward</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

    );
};
