import * as React from 'react';
import Masonry from "react-masonry-css";
import MasonryCard from "components/common/MasonryCard";
import '../../assets/css/v2/masonry-grid.css';
import MasonryCategoriesCard from "components/common/MasonryCategoriesCard";
import {ExperienceMini} from "models/ExperienceModel";
import {HostExperience} from "models/portal/Portal";
import MasonryBlueCard from './MasonryBlueCard';

interface IMasonryGridProps {
    experiences: HostExperience[];
    isOnExperienceDetails?: boolean
    fromHost?: boolean
};

const MasonryGrid = (props: IMasonryGridProps) => {
    const breakpointColumnsObj = {
        default: props.isOnExperienceDetails ? 2 : 5,
        1200: 4,
        992: 3,
        767: 3,
        576: 2,
    };

    return (
        <div className={'masonry-grid'}>
            <Masonry breakpointCols={breakpointColumnsObj}  className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                {
                    props.experiences.map((item: ExperienceMini | any, index: number) => {
                        if (item.hasOwnProperty('categoryTitle')){
                            return <MasonryCategoriesCard experience={item} key={index} />
                        } else if(index % 50 === 6){
                            return <MasonryBlueCard experience={item} key={index} />
                        } 
                        else {
                            return <MasonryCard experience={item} fromHost={props.fromHost} key={index} />
                        }
                    })
                }
            </Masonry>
        </div>
    )
}

export default MasonryGrid;
