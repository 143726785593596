import {
    BookingFormSettings,
    ExperienceEvent,
    ExperienceModel,
    ResourcePauseDates,
    Ticket
} from "../models/ExperienceModel";
import {BookingForm, ExperienceState} from "../ExperienceContext";
import moment from "moment";

export const getRequestedTicketsTotalPrice = (tickets: Ticket[]): number => {
    return tickets.reduce((a, b) => {
        return a + (b.amountToBook * b.price);
    }, 0);
};

export const getTotalTicketsRequested = (tickets: Ticket[]): number => {
    return tickets.reduce((a, b) => {
        return a + b.amountToBook
    }, 0);
};

export const showBottomBar = (expState: ExperienceState): boolean => {
    if (expState.tickets.length) {
        return true;
    }
    return false;
};

export const generateDateRangeSkipDays = (startDate: moment.Moment, stopDatep: moment.Moment, days: string, disabledDates: any = null, experience: any = null, pauseDates: any = null) => {
    let dateArray: any[] = [];
    let currentDate = moment(startDate);
    let stopDate = moment(stopDatep);
    while (currentDate <= stopDate) {
        const date = moment(currentDate);
        date.locale('en');
        const day = date.format('dd').toUpperCase();

        if (!days.includes(day)) {
            dateArray.push(date.toDate());
        }
        currentDate = moment(currentDate).add(1, 'days');
    }

    if(disabledDates && disabledDates.disabledDates) {
        if (pauseDates) {
            const pauseDatesObj: ResourcePauseDates[] = JSON.parse(pauseDates);
            pauseDatesObj.forEach((pauseDate: ResourcePauseDates) => {
                const endDate = moment(pauseDate.endDate).set({
                    hour: 23,
                    minute: 59
                });
                let currentDateOnDisabled = pauseDate.startDate != 0 ? moment(pauseDate.startDate) : moment();
                while (currentDateOnDisabled <= endDate) {
                    const date = moment(currentDateOnDisabled);
                    date.locale('en');
                    dateArray.push(date.toDate());
                    currentDateOnDisabled = moment(currentDateOnDisabled).add(1, 'days');
                }
            })
        } else {
            const endDate = moment(disabledDates.disabledEndDate);
            let currentDateOnDisabled = disabledDates.disabledStartDate != 0 ? moment(disabledDates.disabledStartDate) : moment();
            while (currentDateOnDisabled <= endDate) {
                const date = moment(currentDateOnDisabled);
                date.locale('en');
                dateArray.push(date.toDate());
                currentDateOnDisabled = moment(currentDateOnDisabled).add(1, 'days');
            }
        }
    }

    if(experience && experience.registrationRequired && experience.registrationDeadlineTime) {
        const now = moment();
        const minutesPassedToday = now.diff(moment().startOf('day'), 'minutes');
        const todayWithRegDeadlineTime = now.set('hour', experience.registrationDeadlineTime?.slice(0, 2)).set('minutes', experience.registrationDeadlineTime?.slice(2, 4));
        const minutesOfRegDeadline = todayWithRegDeadlineTime.diff(moment().startOf('day'), 'minutes');

        const lastDeadlineDayPassed = minutesOfRegDeadline <= minutesPassedToday;

        let deadlineDaysToAdd = Number(experience.registrationDeadlineDay) + Number(lastDeadlineDayPassed ? 1 : 0)
        let lastDateAddedToDateArray = moment().startOf('day');
        lastDateAddedToDateArray.locale('en');
        const timesDateArray = dateArray.map(x => x.getTime())
        while(deadlineDaysToAdd > 0) {
            if (timesDateArray.includes(lastDateAddedToDateArray.toDate().getTime())) {
                lastDateAddedToDateArray.add(1, 'days')
            } else {
                timesDateArray.push(lastDateAddedToDateArray.toDate().getTime());
                deadlineDaysToAdd--;
            }
        }

        dateArray = timesDateArray.map(x => new Date(x))
    }

    return dateArray;
};


const isEmailValid = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isRegistrationFormValid = (bookingForm: BookingForm, bookingFormSettings: BookingFormSettings): boolean => {
    return !(!bookingForm.name ||
        (bookingFormSettings.phone.show && bookingFormSettings.phone.required && !bookingForm.phone) ||
        (bookingFormSettings.email.show && bookingFormSettings.email.required && !bookingForm.email) ||
        (bookingFormSettings.email.show && bookingForm.email && !isEmailValid(bookingForm.email)) ||
        (bookingFormSettings.hotelName.show && bookingFormSettings.hotelName.required && !bookingForm.hotelName) ||
        (bookingFormSettings.comment.show && bookingFormSettings.comment.required && !bookingForm.comment) ||
        (bookingFormSettings.customField?.show && bookingFormSettings.customField?.required && !bookingForm.customField) ||
        !bookingForm.acceptedTerms);
};

export const getTimeAsDayName = (val: number) => {
    switch (val) {
        case 2:
            return 'noon';
        case 3:
            return 'afternoon';
        case 4:
            return 'evening';
        case 5:
            return 'night';
        default: return 'morning';
    }
};

export const getResourceTimes = (resourceEvents: ExperienceEvent[]) => {
    if (!resourceEvents.length) {
        return [1, 2, 3, 4];
    }

    let event = resourceEvents[0];
    if (event.times) {
        return event.times.split(',').map(x => Number(x)).sort(function (a, b) {  return a - b;  });
    }

        let startTime = parseInt(moment('20120202T' + event.startTime).format('HHmmss'));
    let endTime = parseInt(moment('20120202T' + event.endTime).format('HHmmss'));
    let times: number[] = [];

    if (60000 >= startTime && 60000 <= endTime || 90000 >= startTime && 90000 <= endTime) {
        times.push(1);
    }
    if (90100 >= startTime && 90100 <= endTime || 120000 >= startTime && 120000 <= endTime) {
        times.push(2);
    }
    if (120100 >= startTime && 120100 <= endTime || 130100 >= startTime && 160000 <= endTime ||  180000 >= startTime && 180000 <= endTime) {
        times.push(3);
    }
    if (180100 >= startTime && 180100 <= endTime || 210000 >= startTime && 210000 <= endTime || 230000 >= startTime && 230000 <= endTime ) {
        times.push(4);
    }
    if (startTime >= 230100 || endTime >= 230100) {
        times.push(5);
    }
    return times;
};

export const getQueryParam = (queryParams: string, key: string) => {
   return new URLSearchParams(queryParams).get(key)
};

export const hasQueryParam = (queryParams: string, key: string) => {
    return queryParams.indexOf(key + '=') >= 0;
};

export const getHcQuery = (queryParams: string) => {
    const hcParam = new URLSearchParams(queryParams).get('hc');
    return !hcParam || hcParam === 'hotelName' ? undefined : hcParam;
};

export const showRegistrationButton = (experience: ExperienceModel): boolean => {
    let showButton = experience.tickets && experience.tickets.length > 0 && experience.registrationRequired;
    if (experience.type == 'ACTIVITY' && experience.activityDates.length == 0) {
        showButton = false;
    }
    return showButton;
};

export const getTimeFromDateString = (dateString: string) => {
    return dateString.substr(11, 5);
};
