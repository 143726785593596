// @flow
import * as React from 'react';


import SinglePerson from './SinglePerson';


interface IPersonsColumn {
    isLeft: boolean;
    peopleList: any;
};


export default function (props: IPersonsColumn) {
    let isLargeSize = false;
    let isLargeSizeRight = true;

    const renderStaff = () => {
        return props.peopleList.map((person: any, index: any) => {
            if (props.isLeft) {
                if (index % 2 === 0) {
                    isLargeSize = !isLargeSize;
                }
            }

            if (!props.isLeft) {
                isLargeSizeRight = !isLargeSizeRight;
            }

            return (
                <SinglePerson
                    peopleList={props.peopleList}
                    key={person.id}
                    guide={person}
                    isLeft={props.isLeft}
                    bigHeightLeft={isLargeSize}
                    bigHeightRight={isLargeSizeRight}
                />
            )
        })
    }

    return (
        <div className="person-column-render">
            {renderStaff()}
        </div>
    );
};
