import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";
import {PortalCategory} from "models/portal/Portal";


const initialState: PortalCategory = {} as PortalCategory;

export default (state = initialState, action: PortalAction) => {
    switch (action.type) {
        case PortalActionTypes.FETCH_CATEGORY:
            const category = (action.payload as PortalCategory)

            return { ...category };
        default:
            return state;
    }
}
