// import React, { Suspense } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import favicon from '../shared/assets/giggle-logo.png';
import {Redirect} from "react-router";
import Home from "./pages/Home";
import DeactivatedAccount from "./pages/DeactivatedAccount";
import "./assets/styles/_typography.css";
import "./assets/styles/styles.css";
import "./assets/styles/loader.css";
import "./assets/styles/core.scss";
import Experience from "./pages/Experience";
import ExceptionalPolicy from "./pages/ExceptionalPolicy";
import Guide from "./pages/Guide";
import {useEffect, useState} from "react";
import {timeSession} from "./services/GigglyticsService";
import Category from "pages/portal/Category";
import HomePage from "pages/HomePage"
import Host from "pages/portal/Host";
import Experiences from "pages/portal/Experiences";
import Region from "pages/portal/Region";
import Destination from "pages/portal/Destination";
let hotelCd = '';
function App() {
    const supportedLanguages = ['de', 'en', 'it', 'fr', 'es', 'sk', 'sl',  'nl', 'ja', 'pt', 'vie', 'zh', 'el', 'sv', 'nb'];
    const [time, setTime] = useState(Date.now);
    const [hotelCode, setHotelCode] = useState<string>();

    useEffect(() => {
        if(__BROWSER__) {
            window.addEventListener("load", onLoad);
            window.addEventListener("beforeunload", () => {
                const now = new Date();
                const seconds = (now.getTime() - time) / 1000;
                if (hotelCode || hotelCd) {
                    timeSession(hotelCode || hotelCd, Math.ceil(seconds));
                }
            });
        }
    }, []);

    const onLoad = (e: any) => {
        const now = new Date();
        setTime(now.getTime());
    };

    return (
        <div className='app'>
            <Helmet
                defaultTitle="Giggle Hotels"
                titleTemplate="%s"
                link={[{ rel: 'icon', type: 'image/ico', href: favicon }]}
            />
            <Switch>

                <Route
                    exact
                    path="/:lang/hosts/:hotelCode"
                    render={props => {
                        setHotelCode(props.match.params.hotelCode);
                        hotelCd = props.match.params.hotelCode;
                        if(supportedLanguages.includes(props.match.params.lang)) {
                            return <Host {...props} />
                        } else {
                            return <Redirect to={`/${props.match.params.hotelCode}`}/>
                        }
                    }
                    }
                />
                <Route
                    exact
                    path="/hosts/:hotelCode"
                    render={props => {
                        setHotelCode(props.match.params.hotelCode);
                        return <Host {...props}/>
                    }}/>

                <Route
                    exact
                    path="/experiences"
                    component={Experiences}/>
                <Route
                    exact
                    path="/:lang/experiences"
                    component={Experiences}/>

                <Route
                    exact
                    path="/experiences/r/:region"
                    component={Region}/>
                <Route
                    exact
                    path="/:lang/experiences/r/:region"
                    component={Region}/>

                <Route
                    exact
                    path="/:lang/experiences/r/:region/c--:category"
                    render={props =>
                        supportedLanguages.includes(props.match.params.lang) ? (
                            <Category {...props} />
                        ) : (
                            <Redirect to={`/${props.match.params.lang}/experiences/${props.match.params.category}`} />
                        )
                    }
                />
                <Route
                    exact
                    path="/experiences/r/:region/c--:category"
                    component={Category}/>

                <Route
                    exact
                    path="/experiences/r/:region/:destination"
                    component={Destination}/>
                <Route
                    exact
                    path="/:lang/experiences/r/:region/:destination"
                    component={Destination}/>



                <Route
                    exact
                    path="/:lang/experiences/r/:region/:destination/c--:category"
                    render={props =>
                        supportedLanguages.includes(props.match.params.lang) ? (
                            <Category {...props} />
                        ) : (
                            <Redirect to={`/${props.match.params.lang}/experiences/${props.match.params.category}`} />
                        )
                    }
                />
                <Route
                    exact
                    path="/experiences/r/:region/:destination/c--:category"
                    component={Category}/>

                <Route
                    exact
                    path="/experiences/d/:destination"
                    component={Destination}/>
                <Route
                    exact
                    path="/:lang/experiences/d/:destination"
                    component={Destination}/>

                <Route
                    exact
                    path="/:lang/experiences/d/:destination/c--:category"
                    render={props =>
                        supportedLanguages.includes(props.match.params.lang) ? (
                            <Category {...props} />
                        ) : (
                            <Redirect to={`/${props.match.params.lang}/experiences/${props.match.params.category}`} />
                        )
                    }
                />
                <Route
                    exact
                    path="/experiences/d/:destination/c--:category"
                    component={Category}/>

                <Route
                    exact
                    path="/:lang/:hotelCode/exceptional-policies"
                    render={props => {
                            setHotelCode(props.match.params.hotelCode);
                            if(supportedLanguages.includes(props.match.params.lang)) {
                                return <ExceptionalPolicy {...props} />
                            } else {
                                return <Redirect to={`/${props.match.params.hotelCode}`} />
                            }
                        }
                    }
                />
                <Route
                    exact
                    path="/:hotelCode/exceptional-policies"
                    render={props => {
                        setHotelCode(props.match.params.hotelCode);
                        return <ExceptionalPolicy {...props} />
                    }}/>

                <Route
                    exact
                    path="/:lang/experiences/c/:category"
                    render={props =>
                        supportedLanguages.includes(props.match.params.lang) ? (
                            <Category {...props} />
                        ) : (
                            <Redirect to={`/${props.match.params.lang}/experiences/${props.match.params.category}`} />
                        )
                    }
                />
                <Route
                    exact
                    path="/experiences/c/:category"
                    component={Category}/>


                <Route
                    exact
                    path="/:lang/experiences/:category/:experienceId/:experienceName"
                    render={props => {
                        if(supportedLanguages.includes(props.match.params.lang)) {
                            return <Experience {...props} />
                        } else {
                            return <Redirect to={`/${props.match.params.hotelCode}`}/>
                        }
                    }
                    }
                />
                <Route
                    exact
                    path="/experiences/:category/:experienceId/:experienceName"
                    component={Experience}/>


                <Route
                    exact
                    path="/:lang/experience/:experienceId/:experienceName"
                    render={props => {
                        if(supportedLanguages.includes(props.match.params.lang)) {
                            return <Experience {...props} />
                        } else {
                            return <Redirect to={`/`}/>
                        }
                    }
                    }
                />
                <Route
                    exact
                    path="/experience/:experienceId/:experienceName"
                    component={Experience}/>


                <Route
                    exact
                    path="/:lang/guides/:guideId/:guideName"
                    render={props =>
                        supportedLanguages.includes(props.match.params.lang) ? (
                            <Guide {...props} />
                        ) : (
                            <Redirect to={`/${props.match.params.hotelCode}`} />
                        )
                    }
                />
                <Route
                    exact
                    path="/guides/:guideId/:guideName"
                    component={Guide}/>


                <Route
                    exact
                    path="/"
                    component={HomePage}/>

                {
                    supportedLanguages.map(lang => {
                        return <Route
                            key={lang}
                            exact
                            path={"/" + lang}
                            component={HomePage}/>
                    })
                }

                <Route
                    exact
                    path="/:lang/:hotelCode"
                    render={props => {
                        setHotelCode(props.match.params.hotelCode);
                        hotelCd = props.match.params.hotelCode;
                        if(supportedLanguages.includes(props.match.params.lang)) {
                            return <Home {...props} />
                        } else {
                            return <Redirect to={`/${props.match.params.hotelCode}`}/>
                        }
                    }
                    }
                />
                <Route
                    exact
                    path="/:hotelCode"
                    render={props => {
                        setHotelCode(props.match.params.hotelCode);
                        return <Home {...props}/>
                    }}/>

                <Route
                    exact
                    path="/:lang/deactivated-account"
                    component={DeactivatedAccount}
                />
            </Switch>
        </div>
    );
};

export default App;
