import * as React from "react";
import ChevronRight from "../../assets/icons/chevron_right_grey.svg";
import {useTranslation} from "react-i18next";
interface IHomePageFooterProps {
    currentUrl: string;
    autoTranslateLangs: string;
};

export default function (props: IHomePageFooterProps) {
    const { i18n } = useTranslation();

    const onButtonClicked = (link: string) => {
        window.open(link, '_blank');
    }

    const getRedirectLink = (lang: string): string => {
        let paths = props.currentUrl.split('/');
        if (['de', 'en', 'it', 'fr', 'es', 'sk', 'sl',  'nl', 'ja', 'pt', 'vie', 'zh', 'el', 'nb', 'sv'].includes(paths[1])) {
            paths.shift();
            paths.shift();
            return lang == 'en' ? `/${lang}/${paths.join('/')}` : `/${lang}/${paths.join('/')}`;
        } else {
            return lang == 'en' ? `/${lang}${paths.join('/')}` : `/${lang}${paths.join('/')}`;
        }
    }

    const getCurrentLanguage = (language: string) => {
        if (language.indexOf('en') !== -1) {
            return 'English';
        }
        else if (language.indexOf('de') !== -1) {
            return 'German';
        }
        else if (language.indexOf('it') !== -1) {
            return 'Italian';
        }
        else if (language.indexOf('fr') !== -1) {
            return 'French';
        }
        else if (language.indexOf('es') !== -1) {
            return 'Spanish';
        }
        else if (language.indexOf('sk') !== -1) {
            return 'Slovakian';
        }
        else if (language.indexOf('sl') !== -1) {
            return 'Slovenian';
        }
        else if (language.indexOf('nl') !== -1) {
            return 'Netherlands';
        }
        else if (language.indexOf('vie') !== -1) {
            return 'Vietnamese';
        }
        else if (language.indexOf('pt') !== -1) {
            return 'Portugal';
        }
        else if (language.indexOf('nb') !== -1) {
            return 'Norwegian';
        }
        else if (language.indexOf('sv') !== -1) {
            return 'Swedish';
        }
        else {
            return 'English';
        }
    }

    return (
        <div className='home-footer'>
            <div className='logo-w'>
                <div className='logo-giggle logo--small' />
                <span className='logo-w__name'>Giggle.tips</span>
            </div>

            <div className='home-footer__btns'>
                <div onClick={() => onButtonClicked('https://hotel.giggle.tips/privacy')} className={'home-footer__btn '}>
                    <span className='home-footer-btn__text'>Privacy</span>
                </div>
                <div onClick={() => onButtonClicked('https://hotel.giggle.tips/privacy')} className={'home-footer__btn '}>
                    <span className='home-footer-btn__text'>Imprint</span>
                </div>
                <div className={'home-footer__btn home-footer__btn--drop-down'}>
                    <span className='home-footer-btn__text'>{getCurrentLanguage(i18n.language || 'en')}</span>
                    <img className='home-footer__btn-down-arrow' src={ChevronRight} alt=""/>
                    {/*<i className='material-icons home-footer__btn-down-arrow'>chevron_right</i>*/}
                    <div className='home-footer__btn-drop-down'>
                        <div className='home-footer__btn-drop-down-item'>
                            <a href={getRedirectLink('de')}>German</a>
                        </div>
                        <div className='home-footer__btn-drop-down-item'>
                            <a href={getRedirectLink('en')}>English</a>
                        </div>
                        {
                            props.autoTranslateLangs.includes('it') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('it')}>Italian</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('fr') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('fr')}>French</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('es') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('es')}>Spanish</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('sk') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('sk')}>Slovakian</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('sl') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('sl')}>Slovenian</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('pt') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('pt')}>Portugal</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('nl') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('nl')}>Netherlands</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('vie') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('vie')}>Vietnamese</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('nb') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('nb')}>Norwegian</a>
                            </div>
                        }
                        {
                            props.autoTranslateLangs.includes('sv') &&
                            <div className='home-footer__btn-drop-down-item'>
                                <a href={getRedirectLink('sv')}>Swedish</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
