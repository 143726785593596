import * as React from "react";
import {useTranslation} from "react-i18next";
import {People} from "../../../models/People";
import {ExperienceMini} from "../../../models/ExperienceModel";
import {useContext, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getLocale} from "../../../store/app/selectors";
// import {useLocation} from "react-router-dom";
import {toSeoUrl} from "../../../helpers/GeneralHelper";
import Hotel from "../../../models/Hotel";
import MasonryGrid from "components/common/MasonryGrid";
import {getQueryParam} from "helpers/ExperienceHelper";
import ExperienceContext from "ExperienceContext";
import {useHistory} from "react-router";
interface IProps {
    hotel: Hotel;
    people: People[];
    category: string;
    relatedExperiences: ExperienceMini[];
};

export default function (props: IProps) {
    const location = useLocation();
    const {t} = useTranslation();
    const locale = useSelector(getLocale);
    const [experiencesShown, setExperiencesShown] = useState(4);

    const showMoreExperiencesShown = () => {
        setExperiencesShown(experiencesShown + 4);
    };

    const isFromHostSources = (): boolean => {
        if (
            !location.state &&
            !Boolean(getQueryParam(location.search, 'redirectTo')) &&
            !Boolean(getQueryParam(location.search, 'fbclid'))
        ) return false;

        return false//Boolean(expState.redirectHotelCode || expState.redirectTo);
    }

    return (
        <div className='experience-guides-related-container' >
            {
                props.people.length > 0 &&
                <div className="experience-guides">
                    <div className="experience-guides__header">
                        <h3>{t('team')}</h3>
                        <div className={'line'}></div>
                    </div>
                    <div className="experience-guides__items">
                        {
                            props.people.map((people, index) => {
                                if (index > 3) return;
                                return (
                                    <Link key={`people-${index}`} className="hotel-guide"
                                         to={{
                                             pathname: `${locale == 'en' ? '' : '/'+locale}/guides/${people.id}/${toSeoUrl(people.firstName + ' ' + people.lastName, false)}${location.search}`,
                                             state: {guideId: people.id}
                                         }}>
                                        <div className="hotel-guide__img" style={{backgroundImage: `url(${people.profilePicture})`}}/>
                                        <div className="hotel-guide__name">
                                            {people.firstName} {people.lastName}
                                        </div>
                                        <div className="hotel-guide__title">
                                            {people.translation.title}
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            }

            {
                // props.relatedExperiences.length > 0 && !props.hotel.disableSimilarExperiences &&
                isFromHostSources() &&
                <div className="related-experiences">
                    <div className="related-experiences__wrapper">
                        <div className={'related-experience-header'}>
                            <h3>
                                {t('more.like.this')}
                            </h3>
                            <div className={'line'}></div>
                        </div>

                        <div className="related-experiences__items">
                            <MasonryGrid  experiences={props.relatedExperiences} isOnExperienceDetails={true}/>

                            {/*{*/}
                            {/*    props.relatedExperiences.map((experience, index) => {*/}
                            {/*        if (experiencesShown < (index + 1)) return;*/}
                            {/*        return (*/}
                            {/*            <Link*/}
                            {/*                key={`rel-exp-${experience.id}`}*/}
                            {/*                to={`${locale == 'de' ? '' : '/'+locale}/experiences/uncategorized/${experience.id}/${toSeoUrl(experience.translation.title)}${locationObj.search}`}*/}
                            {/*                className="related-experiences__items__box">*/}
                            {/*                <img src={experience.imageUrl} loading={'lazy'} alt={`${experience.translation.title} image`}/>*/}
                            {/*                <h4 className="related-experiences__items__box--title">*/}
                            {/*                    {experience.translation.title}*/}
                            {/*                </h4>*/}
                            {/*                <div className="related-experiences__items__box--shadow"/>*/}
                            {/*            </Link>*/}
                            {/*        )*/}
                            {/*    })*/}
                            {/*}*/}
                        </div>
                        {/*{*/}
                        {/*    props.relatedExperiences.length > experiencesShown &&*/}
                        {/*    <div className="related-experiences__wrapper__btn-wrapper">*/}
                        {/*        <button onClick={showMoreExperiencesShown}>{t('load.more')}</button>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                </div>
            }
            {
                (props.relatedExperiences.length == 0 && props.people.length == 0) ||
                (props.people.length == 0 && props.hotel.disableSimilarExperiences) && <div>
                </div>
            }
        </div>
    );
};
