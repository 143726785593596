import * as React from "react";
import '../../assets/GiggleCalendar/giggle-calendar.css';
import moment, { Moment } from 'moment';
import {useContext, useEffect, useState} from "react";
import leftArrow from '../../assets/GiggleCalendar/icons/left-arrow.svg'
import rightArrow from '../../assets/GiggleCalendar/icons/right-arrow.svg'
import {ActivityDate, CalendarDay} from "../../models/ExperienceModel";
import { monthDaysConst} from "../../helpers/GeneralHelper";
import ExperienceContext from "ExperienceContext";
import {getTimeFromDateString} from "helpers/ExperienceHelper";
import {useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import {useTranslation} from "react-i18next";
import { da } from "date-fns/locale";

interface IProps {
    dateClicked: (activityDate: ActivityDate) => void,
    goNextStep: () => void
};

const GiggleCalendar = (props: IProps) => {
    let weekdays: string[] = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    const weekdaysEn: string[] = ["su", "mo", "tu", "we", "th", "fr", "sa"];
    const locale = useSelector(getLocale);

    if (locale === 'en') {
        weekdays = weekdaysEn;
    }

    const {t} = useTranslation();
    const {expState, setExpState} = useContext(ExperienceContext);
    const [activityDatesWithTimes, setActivityDatesWithTimes] = useState<string[]>([]);
    const today = moment();
    const dayNr: string = today.format("D");
    const month: string = today.format("MM");
    const year: string = today.format("Y");
    const [state, setState] = useState<{
        monthDays: CalendarDay[], date: any, dayChosen: number | undefined, selectedDate: any}>({
        monthDays: [],
        date: expState.experience.activityDates[0] ? moment(expState.experience.activityDates[0].startDate) : moment(),
        dayChosen: Number(moment(expState.experience.activityDates[0].startDate).format('D')),
        selectedDate: moment(expState.experience.activityDates[0].startDate).format('DD-MM-YYYY'),
    });

    // setState({
    //     ...state,
    //     dayChosen: state.dayChosen != day ? day : undefined,
    //     selectedDate: moment(`${day}-${month}-${year}`, 'DD-MM-YYYY')
    // });
    const [isFirstActivityDate, setIsFirstActivityDate] = useState<boolean>(false);
    const [isLastActivityDate, setIsLastActivityDate] = useState<boolean>(false);

    const populateMonthDays = () => {
        const startDayOfMonth: string = state.date.startOf('month').lang('en').format('dd').toLowerCase();
        const startDayOfMonthIndex: number = weekdays.indexOf(startDayOfMonth);
        const daysInMonth: number = moment(state.date, 'MM').daysInMonth();
        const monthDays = JSON.parse(JSON.stringify(monthDaysConst));
        let dayNumber = 1;
        for (let i = startDayOfMonthIndex; i < daysInMonth + startDayOfMonthIndex; i++) {
            const date = moment(state.date);
            const activityDates = expState.experience.activityDates || [];
            date.set('date', dayNumber)
            monthDays[i] = {
                dayLabel: dayNumber,
                dayTimes: activityDates.filter(x => moment(x.startDate).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'))
            };
            dayNumber++;
        }
        setState({...state, monthDays})
    };

    const getMonthDays = (stateDate: any) => {
        const startDayOfMonth: string = stateDate.startOf('month').lang('en').format('dd').toLowerCase();
        const startDayOfMonthIndex: number = weekdays.indexOf(startDayOfMonth);
        const daysInMonth: number = moment(stateDate, 'MM').daysInMonth();
        const monthDays = JSON.parse(JSON.stringify(monthDaysConst));
        let dayNumber = 1;

        for (let i = startDayOfMonthIndex; i < daysInMonth + startDayOfMonthIndex; i++) {
            const date = moment(stateDate);
            const activityDates = expState.experience.activityDates || [];
            date.set('date', dayNumber)
            monthDays[i] = {
                dayLabel: dayNumber,
                dayTimes: activityDates.filter(x => moment(x.startDate).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'))
            };
            dayNumber++;
        }

        return monthDays;
    };

    useEffect(() => {
        if (expState.experience.activityDates) {
            populateMonthDays();
            // getResourceDisabledDates();
            setActivityDatesWithTimes(
                (expState.experience.activityDates || []).map(x => moment(x.startDate).format('DD-MM-YYYY'))
            )
        }
    }, [expState.experience.activityDates]);

    const selectDate = (day: number | undefined, isDayDisabled: boolean) => {
        if (isDayDisabled) return;
        if (day) {
            setState({
                ...state,
                dayChosen: state.dayChosen != day ? day : undefined,
                selectedDate: moment(`${day}-${month}-${year}`, 'DD-MM-YYYY')
            });
        }
    };

    const onDateClicked = (activityDate: ActivityDate) => {
        if (!registrationDeadlinePassed(activityDate) && !registrationWindowNotPassed(activityDate)) {
            props.dateClicked(activityDate)
            // props.goNextStep()
            // setExpState({
            //     ...expState,
            //     activityStep: 2
            // })
        }
    };

    const onPrev = () => {
        const monthDays = getMonthDays(moment(state.date).subtract(1, 'M'));
        setIsLastActivityDate(false)

        if(checkIfFirstActivityDate(state.date)){
            setIsFirstActivityDate(true)
            return
        }

        setState({
            ...state,
            date: state.date.subtract(1, 'M'),
            monthDays,
            dayChosen: undefined,
            selectedDate: null,
        });
    };

    const onNext = () => {
        const monthDays = getMonthDays(moment(state.date).add(1, 'M'));
        setIsFirstActivityDate(false)

        if(checkIfLastActivityDate(state.date)){
            setIsLastActivityDate(true)
            return
        }

        setState({
            ...state,
            date: state.date.add(1, 'M'),
            monthDays,
            dayChosen: undefined,
            selectedDate: null,
        });
    };

    const checkIfFirstActivityDate = (date: Moment) => {
        const currentDate = moment(date).format('YYYY-MM')
        const firstActivityDate = moment(expState.experience.activityDates[0].startDate).format('YYYY-MM')

        return moment(currentDate).isSame(firstActivityDate);
    }

    const checkIfLastActivityDate = (date: Moment) => {
        const currentDate = moment(date)
        const lastActivityDate = moment(expState.experience.activityDates[expState.experience.activityDates.length - 1].startDate)

        return moment(currentDate).isAfter(lastActivityDate);
    }

    const isToday = (day: CalendarDay): boolean => {
        return state.date.format("MM") === month &&
            state.date.format("Y") === year &&
            Number(dayNr) === day.dayLabel
    };

    const isDayClicked = (day: CalendarDay): boolean => {
        return state.dayChosen === day.dayLabel && day.dayLabel != undefined;
    };

    const formatRegistrationWindowTime = () => {
        if(!expState.experience.registrationWindowTime) {
            return;
        }
        return (expState.experience.registrationWindowTime as string).substring(0, 4).replace(/^(\d{2})/, '$1:');
    };

    const registrationDeadlinePassed = (activityDate: ActivityDate) => {
        if(
            (!expState.experience.registrationDeadlineTime && !expState.experience.registrationDeadlineDay)
            || expState.experience.registrationDeadlineTime == null) {
            return false;
        }
        let registrationDeadLine = moment(activityDate.startDate).subtract(expState.experience.registrationDeadlineDay, 'days')
        let timeStr = String(expState.experience.registrationDeadlineTime?.slice(0,2) + ':' + String(expState.experience.registrationDeadlineTime?.slice(2,4)));

        let date = moment(registrationDeadLine);
        let time = moment(timeStr, 'HH:mm');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute')
        });
        let diffInMinutes = date.diff(new Date(), 'minutes');
        if(!!diffInMinutes) {
            return diffInMinutes > 0 ? false : true
        }
        return false;
    };

    const registrationWindowNotPassed = (activityDate: ActivityDate) => {
        if(
            !expState.hotel.bookingWindowEnabled
            || (!expState.experience.registrationWindowTime && !expState.experience.registrationWindowDay)
            || expState.experience.registrationWindowTime == null
            || expState.experience.registrationWindowDay === -1
        ) {
            return false;
        }

        let registrationWindow = moment(activityDate.startDate).subtract(expState.experience.registrationWindowDay, 'days')
        let timeStr = String(expState.experience.registrationWindowTime?.slice(0,2) + ':' + String(expState.experience.registrationWindowTime?.slice(2,4)));

        let date = moment(registrationWindow);
        let time = moment(timeStr, 'HH:mm');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute')
        });

        let diffInMinutes = moment().diff(date, 'minutes');
        return diffInMinutes < 0;
    };

    const getTimePeriod = (activityDate: ActivityDate) => {
        const openEnd = activityDate.endDate.startsWith('0001');
        const startTime = activityDate.startDate ? getTimeFromDateString(activityDate.startDate) : '';
        const endTime = openEnd ? '' : (' - ' + getTimeFromDateString(activityDate.endDate));
        return startTime + endTime;
    };

    const checkIsDayDisabled = (day: CalendarDay): boolean => {
        const date = state.date;
        date.set('date', day.dayLabel);
        date.set({
            hour: 23,
            minute: 59
        });
        const hasTime = activityDatesWithTimes.includes(date.format('DD-MM-YYYY'));

        let diffInMinutes = moment().diff(date, 'minutes');
        return diffInMinutes >= 0 || !hasTime;
    };

    // @ts-ignore
    const isSelectedDate = (day: CalendarDay) => {
        return state.selectedDate === moment(`${day}-${month}-${year}`, 'DD-MM-YYYY');
    };

    const getTextForSelectItem = (item: number) => {
        if (item === 0) {
            return t('at the experience day');
        } else if (item === 1) {
            return t('the day before the experience day');
        } else {
            return item + ' ' + t('days before the experience');
        }
    }

    const getStyleOfDate = (isDayClicked_: boolean, isToday: boolean, color: string) => {
        let style = {} as any;

        if (isDayClicked_) {
            style.backgroundImage = `linear-gradient(90deg, var(--link-color) 0%, var(--link-color) 100%)`;
            style.color = `var(--link-font-color)`;
        }

        // if (isToday) {
        //     style.color = `var(--link-font-color)`;
        // }

        return style;
    }

    if (!activityDatesWithTimes) return <></>;
    return (
        <div className={'giggle-calendar'}>
            <div className={'giggle-calendar__nav'}>
                <span className={`prev ${isFirstActivityDate ? 'disabled' : ''}`} onClick={onPrev} >
                    <img src={leftArrow} />
                </span>
                <span>{state.date.lang(locale).format("MMMM")} {state.date.format("Y")}</span>
                <span className={`next ${isLastActivityDate ? 'disabled' : ''}`} onClick={onNext}>
                    {/* filter: hue-rotate(90deg) */}
                    <img src={rightArrow} />
                </span>
            </div>

            <div className={'giggle-calendar__week'}>{ moment.weekdaysShort(true).map(name => <span>{name.replace(/\./g, '')}</span>)}</div>

            <div className={'giggle-calendar__days'}>
                {
                    state.monthDays.map((day: CalendarDay, index: number) => {
                        if (index > 6 && !day.dayLabel) return;
                        const isDayClicked_ = isDayClicked(day);
                        const isDayDisabled = checkIsDayDisabled(day);

                        return (
                            <div
                                className={
                                    `giggle-calendar__days__day ${isDayClicked_ ? 'open' : ''} ${isDayDisabled ? 'disabled_day' : 'active_day'} ${!day.dayLabel ? 'empty_day' : ''}`
                                }
                                style={{height: isDayClicked_ ? `${110 + (Math.ceil(day.dayTimes.length / 3) * 70) }px` : 'inherit'}}
                                key={`day-${index}`}
                                onClick={() => selectDate(day.dayLabel, isDayDisabled)}>
                                <span className={`giggle-calendar__days__day__date ${isDayClicked_ ? 'selected' : ''} ${isToday(day) ? 'today' : ''}`}
                                      style={getStyleOfDate(isDayClicked_, isToday(day), expState.hotel.color)
                                        //   isDayClicked_ ? {
                                        //       backgroundImage: `linear-gradient(90deg, #${expState.hotel.color} 0%, #${expState.hotel.color} 100%)`,
                                        //       color: `#${expState.hotel.color}`
                                        //   } : {}
                                      }

                                >
                                    {day.dayLabel || ''}

                                    {isDayClicked_ && <span style={{backgroundColor: `var(--link-color)`}} className={'corner'}></span>}
                                </span>
                                {
                                    isToday(day) && !isDayClicked_
                                    && <span className={'giggle-calendar__days__day__line'} style={{backgroundColor: `var(--link-color)`}}></span>
                                }

                                {
                                    (isDayClicked_ && day.dayTimes.length) &&
                                    <div className={'giggle-calendar__days__day__time open'}>
                                        {/* <div className={'giggle-calendar__days__day__time__label'}>{t('select.time.for')} {moment(day.dayTimes[0].startDate).format('dddd, MMMM DD')}</div> */}
                                        <div className="booking-dates__content giggle-calendar__days__day__time__interval2">
                                            {
                                                day.dayTimes
                                                    .map((activityDate, index) => {
                                                        const registrationDeadlinePassed_ = registrationDeadlinePassed(activityDate);
                                                        return (
                                                            (expState.experience.registrationRequired && (activityDate.bookingsNumber < expState.experience.maxParticipant || expState.experience.maxParticipant <= 0)) ?
                                                                <div key={`activity-date-${index}`} className={`booking-date available-seats ${registrationDeadlinePassed_ ? 'window-disabled' : ''}`}
                                                                     onClick={() => onDateClicked(activityDate)}>
                                                                    <div className="booking-date__box">
                                                                        {/*<div className="booking-date__box--date">*/}
                                                                        {/*    {moment(new Date(activityDate.startDate)).utc().locale(locale).format('ddd MMM D')}*/}
                                                                        {/*</div>*/}

                                                                        <div className="booking-date__box--hour">
                                                                            {getTimePeriod(activityDate)}
                                                                        </div>

                                                                        {
                                                                            registrationDeadlinePassed_ &&
                                                                            <span className="booking-date__date booking-date__book--fully">{t('registration.deadline.passed')}</span>
                                                                        }
                                                                        {
                                                                            expState.experience.maxParticipant > 0
                                                                            && activityDate.bookingsNumber > 0
                                                                            && !registrationDeadlinePassed_
                                                                            && <span className="participants-number">{activityDate.bookingsNumber}/{expState.experience.maxParticipant}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div key={`activity-date-booked-${index}`} className={`booking-date ${(expState.experience.tickets.length !== 0 && expState.experience.registrationRequired) ? 'fully-booked' : ''}`}>
                                                                    <div className="booking-date__box" style={{cursor: 'default'}} >
                                                                        {/*<div className='booking-date__date booking-date__date--fully'>{moment(new Date(activityDate.startDate)).format('ddd, MMM D')}</div>*/}
                                                                        <div className={`booking-date__hour booking-date__box--hour${(expState.experience.tickets.length !== 0 && expState.experience.registrationRequired) ? 'booking-date__hour--fully' : ''} `}>{getTimePeriod(activityDate)}</div>
                                                                        {
                                                                            expState.experience.registrationRequired &&
                                                                            <div className={`booking-date__date ${(expState.experience.tickets.length !== 0 && expState.experience.registrationRequired) ? 'booking-date__hour--fully' : ''}`}>{t('out.of.stock')}</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>


            {
                expState.hotel.bookingWindowEnabled &&
                expState.experience.registrationRequired
                && !((!expState.experience.registrationWindowTime && !expState.experience.registrationWindowDay)
                    || expState.experience.registrationWindowTime == null)
                && expState.experience.registrationWindowDay !== -1 &&
                <p className="dates-info-text">{t('registration.window', { day: getTextForSelectItem(expState.experience.registrationWindowDay as number), time: formatRegistrationWindowTime() })}</p>
            }
        </div>
    )
};

export default GiggleCalendar;
