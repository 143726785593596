// @flow
import * as React from 'react';
import { Location } from 'history';
import {match} from 'react-router';
import { useState, useEffect } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import Hotel, {HotelTranslation} from 'models/Hotel';

import {getLocale} from "../store/app/selectors";
import HotelCancelPolicy from "../models/Hotel";
import {fetchHotelOnly, getHotelCancelPolicies, getHotelTranslations} from "../services/HotelService";
import TranslationUtils from "../helpers/TranslationUtils";


interface IExceptionalPolicyProps {
    hotel: Hotel,
    location: Location,
    match: match
};



function ExceptionalPolicy(props: IExceptionalPolicyProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const [cancelPolicies, setCancelPolicies] = useState([] as HotelCancelPolicy[]);
    const [hotelTranslations, setHotelTranslations] = useState([] as HotelTranslation[]);
    const fetchHotelCancelPolicies = async() => {
        setCancelPolicies([] as HotelCancelPolicy[])
        setHotelTranslations([] as HotelTranslation[])
        const results: any = await getHotelCancelPolicies(props.hotel.id as string);
        const hotelTranslations: any = await getHotelTranslations(props.hotel.id as string);
        setHotelTranslations(hotelTranslations)
        setCancelPolicies(results)
    };

    useEffect(() => {
        if(Object.keys(props.hotel).length > 0) {
            fetchHotelCancelPolicies();
        } else {
            fetchHotelOnly((props.match.params as any).hotelCode, locale, dispatch);
        }
    }, [props.hotel]);


    return (
        <div className='flex fl-direction-co white' style={{padding: '30px', color: 'white'}}>
            <h1>{props.hotel?.name} Policy</h1>
            <div className="mrb-10px">
                {/*{cancelPolicies.length > 0 && <h2>Cancellation policies</h2>}*/}
                {/*{cancelPolicies.map((cancelPolicy: HotelCancelPolicy) => {*/}
                    {/*return (*/}
                        {/*<p className="cancellation-policy-card">*/}
                            {/*<b>{cancelPolicy.penaltyPercentage} percent</b> of the ticket price will be kept if cancelled <b>{cancelPolicy.daysBefore} day before</b> the experience takes place*/}
                        {/*</p>*/}
                    {/*);*/}
                {/*})}*/}
            </div>

            {   (props.hotel && TranslationUtils.getTranslatedField(hotelTranslations, locale, 'exceptionalPolicy') != '') &&
                    <div className="">
                        <h2>Exceptional policy</h2>
                        <p dangerouslySetInnerHTML={{__html: TranslationUtils.getTranslatedField(hotelTranslations, locale, 'exceptionalPolicy')}}></p>
                    </div>
            }
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel,
    }
}

export default connect(mapStateToProps)(ExceptionalPolicy);

