import Hotel from '../../models/Hotel';
import { HotelAction } from '../actions/HotelActions/HotelActions';
import { HotelActionTypes } from '../actions/HotelActions/HotelsActionTypes';


const initialState: Hotel = {} as Hotel;

export default (state = initialState, action: HotelAction) => {

    switch (action.type) {

        case HotelActionTypes.FETCH_HOTEL:
            const hotel = (action.payload as Hotel)
            const hotelPreloaded = (action.payload as Hotel)

            return { ...hotel, ...hotelPreloaded };
        default:
            return state;
    }
}
