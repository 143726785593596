import axios, {AxiosError} from "axios";
import {API} from "../config";
import {
    experienceLoadingErrorAction,
    experienceNotFoundAction,
    fetchExperienceAction
} from "../store/actions/ExperienceActions/ExperienceActionCreators";
import {
    fetchGuideAction,
    guideLoadingErrorAction,
    guideNotFoundAction
} from "../store/actions/GuideActions/GuideActionCreators";

export const fetchExperienceService = async (experienceId: string, languageCode: string, dispatch?: any) => {
    try {
        const response = await axios.get(`${API}/api/v2/experiences/${experienceId}/app`, {
            params: {
                languageCode,
            }
        });

        if (dispatch) {
            dispatch(fetchExperienceAction(response.data))
        }
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (dispatch) {
            dispatch(experienceLoadingErrorAction())
        }
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    dispatch(experienceNotFoundAction())
                }
            }
            return error.response.status;
        } else {
            return 500;
        }
    }
};


export const fetchGuideService = async (guideId: string, languageCode: string, dispatch?: any) => {
    try {
        const response = await axios.get(`${API}/api/people/${guideId}`, {
            params: {
                languageCode
            }
        });

        if (dispatch) {
            dispatch(fetchGuideAction(response.data))
        }
        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (dispatch) {
            dispatch(guideLoadingErrorAction())
        }
        if (error.isAxiosError && error.response) {
            if (error.response.status === 404) {
                if (dispatch) {
                    dispatch(guideNotFoundAction())
                }
            }
            return error.response.status;
        } else {
            return 500;
        }
    }
};
