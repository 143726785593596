import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";
import {RegionDestination} from "models/portal/Portal";


const initialState: RegionDestination = {} as RegionDestination;

export default (state = initialState, action: PortalAction) => {
    switch (action.type) {
        case PortalActionTypes.FETCH_DESTINATION:
            const destination = (action.payload as RegionDestination)
            const destinationPreloaded = (action.payload as RegionDestination)

            return { ...destination, ...destinationPreloaded };
        default:
            return state;
    }
}
