import * as React from "react";
import {useEffect, useState} from "react";
import {match} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import "../../assets/styles/home-page.css"
import "../../assets/styles/category-experiences.css"
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {getLocale} from "../../store/app/selectors";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import Loader from "../../components/common/Loader";
import '../../assets/css/v2/overview-page.css';
import MasonryGrid from "components/common/MasonryGrid";
import Header from "components/common/Header";
import {fetchExperienceAction} from "store/actions/ExperienceActions/ExperienceActionCreators";
import {ExperienceModel} from "models/ExperienceModel";
import moment from "moment/moment";
import {fetchExperiences} from "services/PortalService";
import {Host, Host as HostModel, Breadcrumbs as BreadcrumbsModel} from "models/portal/Portal";
import {fetchHostAction, putBreadcrumbAction} from "store/actions/PortalActions/PortalActions";
import Breadcrumbs from "components/common/Breadcrumbs";
import LoaderV2 from "components/common/LoaderV2";

interface IProps {
    hotel: HostModel,
    breadcrumb: BreadcrumbsModel,
    match: match
};

function Experiences(props: IProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [scrolling, setScrolling] = useState<boolean>(false);
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false,
        page: 1,
        limit: 50,
        hasMore: true,
        loadMoreLoading: false
    });


    useEffect(() => {
        dispatch(fetchExperienceAction({} as ExperienceModel));
    }, []);

    useEffect(() => {
        moment.locale(locale);

        const loadHotel = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });
            const experiences: any = await fetchExperiences(locale, state.page, state.limit);

            if (experiences === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (experiences === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                const hostResult = {} as Host;
                (hostResult as HostModel).experiences = experiences.results;
                dispatch(fetchHostAction(hostResult));
                setState({
                    ...state,
                    loading: false,
                });
            }
        };

        if (!state.loading && Object.keys(props.hotel).length == 0 && __BROWSER__) {
            loadHotel();
        }

        dispatch(putBreadcrumbAction({
            category: null,
            experience: null,
            destination: null,
            region: null,
            experiencesVisited: true,
            last: 'experiences'
        }));
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (!state.loadMoreLoading && window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) { // added buffer of 10px
                setState(prevState => ({
                    ...prevState,
                    loadMoreLoading: true,
                }));

                loadMoreExperiences(props.hotel.experiences || []);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state.loadMoreLoading, props.hotel.experiences, props.breadcrumb]);

    const loadMoreExperiences = async (existingExperiences: any[]) => {
        const nextPage = state.page + 1;
        const experiencesResult: any = await fetchExperiences(locale, nextPage, state.limit);

        if (experiencesResult.results.length === 0) {
            setState(prevState => ({
                ...prevState,
                loadMoreLoading: false,
                hasMore: false
            }));
            return;
        }

        const combinedExperiences = [...existingExperiences, ...experiencesResult.results];
        const updatedExperiencesState: any = {
            ...props.hotel,
            experiences: combinedExperiences
        };

        dispatch(fetchHostAction(updatedExperiencesState));

        setState(prevState => ({
            ...prevState,
            loadMoreLoading: false,
            page: nextPage
        }));
    };


    if ((Object.keys(props.hotel).length == 0 || state.loading) && !state.notFound) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Data</h1>
            </div>
        )
    }

    return (
        <>
            <div style={{display: 'block'}} className={'overview-page'}>
                { state.loadMoreLoading && <LoaderV2 /> }
                <Helmet>
                    <title>Giggle Experiences</title>
                    <meta name="description" content={''} />
                    <meta property="og:description" content={''}/>
                </Helmet>

                <Header />

                <Breadcrumbs/>

                <br/>

                <h1 className={'overview-page__title'}>Experiences</h1>

                <p className={'overview-page__subtitle'}>Amazing experiences for your {new Date().getFullYear()} holiday</p>

                <br/>
                <br/>
                {
                    !state.loading && <MasonryGrid experiences={props.hotel.experiences} />
                }
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.host,
        breadcrumb: state.breadcrumb,
    }
}

export default connect(mapStateToProps)(Experiences);
