import * as React from "react";
import {ExperienceImage} from "../../models/ExperienceModel";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/css/v2/image-slider.css';
import GiggleLogo from '../../assets/icons/giggle-poweredby-neg.svg'
import GiggleLogoBlack from '../../assets/icons/giggle-poweredby-black.svg'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {useContext} from "react";
import ExperienceContext from "ExperienceContext";

interface IImageSliderProps {
    images: ExperienceImage[],
    slideRightNumber?: number;
    slideLeftNumber?: number;
    guideImage: string;
    isWhiteBackground?: any
};

export default function (props: IImageSliderProps) {
    const {expState} = useContext(ExperienceContext);

    const renderImages = () => {
        return props.images.map((img, i) => (
            <div className={'experience-image'} key={`exp-img-${i}`} style={{backgroundImage: `url(${img.url})`}} />
        ));
    };

    const customRenderIndicator = (clickHandler: any, isSelected: boolean, index: number, label: string | number) => {
        return (
            <div
                key={`exp-img-indicator-${index}`}
                className={`indicator `}
                onClick={clickHandler}>
                <div className={`indicator-filled ${isSelected ? ' active' : ''}`}></div>
            </div>
        );
    };

    return (
        <div className="giggle-slider">
            <div>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    interval={3000}
                    showIndicators={true}
                    swipeable={false}
                    showArrows={true}
                    renderIndicator={customRenderIndicator}
                >
                    {renderImages()}
                </Carousel>

                {/* <div className={'experience-cat-title'}>
                    {
                        (expState.experience.category && !expState.hotel.disableSimilarExperiences) &&
                        <div className="experience-cat-title__category" dangerouslySetInnerHTML={{ __html: expState.experience.category.translation.name}} />
                    }
                    <h2>{expState.experience.translation.title}</h2>
                </div> */}
                {
                    props.guideImage &&
                    <div className={'guide-wrapper'}>
                        <img className={'guide-wrapper__image'} src={props.guideImage} alt="" />
                    </div>
                }

                <div className={'giggle-logo'}>
                    <img src={props.isWhiteBackground ? GiggleLogoBlack : GiggleLogo } alt=""/>
                </div>
            </div>
        </div>
    );
};
