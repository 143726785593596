import {ExperienceModel} from "../../../models/ExperienceModel";
import {ExperienceActionTypes} from "./ExperienceActionTypes";
import {ExperienceAction} from "./ExperienceActions";

export const fetchExperienceAction = (experience: ExperienceModel) => {
    const FetchExperienceAction: ExperienceAction = {
        type: ExperienceActionTypes.FETCH_EXPERIENCE,
        payload: experience
    };

    return FetchExperienceAction;
};


export const experienceNotFoundAction = () => {
    const ExperienceNotFoundAction: ExperienceAction = {
        type: ExperienceActionTypes.EXPERIENCE_NOT_FOUND,
        payload: {}
    };

    return ExperienceNotFoundAction;
};


export const experienceLoadingErrorAction = () => {
    const ExperienceLoadingErrorAction: ExperienceAction = {
        type: ExperienceActionTypes.EXPERIENCE_LOADING_ERROR,
        payload: {}
    };

    return ExperienceLoadingErrorAction;
};
