import { Action } from 'redux';
import {Breadcrumbs, Host, RegionDestination, SearchModel} from "models/portal/Portal";
import {ExperienceCategory} from "models/ExperienceModel";

export interface PortalAction extends Action {
    payload: any
}

export enum PortalActionTypes {
    FETCH_HOST = "@@host/fetch_host",
    HOST_NOT_FOUND = "@@host/host_not_found",
    HOST_LOADING_ERROR = "@@host/host_loading_error",

    FETCH_REGION = "@@region/fetch_region",
    REGION_NOT_FOUND = "@@region/region_not_found",
    REGION_LOADING_ERROR = "@@region/region_loading_error",

    FETCH_DESTINATION = "@@destination/fetch_destination",
    DESTINATION_NOT_FOUND = "@@destination/destination_not_found",
    DESTINATION_LOADING_ERROR = "@@destination/destination_loading_error",

    FETCH_SEARCH_SUGGESTIONS = "@@search/fetch_search_suggestions",
    FETCH_CATEGORY = "@@category/fetch_category",
    PUT_BREADCRUMBS = "@@breadcrumb/put_breadcrumb",
}


export const fetchHostAction = (host: Host) => {
    const fetchHostAction: PortalActionTypes = {
        type: PortalActionTypes.FETCH_HOST,
        payload: host
    }

    return fetchHostAction;
}


export const hostNotFoundAction = () => {
    const HostNotFoundAction: PortalActionTypes = {
        type: PortalActionTypes.HOST_NOT_FOUND,
        payload: {}
    }

    return HostNotFoundAction;
}


export const hostLoadingErrorAction = () => {
    const HostLoadingErrorAction: PortalActionTypes = {
        type: PortalActionTypes.HOST_LOADING_ERROR,
        payload: {}
    }

    return HostLoadingErrorAction;
}

export const fetchRegionAction = (region: RegionDestination) => {
    const fetchAction: PortalActionTypes = {
        type: PortalActionTypes.FETCH_REGION,
        payload: region
    }

    return fetchAction;
}

export const fetchDestinationAction = (destination: RegionDestination) => {
    const fetchAction: PortalActionTypes = {
        type: PortalActionTypes.FETCH_DESTINATION,
        payload: destination
    }

    return fetchAction;
}

export const fetchSearchSuggestionsAction = (searchSuggestions: SearchModel) => {
    const fetchAction: PortalActionTypes = {
        type: PortalActionTypes.FETCH_SEARCH_SUGGESTIONS,
        payload: searchSuggestions
    }

    return fetchAction;
}

export const fetchCategoryAction = (category: ExperienceCategory) => {
    const fetchAction: PortalActionTypes = {
        type: PortalActionTypes.FETCH_CATEGORY,
        payload: category
    }

    return fetchAction;
}

export const putBreadcrumbAction = (breadcrumbs: Breadcrumbs) => {
    const fetchAction: PortalActionTypes = {
        type: PortalActionTypes.PUT_BREADCRUMBS,
        payload: breadcrumbs
    }

    return fetchAction;
}
