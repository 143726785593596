import * as React from "react";
import {useEffect} from "react";
import {match} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import "../../assets/styles/home-page.css"
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {useState} from "react";
import {getLocale} from "../../store/app/selectors";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import Loader from "../../components/common/Loader";
import {HotelVisited} from "../../services/GigglyticsService";
import DeactivatedAccount from "pages/DeactivatedAccount";
import {fetchExperienceAction} from "store/actions/ExperienceActions/ExperienceActionCreators";
import {ExperienceModel} from "models/ExperienceModel";
import '../../assets/css/v2/hostpage.css';
import Header from "components/common/Header";
import LogoPlaceholder from '../../assets/icons/hostpage/hotel-logo-placeholder.svg';
import LocationPlaceholder from '../../assets/icons/hostpage/location-placeholder.svg';
import FacebookPlaceholder from '../../assets/icons/hostpage/facebook-placeholder.svg';
import InstagramPlaceholder from '../../assets/icons/hostpage/instagram-placeholder.svg';
import Pin from '../../assets/icons/hostpage/pin.svg';
import Facebook from '../../assets/icons/experience-detail/facebook.svg';
import Instagram from '../../assets/icons/experience-detail/instagram.svg';
import OpenNewWindow from '../../assets/icons/hostpage/open-window.svg';
import MasonryGrid from "components/common/MasonryGrid";
import { Host as HostModel } from "models/portal/Portal";
import {fetchHostExperiencesService, fetchPlainHotelService} from "services/PortalService";
import {fetchHostAction} from "store/actions/PortalActions/PortalActions";

interface IHomeProps {
    hotel: HostModel,
    match: match
};

function Host(props: IHomeProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false
    });

    const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(props.hotel.address)}`;

    useEffect(() => {
        dispatch(fetchExperienceAction({} as ExperienceModel));
    }, []);

    useEffect(() => {
        moment.locale(locale);
        const loadHotel = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });
            const [hostResult, experiencesResult]: any = await Promise.all([
                fetchPlainHotelService((props.match.params as any).hotelCode),
                fetchHostExperiencesService((props.match.params as any).hotelCode, locale, 1, 50),
            ]);

            if (hostResult === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (hostResult === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                (hostResult as HostModel).experiences = experiencesResult.results;
                dispatch(fetchHostAction(hostResult));
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        if (!state.loading && Object.keys(props.hotel).length == 0 && __BROWSER__) {
            loadHotel();
        }

        if (Object.keys(props.hotel).length > 0 && !state.loading) {
            dispatch(fetchHostAction({} as HostModel));
        }
    }, [props.hotel]);

    if ((Object.keys(props.hotel).length == 0 || state.loading) && !state.notFound) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.notFound) {
        return <DeactivatedAccount/>;
        // @ts-ignore
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Hotel Not Found`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Hotel Not Found</h1>
            </div>

            // <DeactivatedAccount/>
        )
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Hotel Data</h1>
            </div>
        )
    }

    if (!props.hotel.active) {
        return <DeactivatedAccount/>;
    }

    if(__BROWSER__) {
        // Gigglytics - Hotel visited
        HotelVisited(props.hotel.id as string);
    }

    return (
        <>
            <Helmet>
                <title>{`${props.hotel.name} ${t('events')}`}</title>
                {
                    props.hotel.noIndex ?
                        <meta name="robots" content="noindex,nofollow"/> :
                        <meta name="robots" content="all"/>
                }
                <link rel="canonical" href={`https://giggle.tips/${locale}/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://giggle.tips/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="de" href={`https://giggle.tips/de/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="en" href={`https://giggle.tips/en/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="it" href={`https://giggle.tips/it/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="fr" href={`https://giggle.tips/fr/hosts/${props.hotel.code}`} />
                <link rel="alternate" hrefLang="es" href={`https://giggle.tips/es/hosts/${props.hotel.code}`} />
            </Helmet>

            <div className={'hostpage'} style={{ backgroundImage: `url('${props.hotel.coverImageUrl || ''}')` }}>
                <Header />

                <div className={'hostpage__info'}>
                    <div className={'hostpage__info__logo-holder'} style={{backgroundImage: `url('${LogoPlaceholder}')`}}>
                        <div className={'hostpage__info__logo-holder__white'}>
                            <img src={props.hotel.logoUrl}/>
                        </div>
                    </div>

                    <p className={'hostpage__info__title'}>EXPERIENCES FROM</p>
                    <h1 className={'hostpage__info__name'}>{props.hotel.name}</h1>

                    <div className={'hostpage__info__general'}>
                        <div className={'hostpage__info__general__location'}>
                            <div className={'icon-placeholder'} style={{backgroundImage: `url('${LocationPlaceholder}')`}}>
                                <img src={Pin} alt=""/>
                            </div>
                            <span className={'hostpage__info__general__location__name'}>
                                {props.hotel.address} - <a href={googleMapsUrl} target={'_blank'}>Open in Google Maps</a>
                            </span>
                        </div>

                        {
                            (props.hotel.facebookUrl || props.hotel.instagramUrl) &&
                            <div className={'hostpage__info__general__socials'}>
                                <ul className={'hostpage__info__general__socials__list'}>
                                    {
                                        props.hotel.fbPageId &&
                                        <li className={'hostpage__info__general__socials__list__item'}>
                                            <a href={`https://facebook.com/${props.hotel.fbPageId}`} target='_blank'>
                                                <div className={'icon-placeholder'} style={{backgroundImage: `url('${FacebookPlaceholder}')`}}>
                                                    <img src={Facebook} alt=""/>
                                                </div>
                                                Facebook
                                            </a>
                                        </li>
                                    }
                                    {
                                        props.hotel.instagramUrl &&
                                        <li className={'hostpage__info__general__socials__list__item'}>
                                            <a href={props.hotel.instagramUrl} target='_blank'>
                                                <div className={'icon-placeholder'} style={{backgroundImage: `url('${InstagramPlaceholder}')`}}>
                                                    <img src={Instagram} alt=""/>
                                                </div>
                                                Instagram
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>

                    {
                        props.hotel.website &&
                        <div className={'hostpage__info__website'}>
                            <a href={props.hotel.website} target={'_blank'} className={'hostpage__info__website__btn'}>
                                <span>Visit Website</span>
                                <img src={OpenNewWindow} alt=""/>
                            </a>
                        </div>
                    }
                </div>
            </div>

            <div className={'hotel-experiences'}>
                <div className={'hotel-experiences__title'}>
                    <h3>{props.hotel.name} {t('Experiences')}</h3>
                    <div className={'line'}></div>
                </div>
            </div>

            <MasonryGrid fromHost={true} experiences={props.hotel.experiences || []} />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.host,
    }
}

export default connect(mapStateToProps)(Host);
