import * as React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useContext, useState} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {
    getHcQuery,
    getQueryParam,
    getResourceTimes,
    getTimeAsDayName,
    getTimeFromDateString,
    showRegistrationButton
} from "../../../helpers/ExperienceHelper";
import {decodeHtml} from "../../../helpers/HtmlEntities";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import {Link, useLocation} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import {Location} from "history";
import HostPlaceHolder from '../../../assets/icons/experience-detail/placeholder-provider.svg';
import FacebookPlaceHolder from '../../../assets/icons/experience-detail/facebook-placeholder.svg';
import InstagramPlaceHolder from '../../../assets/icons/experience-detail/instagram-placeholder.svg';
import FacebookIcon from '../../../assets/icons/experience-detail/facebook.svg';
import InstagramIcon from '../../../assets/icons/experience-detail/instagram.svg';
import CalendarIcon from '../../../assets/icons/experience-detail/calendar.svg';
import UsersIcon from '../../../assets/icons/experience-detail/guests.svg';
import TimeIcon from '../../../assets/icons/experience-detail/time.svg';
import PinIcon from '../../../assets/icons/experience-detail/pin.svg';
import {toSeoUrl} from "helpers/GeneralHelper";

interface IProps {
    navigateToBookingPart: () => void;
};

export default function (props: IProps) {
    const {expState} = useContext(ExperienceContext);
    const locale = useSelector(getLocale);
    const location = useLocation();
    const {t} = useTranslation();
    const [readMoreDescription, setReadMoreDescription] = useState(expState.experience.translation.longDescription.length > 150 ? true : false);
    const { width } = __BROWSER__ ? useWindowDimensions() : { width: 0 };


    let peopleCapacity = '';
    const getPeopleCapacity = () => {
        let min = expState.experience.minParticipant === -1 ? '∞' : expState.experience.minParticipant;
        let max = expState.experience.maxParticipant === -1 ? '∞' : expState.experience.maxParticipant;

        if (((expState.experience.minParticipant || 0) > 0 && (expState.experience.minParticipant || 0) != -1)
            || ((expState.experience.maxParticipant || 0) > 0 && (expState.experience.maxParticipant || 0) != -1)) {
            // peopleCapacity = min + ' - ' + max + ' ' + t('group.persons');
            peopleCapacity = min + ' - ' + max;
        }
        return peopleCapacity;
    };

    const getTextForSelectItem = (item: number) => {
        if (item === 0) {
            return t('at the experience day');
        } else if (item === 1) {
            return t('the day before the experience day');
        } else {
            return item + ' ' + t('days before the experience');
        }
    }

    const formatRegistrationTime = (reg: boolean = true) => {
        if (reg) {
            if(!expState.experience.registrationDeadlineTime) {
                return;
            }
            return (expState.experience.registrationDeadlineTime as string).substring(0, 4).replace(/^(\d{2})/, '$1:');
        } else {
            if(!expState.experience.registrationWindowTime) {
                return;
            }
            return (expState.experience.registrationWindowTime as string).substring(0, 4).replace(/^(\d{2})/, '$1:');
        }
    };

    let durationText = '';
    const getDurationText = () => {
        if (expState.experience.type == 'ACTIVITY') {
            if (expState.experience.activityDates.length > 0) {
                const firstActivityDate = expState.experience.activityDates[0];
                if (firstActivityDate.endDate.startsWith('0001')) {
                    return '';
                }
                const startDate = moment(new Date(firstActivityDate.startDate));
                const endDate = moment(new Date(firstActivityDate.endDate));
                if (firstActivityDate.endDate.includes('00:00:00') || endDate < startDate) {
                    endDate.add(1, 'days');
                }
                const duration = moment.duration(endDate.diff(startDate));
                const durationHours = duration.asHours();
                const durationMinutes = duration.asMinutes();

                if (durationHours >= 1) {
                    durationText = Math.floor(durationHours).toString();
                    if (Math.floor(durationHours) > 1) {
                        durationText += ` ${t('hours')}`
                    } else {
                        durationText += ` ${t('hour')}`
                    }
                    if (durationMinutes % 60 > 0) {
                        durationText += ` ${(durationMinutes % 60).toString()} ${t('minutes')}`
                    }
                } else {
                    durationText = durationMinutes.toString();
                    if (durationMinutes > 1) {
                        durationText += ` ${t('minutes')}`
                    } else {
                        durationText += ` ${t('minute')}`
                    }
                }

                return durationText;
            }
            return '';
        } else {
            const times = getResourceTimes(expState.experience.resourceEvents);
            return times.map(time => t(getTimeAsDayName(time))).join(', ')
            // let event = expState.experience.resourceEvents ? expState.experience.resourceEvents[0] : undefined;
            // if (!event) { return '' };

            // let startTime = event.startTime ? event.startTime.toString().substring(0, 4).replace(/^(\d{2})/, '$1:') : '';
            // let endTime = event.openEnd ? '' : event.endTime ? (' - ' + event.endTime.toString().substring(0, 4).replace(/^(\d{2})/, '$1:')) : '';
            // durationText = startTime + endTime;
            // return durationText;
        }
    };

    let experienceOptions = '';
    const getExperienceOptions = () => {
        if (expState.experience.type == 'ACTIVITY') {
            if (expState.experience.activityDates.length > 0) {
                const firstActivityDate = expState.experience.activityDates[0];
                const openEnd = firstActivityDate.endDate.startsWith('0001');
                const startDate = moment(new Date(firstActivityDate.startDate));
                const startTime = firstActivityDate.startDate ? ' • ' + getTimeFromDateString(firstActivityDate.startDate) : '';
                const endTime = openEnd ? '' : (' - ' + getTimeFromDateString(firstActivityDate.endDate));
                const date = startDate.format('ddd,MMM DD');
                experienceOptions = date + startTime + endTime;
            }
            return experienceOptions;
        } else {
            if (!expState.experience.tickets || !expState.experience.tickets.length) { return ''; }
            let label = t('');
            if (expState.experience.tickets.length > 1) {
                label = t('');
            }
            experienceOptions = expState.experience.tickets.length + ' ' + label;
            return experienceOptions;
        }
    };

    const description = decodeHtml(expState.experience.translation.longDescription.replace(/(?:\r\n|\r|\n)/g, '<br/>'));

    const isFromHostSources = (): boolean => {
        if (
            !location.state &&
            !Boolean(getHcQuery(location.search)) &&
            !Boolean(getQueryParam(location.search, 'redirectTo')) &&
            !Boolean(getQueryParam(location.search, 'fbclid'))
        ) return false;

        return Boolean(expState.redirectHotelCode || expState.redirectTo || Boolean(getHcQuery(location.search)) || Boolean(getQueryParam(location.search, 'fbclid')));
    }

    return (
        <>
            <div className="experience-header" style={{marginBottom: showRegistrationButton(expState.experience) ? 'inherit' : '0'}}>
                {/*{*/}
                {/*    !isFromHostSources() &&*/}
                {/*    <div className="breadcrumbs" style={{border: 'none', margin: 0, marginLeft: '-16px', zIndex: 100}}>*/}
                {/*        /!*<p>Home   /   Experiences   / {category.translation.name}  </p>*!/*/}
                {/*        /!*<p style={{border: 'none', margin: 0, padding: 0}}>*!/*/}
                {/*        /!*    <a href={'https://hotel.giggle.tips/'}>Home</a> /&nbsp;*!/*/}
                {/*        /!*    <Link to={{pathname: `/${locale}/experiences/${expState.experience.category.translation.urlName}`}}>{expState.experience.category.translation.name}</Link> /&nbsp;*!/*/}
                {/*        /!*    <a href={void(0)}>{expState.experience.translation.title}</a>*!/*/}
                {/*        /!*</p>*!/*/}
                {/*    </div>*/}
                {/*}*/}
                {
                    (expState.experience.category && !expState.hotel.disableSimilarExperiences) &&
                    <div className="experience-detail__category-title" dangerouslySetInnerHTML={{ __html: expState.experience.category.translation.name}} />
                }

                <h1 className={'experience-detail__title'}>{expState.experience.translation.title}</h1>

                {
                    showRegistrationButton(expState.experience) &&
                    <div className="experience-header__btn-wrapper">
                        <button
                            onClick={props.navigateToBookingPart}
                            className={`${expState.noWindowsEnabled ? 'nowindow-button' : ''}`}
                            >
                            {expState.experience.type == 'ACTIVITY' ? t('book') : t('request.resource')}
                            </button>
                        {
                            !expState.noWindowsEnabled &&
                            <p className="mrl-20px">{expState.experience.type == 'ACTIVITY' ? t('it.takes.one.minute') : t('request.now')}</p>
                        }
                        {
                            expState.noWindowsEnabled &&
                            <p className="mrl-20px">{t('registration.window', { day: getTextForSelectItem(expState.experience.registrationWindowDay as number), time: formatRegistrationTime(false) })}</p>
                        }
                    </div>
                }
            </div>

            <div className="experience-general-infos">
                {
                    (expState.experience.type == 'ACTIVITY' && getExperienceOptions()) &&
                    <div className="experience-general-infos__item">
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={CalendarIcon} alt="" className={'icon'}/>
                            <span className='experience-general-infos__item-detail'>{expState.experience.type == 'ACTIVITY' ? t('experience.options') : t('experience.options.services')}</span>
                        </div>

                        {/*<i className='material-icons experience-general-infos__item-i'>date_range</i>*/}

                        <span className='experience-general-infos__item-txt'>{experienceOptions}</span>
                    </div>
                }

                {
                    getDurationText() &&
                    <div className="experience-general-infos__item">
                        {/*<i className='material-icons experience-general-infos__item-i'>access_time</i>*/}
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={TimeIcon} alt="" className={'icon'}/>

                            <span className='experience-general-infos__item-detail'>
                                {
                                    expState.experience.type == 'ACTIVITY' ? t('duration.activity') : t('duration')
                                }
                            </span>
                        </div>

                        <span className='experience-general-infos__item-txt'>{getDurationText()}</span>
                    </div>
                }

                {
                    (expState.experience.type == 'ACTIVITY' && getPeopleCapacity()) &&
                    <div className="experience-general-infos__item">
                        {/*<i className='material-icons experience-general-infos__item-i'>people_outline</i>*/}
                        <span className='experience-general-infos__item-detail'>
                            <img src={UsersIcon} alt="" className={'icon'}/>
                            <span>{t('group.size')}</span>
                        </span>
                        <span className='experience-general-infos__item-txt'>{peopleCapacity}</span>
                    </div>
                }

                {
                    expState.experience.registrationDeadlineDay >= 0 &&
                    <div className="experience-general-infos__item">
                        {/*<i className='material-icons experience-general-infos__item-i'>alarm_off</i>*/}
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={CalendarIcon} alt="" className={'icon'}/>
                            <span className='experience-general-infos__item-detail'>{t('registration deadline')}</span>
                        </div>

                        <span className='experience-general-infos__item-txt'> {getTextForSelectItem(expState.experience.registrationDeadlineDay as number)} <br/> {formatRegistrationTime()} •</span>
                    </div>
                }

                {
                    (expState.experience.address && isFromHostSources()) &&
                    <div className="experience-general-infos__item">
                        {/*<i className='material-icons experience-general-infos__item-i'>pin_drop</i>*/}
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={PinIcon} alt="" className={'icon'}/>
                            <span className='experience-general-infos__item-detail'>{t('location')}</span>
                        </div>

                        <span className='experience-general-infos__item-txt'>{expState.experience.address}</span>
                    </div>
                }

                {
                    expState.experience.translation.meetingPoint &&
                    <div className="experience-general-infos__item">
                        {/*<i className='material-icons experience-general-infos__item-i'>zoom_out_map</i>*/}
                        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={PinIcon} alt="" className={'icon'}/>
                            <span className='experience-general-infos__item-detail'>{t('meeting.point')}</span>
                        </div>

                        <span className='experience-general-infos__item-txt'>{expState.experience.translation.meetingPoint}</span>
                    </div>
                }
            </div>

            {
                !isFromHostSources() &&

                <div className={'experience-host'}>
                    <div className={'experience-host__title'}>
                        <h2>{t('Host')}</h2>
                        <div className={'line'}></div>
                    </div>
                    <div className={'experience-host__top'}>
                        <div className={'experience-host__image-placeholder'} style={{backgroundImage: `url('${HostPlaceHolder}')`}}>
                            <div className={'placeholder-middle'} >
                                <span className={'placeholder-middle__logo'} style={{backgroundImage: `url('${expState.hotel.logoUrl}')`}}></span>
                            </div>
                        </div>
                        <div className={'experience-host__top__content'}
                              style={{
                                justifyContent: expState.hotel.name.length < 40 && !expState.hotel.address ? 'center' : ''
                              }}
                        >
                            <div>
                                <div className={'title'}>{expState.hotel.name}</div>
                                {
                                    expState.hotel.address &&
                                    <div className={'address'}>{expState.hotel.address}</div>
                                }
                            </div>

                            {
                                !isFromHostSources() &&
                                <div className={'host-profile desktop'}>
                                    <a href={expState.hotel.website}
                                       target={'_blank'}
                                       className={'host-profile__website'}
                                       style={{
                                           background: expState.buttonColor ? `var(--link-color)` : '',
                                           color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                                       }}
                                    >{t('Visit website')}</a>
                                    <a href={`/${locale}/hosts/${expState.hotel.code}`} className={'host-profile__profile'}>{t('View Profile')}</a>
                                </div>
                            }

                            {
                                expState.hotel.facebook || expState.hotel.instagram &&
                                <div className={'socials'}>
                                    {
                                        expState.hotel.facebook &&
                                        <a href={'https://facebook.com'} className={'socials__item'}>
                                            <span className={'icon'} style={{backgroundImage: `url('${FacebookPlaceHolder}')`}}>
                                                <img src={FacebookIcon} alt=""/>
                                            </span>
                                                    <span className={'name'}>
                                                Facebook
                                            </span>
                                        </a>
                                    }

                                    {
                                        expState.hotel.instagram &&
                                        <a href={'https://instagram.com'} className={'socials__item'}>
                                            <span className={'icon'} style={{backgroundImage: `url('${InstagramPlaceHolder}')`}}>
                                                <img src={InstagramIcon} alt=""/>
                                            </span>
                                                <span className={'name'}>
                                                Instagram
                                            </span>
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    {/* host website mobile */}
                    <div className={'host-profile mobile'}>
                        <a href={expState.hotel.website}
                           style={{
                               background: expState.buttonColor ? `var(--link-color)` : '',
                               color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                           }}
                           target={'_blank'}
                           className={'host-profile__website'}>{t('Visit website')}</a>
                        <a href={`/${locale}/hosts/${expState.hotel.code}`} className={'host-profile__profile'}>{t('View Profile')}</a>
                    </div>
                    {/*{*/}
                    {/*    expState.hotel.website &&*/}
                    {/*    <div className={'host-site'}>*/}
                    {/*        <a href={expState.hotel.website} target={'_blank'} className={'host-site__link'}>Visit website</a>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            }

            {/*<div className="divider">*/}
            {/*    <div className="line"></div>*/}
            {/*</div>*/}

            {
                <div className="experience-wwd">
                    <div className="experience-wwd__title">
                        <div>{t("what we'll do")}</div>
                        <div className={'line'}></div>
                    </div>
                    <div className={`experience-wwd__content`}
                         dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            }

            {/*{*/}
                {/*width > 0 && width < 575.98 && <div className="experience-wwd">*/}
                    {/*<div className="experience-wwd__title">{t("what we'll do")}</div>*/}
                    {/*<div className={`experience-wwd__content ${readMoreDescription ? 'gradient' : ''}`}*/}
                         {/*dangerouslySetInnerHTML={{ __html: description }}*/}
                         {/*style={{overflow: 'hidden', maxHeight: readMoreDescription ? '150px' : 'auto', transition: 'max-height 0.25s ease-in'}}*/}
                    {/*/>*/}
                    {/*<div className="experience-wwd__read-more">*/}
                        {/*<button onClick={() => setReadMoreDescription(!readMoreDescription)}>{readMoreDescription ? "Read more": "Show less" }</button>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*}*/}
        </>
    );
};
