import * as React from 'react';
import { Component } from 'react';
import { People } from '../../models/People';
import {Link} from "react-router-dom";
import {toSeoUrl} from "../../helpers/GeneralHelper";

interface InformationsProps {
    hotelName: string;
    hotelColor: string;
    hotelCode: string;
    locale: string;
    peopleList: People[];
    t: any;
}

export default class Informations extends Component<InformationsProps> {
    componentDidMount() {
        // this.locale = useSelector(getLocale);
        if (__BROWSER__) {
            this.resize();
            window.addEventListener('resize', this.resize);
        }
    }

    componentWillUnmount() {
        if (__BROWSER__) {
            window.removeEventListener('resize', this.resize);
        }
    }

    lastWidth = __BROWSER__ ? window.innerWidth : 150;

    resize = () => {
        // let fontWidth =
        //     parseInt((getComputedStyle(this.title).width as any).replace('px', ''), 10) + 45;
        // let fontSize = parseInt(getComputedStyle(this.title).fontSize || '', 10);
        //
        // const up = this.lastWidth < window.innerWidth;
        //
        // if (fontWidth > window.innerWidth && !up) {
        //     while (fontWidth > window.innerWidth && fontSize > 5) {
        //         this.title.style.fontSize =
        //             parseInt(getComputedStyle(this.title).fontSize || '', 10) - 1 + 'px';
        //         this.title.style.marginTop = '0px';
        //         fontSize = parseInt(getComputedStyle(this.title).fontSize || '', 10);
        //         fontWidth =
        //             parseInt((getComputedStyle(this.title).width as any).replace('px', ''), 10) +
        //             45;
        //     }
        // } else if (fontSize < 67 && fontWidth < window.innerWidth && up) {
        //     this.title.style.fontSize =
        //         parseInt(getComputedStyle(this.title).fontSize || '', 10) + 1 + 'px';
        //     this.title.style.marginTop = '0px';
        //     fontSize = parseInt(getComputedStyle(this.title).fontSize || '', 10);
        //     fontWidth =
        //         parseInt((getComputedStyle(this.title).width as any).replace('px', ''), 10) + 50;
        // }
        //
        // this.lastWidth = window.innerWidth;
    };

    private title: any;

    showWelcomeTitle = () => {
        return this.props.hotelCode !== 'dorint-bad-brueckenau'
    }
    render() {
        return (
            <>
                <div className="hotel-informations fade-up">
                    {
                        this.showWelcomeTitle() &&
                        <div className="hotel-informations__welcome">{this.props.t('welcome to')}</div>
                    }
                    <h1 ref={(ref) => (this.title = ref)} className="hotel-informations__hotel-name">
                        {(this.props.hotelName || '').toUpperCase()}
                    </h1>

                    <div className="hotel-guides fade-up">
                        {this.props.peopleList.map((people) => {
                            return (
                                <Link key={`people${people.id}`} className="hotel-guide"
                                      to={{
                                          pathname: `${this.props.locale == 'en' ? '' : '/'+this.props.locale}/guides/${people.id}/${toSeoUrl(people.firstName + ' ' + people.lastName, false)}?hc=${this.props.hotelCode}`,
                                          state: {guideId: people.id}
                                      }}>
                                    <div style={{ position: 'relative' }}>
                                        <div className="hotel-animation"
                                             style={{
                                                 borderColor: this.props.hotelColor ? `#${this.props.hotelColor}` : '',
                                             }}/>
                                        <div
                                            className="hotel-guide__img"
                                            style={{
                                                backgroundImage: `url('${
                                                    people.profilePicture || ''
                                                }')`,
                                            }}
                                        />
                                    </div>
                                    <span className="hotel-guide__name">
                                    {people.firstName + ' ' + people.lastName}
                                </span>
                                    <span className="hotel-guide__title">
                                    {people.translation.title}
                                </span>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}
