import { HotelActionTypes } from './HotelsActionTypes'
import { HotelAction } from './HotelActions'
import Hotel from 'models/Hotel'

export const fetchHotelAction = (hotels: Hotel) => {
    const FetchHotelAction: HotelAction = {
        type: HotelActionTypes.FETCH_HOTEL,
        payload: hotels
    }

    return FetchHotelAction;
}


export const hotelNotFoundAction = () => {
    const HotelNotFoundAction: HotelAction = {
        type: HotelActionTypes.HOTEL_NOT_FOUND,
        payload: {}
    }

    return HotelNotFoundAction;
}


export const hotelLoadingErrorAction = () => {
    const HotelLoadingErrorAction: HotelAction = {
        type: HotelActionTypes.HOTEL_LOADING_ERROR,
        payload: {}
    }

    return HotelLoadingErrorAction;
}
