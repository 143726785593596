import * as React from "react";
import {ActivityDate} from "../../../models/ExperienceModel";
import moment from "moment";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {getTimeFromDateString} from "../../../helpers/ExperienceHelper";
interface IProps {
    dateClicked: (activityDate: ActivityDate) => void,
    showAnimation: boolean,
};

export default function (props: IProps) {
    const {expState} = useContext(ExperienceContext);
    const {t} = useTranslation();
    const [datesShown, setDatesShown] = useState(5);

    const showMoreEvents = () => {
        setDatesShown(datesShown + 5);
    };

    const getTimePeriod = (activityDate: ActivityDate) => {
        const openEnd = activityDate.endDate.startsWith('0001');
        const startTime = activityDate.startDate ? getTimeFromDateString(activityDate.startDate) : '';
        const endTime = openEnd ? '' : (' - ' + getTimeFromDateString(activityDate.endDate));
        return startTime + endTime;
    };

    const registrationDeadlinePassed = (activityDate: ActivityDate) => {
        if(
            (!expState.experience.registrationDeadlineTime && !expState.experience.registrationDeadlineDay)
            || expState.experience.registrationDeadlineTime == null) {
            return false;
        }
        let registrationDeadLine = moment(activityDate.startDate).subtract(expState.experience.registrationDeadlineDay, 'days')
        let timeStr = String(expState.experience.registrationDeadlineTime?.slice(0,2) + ':' + String(expState.experience.registrationDeadlineTime?.slice(2,4)));

        let date = moment(registrationDeadLine);
        let time = moment(timeStr, 'HH:mm');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute')
        });
        let diffInMinutes = date.diff(new Date(), 'minutes');
        if(!!diffInMinutes) {
            return diffInMinutes > 0 ? false : true
        }
        return false;
    };

    const registrationWindowNotPassed = (activityDate: ActivityDate) => {
        if(
            !expState.hotel.bookingWindowEnabled
            || (!expState.experience.registrationWindowTime && !expState.experience.registrationWindowDay)
            || expState.experience.registrationWindowTime == null
            || expState.experience.registrationWindowDay === -1
        ) {
            return false;
        }

        let registrationWindow = moment(activityDate.startDate).subtract(expState.experience.registrationWindowDay, 'days')
        let timeStr = String(expState.experience.registrationWindowTime?.slice(0,2) + ':' + String(expState.experience.registrationWindowTime?.slice(2,4)));

        let date = moment(registrationWindow);
        let time = moment(timeStr, 'HH:mm');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute')
        });

        let diffInMinutes = moment().diff(date, 'minutes');
        return diffInMinutes < 0;
    };

    const onDateClicked = (activityDate: ActivityDate) => {
        if (!registrationDeadlinePassed(activityDate) && !registrationWindowNotPassed(activityDate)) {
            props.dateClicked(activityDate)
        }
    };

    const showAnimation = (activityDate: ActivityDate) => {
        return props.showAnimation && !registrationDeadlinePassed(activityDate) && !registrationWindowNotPassed(activityDate);
    };

    const getTextForSelectItem = (item: number) => {
        if (item === 0) {
            return t('at the experience day');
        } else if (item === 1) {
            return t('the day before the experience day');
        } else {
            return item + ' ' + t('days before the experience');
        }
    }

    const formatRegistrationWindowTime = () => {
        if(!expState.experience.registrationWindowTime) {
            return;
        }
        return (expState.experience.registrationWindowTime as string).substring(0, 4).replace(/^(\d{2})/, '$1:');
    };

    return (
        <div className="experience-booking">
            <div className="booking-dates">
                <div className="booking-dates__header">
                    <h3>{t('availabilities')}</h3>
                    <div className={'line'}></div>
                    {expState.experience.registrationRequired ? <p>{t('choose date')}</p> : <p>{t('select.a.date')}</p> }
                </div>

                <div className="booking-dates__content">
                    {
                        expState.experience.activityDates
                            .filter(x => moment(x.startDate) > moment())
                            .map((activityDate, index) => {
                            if (datesShown < index + 1) return;
                            const registrationWindowNotPassed_ = registrationWindowNotPassed(activityDate);
                            const registrationDeadlinePassed_ = registrationDeadlinePassed(activityDate);
                            return (
                                (expState.experience.registrationRequired && (activityDate.bookingsNumber < expState.experience.maxParticipant || expState.experience.maxParticipant <= 0)) ?
                                    <div key={`activity-date-${index}`} className={`booking-date available-seats ${registrationWindowNotPassed_ ? 'window-disabled' : ''}`}
                                         onClick={() => onDateClicked(activityDate)}>
                                        <div
                                            className={`${showAnimation(activityDate) ? 'animation-booking-dates' : 'd-none'}`}
                                            style={{
                                                borderColor: expState.buttonColor ? `var(--link-color)` : '',
                                            }}
                                        ></div>
                                        <div className="booking-date__box">
                                            <div className="booking-date__box--date">
                                                {moment(new Date(activityDate.startDate)).utc().format('ddd MMM D')}
                                            </div>

                                            <div className="booking-date__box--hour">
                                                {getTimePeriod(activityDate)}
                                            </div>

                                            {
                                                registrationDeadlinePassed_ &&
                                                    <span className="booking-date__date booking-date__book--fully">{t('registration.deadline.passed')}</span>
                                            }
                                            {
                                                expState.experience.maxParticipant > 0
                                                && activityDate.bookingsNumber > 0
                                                && !registrationDeadlinePassed_
                                                && <span className="participants-number">{activityDate.bookingsNumber}/{expState.experience.maxParticipant}</span>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div key={`activity-date-booked-${index}`} className='booking-date'>
                                        <div className="booking-date__box" style={{cursor: 'default'}} >
                                            <div className='booking-date__date booking-date__date--fully'>{moment(new Date(activityDate.startDate)).format('ddd, MMM D')}</div>
                                            <div 
                                                className={`booking-date__hour booking-date__hour--fully`}
                                                style={expState.experience.registrationRequired ? {} : {color: "white"}}
                                            >{getTimePeriod(activityDate)}</div>
                                            {
                                                expState.experience.registrationRequired &&
                                                <div className='booking-date__date booking-date__book--fully'>{t('out.of.stock')}</div>
                                            }
                                        </div>
                                    </div>
                            )
                        })
                    }
                    {
                        expState.experience.activityDates.length > datesShown &&
                        <div className={'booking-date available-seats'}>
                            <div className="booking-date__show-more-txt" onClick={showMoreEvents}>{t('more dates')}</div>
                        </div>

                    }
                </div>
                {
                    expState.hotel.bookingWindowEnabled &&
                    expState.experience.registrationRequired
                    && !((!expState.experience.registrationWindowTime && !expState.experience.registrationWindowDay)
                        || expState.experience.registrationWindowTime == null)
                    && expState.experience.registrationWindowDay !== -1 &&
                    <p className="dates-info-text">{t('registration.window', { day: getTextForSelectItem(expState.experience.registrationWindowDay as number), time: formatRegistrationWindowTime() })}</p>
                }
            </div>
        </div>
    );
};
