import * as React from "react";
import {useEffect, useState} from "react";
import {match} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import "../../assets/styles/home-page.css"
import "../../assets/styles/category-experiences.css"
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {getLocale} from "../../store/app/selectors";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import Loader from "../../components/common/Loader";
import '../../assets/css/v2/overview-page.css';
import MasonryGrid from "components/common/MasonryGrid";
import Header from "components/common/Header";
import {fetchExperienceAction} from "store/actions/ExperienceActions/ExperienceActionCreators";
import {ExperienceCategory, ExperienceModel} from "models/ExperienceModel";
import moment from "moment/moment";
import {
    fetchCategoryExperiences, fetchCategorySimilars
} from "services/PortalService";
import {
    PortalCategory,
    Breadcrumbs as BreadcrumbsModel,
} from "models/portal/Portal";
import {
    fetchCategoryAction,
    putBreadcrumbAction
} from "store/actions/PortalActions/PortalActions";
import {fetchCategoryService} from "services/CategoryService";
import Breadcrumbs from "components/common/Breadcrumbs";
import LoaderV2 from "components/common/LoaderV2";
import FilterCarousel from "components/portal/FilterCarousel";
import TranslationUtils from "helpers/TranslationUtils";

interface IProps {
    category: PortalCategory,
    breadcrumb: BreadcrumbsModel,
    match: match
};

function Destination(props: IProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const hasRegionOrDestination = (props.match.params as any).region || (props.match.params as any).destination;
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false,
        page: 1,
        limit: 50,
        hasMore: true,
        loadMoreLoading: false
    });

    useEffect(() => {
        dispatch(fetchExperienceAction({} as ExperienceModel));
    }, []);

    useEffect(() => {
        moment.locale(locale);

        const loadEntity = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });

            const [categoryDetails, categoryExperiencesResult, similars]: any = await Promise.all([
                fetchCategoryService((props.match.params as any).category, locale),
                fetchCategoryExperiences((props.match.params as any).category, locale, state.page, state.limit, (props.match.params as any).region, (props.match.params as any).destination),
                fetchCategorySimilars((props.match.params as any).category, locale),
            ]);

            if (categoryDetails === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (categoryDetails === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {

                categoryDetails.experiences = categoryExperiencesResult.results;
                categoryDetails.similars = similars;
                dispatch(fetchCategoryAction(categoryDetails));
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        // if (!state.loading && Object.keys(props.category ?? {}).length == 0 && __BROWSER__ && (props.match.params as any).category !== props.category.translation.urlName) {
        //     loadEntity();
        // }

        loadEntity();
    }, [(props.match.params as any).category]);

    useEffect(() => {
        if (props.category) {
            dispatch(putBreadcrumbAction({
                ...props.breadcrumb,
                experience: null,
                category: props.category,
                last: 'category'
            }));
        }
    }, [props.category]);

    useEffect(() => {
        const handleScroll = () => {
            if (!state.loadMoreLoading && window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) { // added buffer of 10px
                setState(prevState => ({
                    ...prevState,
                    loadMoreLoading: true,
                }));

                loadMoreExperiences(props.category.experiences || []);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state.loadMoreLoading, props.category, props.breadcrumb]);

    const loadMoreExperiences = async (existingExperiences: any[]) => {
        const nextPage = state.page + 1;
        const regionExperiencesResult: any = await fetchCategoryExperiences(
            (props.match.params as any).category,
            locale,
            nextPage,
            state.limit,
            (props.match.params as any).region,
            (props.match.params as any).destination
        );

        if (regionExperiencesResult.results.length === 0) {
            setState(prevState => ({
                ...prevState,
                loadMoreLoading: false,
                hasMore: false
            }));
            return;
        }

        const combinedExperiences = [...existingExperiences, ...regionExperiencesResult.results];
        const updatedExperiencesState: any = {
            ...props.category,
            experiences: combinedExperiences
        };

        dispatch(fetchCategoryAction(updatedExperiencesState));

        setState(prevState => ({
            ...prevState,
            loadMoreLoading: false,
            page: nextPage
        }));
    };

    const formatUrlText = (urlString: string) => {
        // Replace hyphens with spaces
        let convertedString = urlString.replace(/-/g, ' ');

        // Convert sequences to umlauts
        convertedString = convertedString.replace(/ue/g, 'ü')
            .replace(/oe/g, 'ö')
            .replace(/ae/g, 'ä');

        // Capitalize the first letter of each word
        convertedString = convertedString.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');

        return convertedString;
    }

    if ((Object.keys(props.category).length == 0 || state.loading) && !state.notFound) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Data</h1>
            </div>
        )
    }

    return (
        <>
            <div style={{display: 'block'}} className={'overview-page'}>
                { state.loadMoreLoading && <LoaderV2 /> }
                <Helmet>
                    <title>{props.category.translation.name}</title>
                    <meta name="description" content={props.category.translation.categoryDetailsMetaDescription} />
                    <meta property="og:description" content={props.category.translation.categoryDetailsMetaDescription}/>
                </Helmet>

                <Header regionParam={(props.match.params as any).region} destinationParam={(props.match.params as any).destination}/>

                <Breadcrumbs/>

                <br/>

                {
                    ((props.match.params as any).destination || (props.match.params as any).region) &&
                    <h1 className={'overview-page__title'}>{props.category.translation.name} {locale != 'de' ? 'in' : ' - '} {formatUrlText((props.match.params as any).destination ?? (props.match.params as any).region)}</h1>
                }

                {
                    !((props.match.params as any).destination || (props.match.params as any).region) &&
                    <h1 className={'overview-page__title'}>{props.category.translation.name}</h1>
                }
                <p className={'overview-page__subtitle'}>Amazing experiences in {props.category.translation.name} for your {new Date().getFullYear()} holiday</p>

                <br/>
                <br/>
                {
                    props.category.similars?.length &&
                    <FilterCarousel
                        type={'category'}
                        regionParam={(props.match.params as any).region}
                        destinationParam={(props.match.params as any).destination}
                        filters={props.category.similars.map(x =>  {
                        return {
                            label: x.translation.name, url: x.translation.urlName
                        }
                    })}></FilterCarousel>
                }
                {
                    !state.loading && <MasonryGrid experiences={props.category.experiences} />
                }
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        category: state.category,
        breadcrumb: state.breadcrumb ?? {} as BreadcrumbsModel,
        test: 'zahir'
    }
}

export default connect(mapStateToProps)(Destination);
