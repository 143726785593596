import * as React from 'react';
import '../../assets/css/v2/loader-v2.css';
import {useTranslation} from "react-i18next";

export default function () {
    const { t } = useTranslation();

    return (
    <div className={'loader-wrapper'}>
        <img src={'https://s3.eu-central-1.amazonaws.com/giggle.storage/giggle-loader-v2.png'} className={'loader-wrapper__img'} alt=""/>
        <p className={'loader-wrapper__info'}>{t('Loading more experiences...')}</p>
    </div>
    );
};
