import * as React from "react";
import {useContext, useState} from "react";
import ExperienceContext from "ExperienceContext";
import ActivityExperienceDetailsTickets from "components/experience-page/activity/ActivityExperienceDetailsTickets";
import ArrowGray from "../../../assets/icons/left-arrow-gray.svg";
import ArrowRight from "../../../assets/icons/arrow-e-black.svg";
import ExperienceTickets from "components/experience-page/shared/ExperienceTickets";
import {
    getRequestedTicketsTotalPrice,
    getTotalTicketsRequested, isRegistrationFormValid,
    showBottomBar
} from "helpers/ExperienceHelper";
import getSymbolFromCurrency from "currency-symbol-map";
import {useTranslation} from "react-i18next";
import ExperienceBookingDetailsForm from "components/experience-page/shared/ExperienceBookingDetailsForm";
import ExperienceBookingConfirmation from "components/experience-page/shared/ExperienceBookingConfirmation";
import {ExperienceInitiateCheckout, ExperiencePurchase} from "services/GigglyticsService";
import moment from "moment";
import {addBookingService, fetchBookingAvailability} from "services/BookingService";
import {useSelector} from "react-redux";
import {getLocale} from "store/app/selectors";
import {ActivityDate, BookingFormSettings} from "models/ExperienceModel";


interface IProps {

};

const ActivityExpereinceV2 = (props: IProps) => {
    const {expState, setExpState} = useContext(ExperienceContext);
    const {t} = useTranslation();
    const locale = useSelector(getLocale);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const bookingFormSettingsInit : BookingFormSettings = {
        name: { show: true, required: true},
        phone: { show: true, required: false},
        email: { show: true, required: false},
        comment: { show: true, required: false},
        hotelName: { show: false, required: false},
        customField: { show: false, required: false, label: {}},
    }
    const [bookingFormSettings, setBookingFormSettings] = useState(expState.hotel.bookingFormSettings ? (JSON.parse(expState.hotel.bookingFormSettings) as BookingFormSettings ) : bookingFormSettingsInit);

    const goNextStep = () => {
        setLoading(true);
        bookExperience();
    };

    const getRegistrationDeadline = (activityDate: ActivityDate, bookingLang) => {
        if(
            (!expState.experience.registrationDeadlineTime && !expState.experience.registrationDeadlineDay)
            || expState.experience.registrationDeadlineTime == null || expState.experience.registrationDeadlineDay < 0) {
            return null;
        }
        let registrationDeadLine = moment(activityDate.startDate).subtract(expState.experience.registrationDeadlineDay, 'days')
        let timeStr = String(expState.experience.registrationDeadlineTime?.slice(0,2) + ':' + String(expState.experience.registrationDeadlineTime?.slice(2,4)));

        let date = moment(registrationDeadLine);
        let time = moment(timeStr, 'HH:mm');
        date.set({
            hour: time.get('hour'),
            minute: time.get('minute')
        });

        if (bookingLang === 'de')
            return date.format('dddd, D. MMMM, HH:mm');
        else
            return date.format('dddd Do [of] MMMM [at] HH:mm');
    };

    const bookExperience = async (paymentPayload: any = null) => {
        const activityDate = expState.activityBooking.activityDate;
        if (!activityDate) {
            return;
        }
        const tickets: any = expState.tickets.map(x => {
            return {
                ticketId: x.id,
                price: x.price,
                amount: x.amountToBook
            }
        });
        let openEnd = false;
        if (activityDate.endDate.startsWith('0001')) {
            openEnd = true;
        };

        let comment = expState.bookingForm.comment;
        // If it's Innsbruck circle, attach hotelname
        if (expState.bookingForm.hotelName) {
            comment = expState.bookingForm.comment + ` [Hotelname: ${expState.bookingForm.hotelName}]`;
        }
        const hasRegDeadline = !((!expState.experience.registrationDeadlineTime && !expState.experience.registrationDeadlineDay) || expState.experience.registrationDeadlineTime == null);
        const dontConfirmActiviesMinpcp =
            expState.hotel.dontConfirmActiviesMinpcp
            && Number(expState.experience.minParticipant) > 1
            && activityDate.bookingsNumber + getTotalTicketsRequested(expState.tickets) < Number(expState.experience.minParticipant)
            && !expState.hotel.stripeEnabled;
        // debugger;

        let data = {
            paymentId: paymentPayload != null ? paymentPayload?.paymentIntent.id : null,
            name: expState.bookingForm.name,
            phone: expState.bookingForm.phone,
            email: expState.bookingForm.email,
            customField: expState.bookingForm.customField,
            comment: comment,
            hotelName: null,
            bookingType: expState.experience.type,
            date: moment(new Date(activityDate.startDate)).format('yyyyMMDD'),
            experienceId: expState.experience.id,
            hotelCode: expState.hotel.code,
            registrationDeadline: getRegistrationDeadline(activityDate, locale),
            dontConfirmActiviesMinpcp: dontConfirmActiviesMinpcp && hasRegDeadline,
            language: locale,
            tickets,
            startTime: activityDate.startDate.substr(11, 8).replace(/:/g, ''),
            endTime: openEnd ? 0 : activityDate.endDate.substr(11, 8).replace(/:/g, '')
        };

        if (expState.experience.type == 'ACTIVITY' && expState.experience.maxParticipant && expState.experience.maxParticipant > 0) {
            let bookingAvailableObj: any = await fetchBookingAvailability(tickets, data.experienceId, data.date, data.startTime);
            if (!bookingAvailableObj.bookingAvailable) {
                setLoading(false);

                setExpState({
                    ...expState,
                    activityStep: expState.activityStep + 1
                })

                return;
            }
        }

        if (paymentPayload) {
            data = {...data, ...paymentPayload};
        }
        let response = await addBookingService(data);
        const booking = response.data;
        let ticketsCount  = 0;
        let totalPrice_ = 0;

        tickets.forEach((ticket: any) => {
            ticketsCount += ticket.amountToBook;
            totalPrice_ += ticket.price;
        });
        ExperiencePurchase(expState.experience.id as string, ticketsCount, totalPrice_);

        setLoading(false);

        setExpState({
            ...expState,
            activityStep: expState.activityStep + 1
        })
    };

    const onSubmit = () => setIsSubmitted(isRegistrationFormValid(expState.bookingForm, bookingFormSettings));

    const isMobile = () => {
        if(window.innerWidth > 992 ) return false;

        const userAgent = window.navigator.userAgent;
        const mobileRegex = /(Android|iPhone|iPod)/i;
        const iPadRegex = /iPad|Macintosh/i;
        const isTouchDevice = navigator.maxTouchPoints > 0;

        // Check for mobile devices and iPads
        return mobileRegex.test(userAgent) || (iPadRegex.test(userAgent) && isTouchDevice);
    };

    return (
        <div className={'experience-content__exp'} id="parent-experience-id">
            {/* Start top navigation */}
                {
                    ((expState.activityStep === 2 || expState.activityStep === 3) && !isMobile()) &&
                    <div className={'experience-top__action'}>
                        <a className={'back-link'}
                           href={void(0)}
                           onClick={() => setExpState({...expState, activityStep: expState.activityStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>

                            <span>{t('close.experience')}</span>
                        </a>
                    </div>
                }
            {/* End top navigation */}

            {/* Start Mobile */}
                {
                    (expState.activityStep === 2 && isMobile())  &&
                    <div className={'fullscreen-step'}>
                        <div className={'experience-top__action'}>
                            <a className={'back-link'}
                               href={void(0)}
                               onClick={() => setExpState({...expState, activityStep: expState.activityStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>
                                <span>{t('close.experience')}</span>
                            </a>
                        </div>

                        <ExperienceTickets experienceType={'ACTIVITY'} />

                        {
                            showBottomBar(expState) &&
                            <div className={'bottom-bar bottom-bar--active'}>
                                <div className="flex fl-direction-row fl-justify-start fl-align-c">
                                    <div className={expState.experience.imageUrl ? 'bottom-bar__profile-picture' : 'd-none'}>
                                        <img src={expState.experience.imageUrl} alt=""/>
                                    </div>

                                    <div className="flex fl-direction-co fl-align-s">
                                        <div className='bottom-bar__item mrr-20px flex fl-direction-ro fl-align-c'>
                                            <div className='bottom-bar__item-name mrr-5px'
                                                 style={{
                                                     color: `var(--link-color)`,
                                                 }}
                                            >{getTotalTicketsRequested(expState.tickets)}</div>

                                            <div className='bottom-bar__item-name'
                                                 style={{
                                                     color: `var(--link-color)`,
                                                 }}>
                                                {getTotalTicketsRequested(expState.tickets) > 1 ? t('tickets.selected') : t('ticket.selected')}
                                            </div>
                                        </div>

                                        <div className="flex fl-direction-ro fl-align-c">
                                            <div className='bottom-bar__item-value mrr-5px'>
                                                <span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>
                                                {getRequestedTicketsTotalPrice(expState.tickets).toFixed(2)}
                                            </div>

                                            <div className='bottom-bar__item-value'>{t('total')}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'bottom-bar__btn'}
                                    style={{
                                        background: expState.buttonColor ? `var(--link-color)` : '',
                                        color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                                    }}
                                     onClick={() => setExpState({...expState, activityStep: expState.activityStep + 1})}>
                                    <span className='bottom-bar__btn-span'>{t('next')}</span>
                                    <div className="material-icons mrl-10px">arrow_forward</div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {
                    (expState.activityStep === 3 && isMobile()) &&
                    <div className={'fullscreen-step'} >
                        <div className={'experience-top__action'}>
                            <a className={'back-link'}
                               href={void(0)}
                               onClick={() => setExpState({...expState, activityStep: expState.activityStep - 1})}>
                                <span className={'icon'}>
                                    <img src={ArrowGray} alt=""/>
                                </span>
                                <span>{t('close.experience')}</span>
                            </a>
                        </div>

                        <ExperienceBookingDetailsForm isSubmitted={isSubmitted} loading={loading} goNextStep={goNextStep} />

                        {/*<div className={'bottom-actions'} onClick={onSubmit}>*/}
                        {/*    <a href={void(0)} onClick={onSubmit}>*/}
                        {/*        <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>*/}

                        {/*        <span className={'icon'} onClick={onSubmit}>*/}
                        {/*            <img onClick={onSubmit} src={ArrowRight} alt=""/>*/}
                        {/*        </span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        <div className={`bottom-actions ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) &&  'inactive' }`}
                             onClick={onSubmit}>
                            <a
                                href={void(0)}
                                style={{
                                    background: expState.buttonColor ? `var(--link-color)` : '',
                                }}>
                                <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>

                                <div className="material-icons">arrow_forward</div>
                            </a>
                        </div>
                    </div>
                }

                {
                    (expState.activityStep === 4 && isMobile()) &&
                    <div className={'fullscreen-step'}>
                        <ExperienceBookingConfirmation experience={expState.experience} />
                    </div>
                }
            {/* End Mobile */}


            {/* Start Desktop */}
                {
                    expState.activityStep === 1 &&
                    <ActivityExperienceDetailsTickets/>
                }

                {
                    (expState.activityStep === 2 && !isMobile())  &&
                    <ExperienceTickets experienceType={'ACTIVITY'} />
                }

                {
                    (expState.activityStep === 3 && !isMobile()) &&
                    <ExperienceBookingDetailsForm isSubmitted={isSubmitted} loading={loading} goNextStep={goNextStep} />
                }

                {
                    (expState.activityStep === 4 && !isMobile()) &&
                    <ExperienceBookingConfirmation experience={expState.experience} />
                }

                {/* Start bottom navigation */}
                    {
                        (showBottomBar(expState) && expState.activityStep === 2 && !isMobile()) &&
                        <div className={'bottom-bar bottom-bar--active'}>
                            <div className="flex fl-direction-row fl-justify-start fl-align-c">
                                <div className={expState.experience.imageUrl ? 'bottom-bar__profile-picture' : 'd-none'}>
                                    <img src={expState.experience.imageUrl} alt=""/>
                                </div>

                                <div className="flex fl-direction-co fl-align-s">
                                    <div className='bottom-bar__item mrr-20px flex fl-direction-ro fl-align-c'>
                                        <div className='bottom-bar__item-name mrr-5px'
                                             style={{
                                                 color: `var(--link-color)`,
                                             }}
                                        >{getTotalTicketsRequested(expState.tickets)}</div>

                                        <div className='bottom-bar__item-name'
                                             style={{
                                                 color: `var(--link-color)`,
                                             }}>
                                            {getTotalTicketsRequested(expState.tickets) > 1 ? t('tickets.selected') : t('ticket.selected')}
                                        </div>
                                    </div>

                                    <div className="flex fl-direction-ro fl-align-c">
                                        <div className='bottom-bar__item-value mrr-5px'>
                                            <span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>
                                            {getRequestedTicketsTotalPrice(expState.tickets).toFixed(2)}
                                        </div>

                                        <div className='bottom-bar__item-value'>{t('total')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={'bottom-bar__btn'}
                                style={{
                                    background: expState.buttonColor ? `var(--link-color)` : '',
                                    color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                                }}
                                 onClick={() => setExpState({...expState, activityStep: expState.activityStep + 1})}>
                                <span className='bottom-bar__btn-span'>{t('next')}</span>
                                <div className="material-icons mrl-10px">arrow_forward</div>
                            </div>
                        </div>
                    }

                    {
                        (expState.activityStep === 3) &&
                        <div className={'bottom-navigation'}>
                            <span className={'info'}>{expState.experience.type == "ACTIVITY" ? t('finish.your.registration') : t('finish.your.request')}</span>

                            <a href={void(0)}
                               style={{
                                   background: expState.buttonColor ? `var(--link-color)` : '',
                                   color: expState.hotel.fontColor ? `var(--link-font-color)` : '',
                               }}
                               className={`bottom-navigation__link ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) && 'bottom-navigation__link-inactive'}`}
                               onClick={onSubmit}>

                                <span>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>

                                <div className="material-icons">arrow_forward</div>
                            </a>
                        </div>
                    }
                {/* End bottom navigation */}

            {/* End Desktop */}

            {/* <div className={'experience-detail-gradient'}></div> */}
        </div>
    )
}

export default ActivityExpereinceV2;
