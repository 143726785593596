import axios, { AxiosError } from "axios";
import {API} from "../config";

export const fetchCategoryService = async (
    categoryCode: string,
    languageCode: string
) => {
    try {
        const params: any = {
            languageCode,
        };
        // const response = await axios.get(`${API}/api/categories/experiences/${categoryCode}`, {
        const response = await axios.get(`${API}/api/v2/categories/experiences/${categoryCode}`, {
            //api/v2/categories/experiences/active?languageCode=en&page=1&limit=10
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};

export const fetchCategoryDetailsService = async (
    categoryId: string,
    languageCode: string,
) => {
    try {
        const params: any = {
            languageCode,
            fromApp: true
        };
        const response = await axios.get(`${API}/api/category/details/${categoryId}`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};


export const fetchCategoriesXMLService = async (lang: string) => {
    try {
        const params: any = {
            lang,
        };
        const response = await axios.get(`${API}/api/categories/xml`, {
            params,
        });

        return response.data;
    } catch (e) {
        return [];
    }
};

export const getPopularCategories = async () => {
    try {
        const params: any = {
            languageCode: 'en',
            page: 1,
            limit: 4,
        };

        const response = await axios.get(`${API}/api/v2/experiences/popular-experience-categories`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
}

export const getPopularLocations = async () => {
    try {
        const params: any = {
            languageCode: 'en',
            page: 1,
            limit: 4,
        };

        const response = await axios.get(`${API}/api/v2/experiences/popular-experience-locations`, {
            params
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
}
