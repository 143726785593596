import * as React from "react";
import {useEffect, useState} from "react";
import {match} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import "../../assets/styles/home-page.css"
import "../../assets/styles/category-experiences.css"
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {getLocale} from "../../store/app/selectors";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import Loader from "../../components/common/Loader";
import '../../assets/css/v2/overview-page.css';
import MasonryGrid from "components/common/MasonryGrid";
import Header from "components/common/Header";
import {fetchExperienceAction} from "store/actions/ExperienceActions/ExperienceActionCreators";
import {ExperienceModel} from "models/ExperienceModel";
import moment from "moment/moment";
import {
    fetchDestination, fetchDestinationExperiences, fetchDestinationSimilars
} from "services/PortalService";
import {Breadcrumbs as BreadcrumbsModel, RegionDestination, RegionDestinationDetails} from "models/portal/Portal";
import {fetchDestinationAction, putBreadcrumbAction} from "store/actions/PortalActions/PortalActions";
import Breadcrumbs from "components/common/Breadcrumbs";
import LoaderV2 from "components/common/LoaderV2";
import FilterCarousel from "components/portal/FilterCarousel";
import TranslationUtils from "helpers/TranslationUtils";
import {toSeoUrl} from "helpers/GeneralHelper";

interface IProps {
    region: RegionDestination,
    destination: RegionDestination,
    breadcrumb: BreadcrumbsModel,
    match: match
};

function Destination(props: IProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [scrolling, setScrolling] = useState<boolean>(false);
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false,
        page: 1,
        limit: 50,
        hasMore: true,
        loadMoreLoading: false
    });

    useEffect(() => {
        dispatch(fetchExperienceAction({} as ExperienceModel));
    }, []);

    useEffect(() => {
        moment.locale(locale);
        const loadEntity = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });

            const [destinationResult, destinationExperiencesResult, similars]: any = await Promise.all([
                fetchDestination((props.match.params as any).destination),
                fetchDestinationExperiences((props.match.params as any).destination, locale, state.page, state.limit, (props.match.params as any).region),
                fetchDestinationSimilars((props.match.params as any).destination, locale),
            ]);

            if (destinationResult === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (destinationResult === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                (destinationResult as RegionDestination).experiences = destinationExperiencesResult.results;
                (destinationResult as RegionDestination).similars = similars;
                console.log(destinationResult);
                (destinationResult as RegionDestination).detailsObj = JSON.parse(destinationResult?.details ?? '{}')
                dispatch(fetchDestinationAction(destinationResult));
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        // if (!state.loading && Object.keys(props.destination ?? {}).length == 0 && __BROWSER__ && (props.match.params as any).destination !== props.destination.id) {
        //     loadEntity();
        // }

        loadEntity();
    }, [(props.match.params as any).destination]);

    useEffect(() => {
        if (props.destination) {
            // let region = props.region && props.region.id == props.destination.regionId ? props.breadcrumb.region : null;
            // if (props.region)
            dispatch(putBreadcrumbAction({
                ...props.breadcrumb,
                experience: null,
                category: null,
                destination: props.destination,
                last: 'destination'
            }));
        }
    }, [props.destination]);

    // useEffect(() => {
    //     const handleScroll = () =>
    //     {
    //         if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    //             setState({
    //                 ...state,
    //                 loadMoreLoading: true,
    //             });

    //             loadMoreExperiences();
    //         }

    //         if (window.scrollY > 0) {
    //             setScrolling(true);
    //         } else {
    //             setScrolling(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // const loadMoreExperiences = async () => {
    //     const nextPage = state.page + 1;
    //     const destinationExperiencesResult : any = await fetchDestinationExperiences((props.match.params as any).destination, locale, nextPage, state.limit)

    //     if (destinationExperiencesResult.results.length === 0) {
    //         setState({
    //             ...state,
    //             loadMoreLoading: false,
    //             hasMore: false
    //         });
    //         return;
    //     }

    //     const existingExperiences = props.destination.experiences || [];
    //     const combinedExperiences = [...existingExperiences, ...destinationExperiencesResult.results];

    //     const updatedCategoryDetails: any = {
    //         ...props.destination,
    //         experiences: combinedExperiences
    //     };

    //     await dispatch(fetchDestinationAction(updatedCategoryDetails));

    //     setState({
    //         ...state,
    //         loadMoreLoading: false,
    //         page: nextPage
    //     });
    // };

    useEffect(() => {
        const handleScroll = () => {
            if (!state.loadMoreLoading && window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) { // added buffer of 10px
                setState(prevState => ({
                    ...prevState,
                    loadMoreLoading: true,
                }));

                loadMoreExperiences(props.destination.experiences || []);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state.loadMoreLoading, props.region.experiences, props.breadcrumb]);

    const loadMoreExperiences = async (existingExperiences: any[]) => {
        const nextPage = state.page + 1;
        const destinationExperiencesResult: any = await fetchDestinationExperiences((props.match.params as any).destination, locale, nextPage, state.limit, (props.match.params as any).region);

        if (destinationExperiencesResult.results.length === 0) {
            setState(prevState => ({
                ...prevState,
                loadMoreLoading: false,
                hasMore: false
            }));
            return;
        }

        const combinedExperiences = [...existingExperiences, ...destinationExperiencesResult.results];
        const updatedExperiencesState: any = {
            ...props.destination,
            experiences: combinedExperiences
        };

        dispatch(fetchDestinationAction(updatedExperiencesState));

        setState(prevState => ({
            ...prevState,
            loadMoreLoading: false,
            page: nextPage
        }));
    };

    if ((Object.keys(props.destination).length == 0 || state.loading) && !state.notFound) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Data</h1>
            </div>
        )
    }

    const description = TranslationUtils.getTranslatedFieldV2(props.destination.detailsObj?.translations ?? [], locale, 'seoText');

    return (
        <>
            <div style={{display: 'block'}} className={'overview-page'}>
            { state.loadMoreLoading && <LoaderV2 /> }
                <Helmet>
                    <title>{TranslationUtils.getTranslatedFieldV2(props.destination.detailsObj?.translations ?? [], locale, 'titleHead')}</title>
                    <meta name="description" content={TranslationUtils.getTranslatedFieldV2(props.destination.detailsObj?.translations ?? [], locale, 'descriptionHead')} />
                    <meta property="og:description" content={TranslationUtils.getTranslatedFieldV2(props.destination.detailsObj?.translations ?? [], locale, 'descriptionHead')}/>
                    <link rel="canonical" href={`https://giggle.tips/${locale}/experiences/r/${props.destination.parentUrl}/${props.destination.code}`} />
                </Helmet>

                <Header regionParam={(props.match.params as any).region} destinationParam={(props.match.params as any).destination}/>

                <Breadcrumbs/>

                <br/>

                <h1 className={'overview-page__title'}>{props.destination.name}</h1>

                <div className='region-description-container'>
                    {/*<p className={'overview-page__subtitle-v2'} dangerouslySetInnerHTML={{ __html: readMoreDescription ? description.substring(0, 135) + '...' : description }} ></p>*/}
                    <p className={'overview-page__subtitle-v2'} dangerouslySetInnerHTML={{ __html: description }}></p>
                    {/*{*/}
                    {/*    description.length > 135 &&*/}
                    {/*    <div className={'more-btn-wrapper'}>*/}
                    {/*        <button onClick={() => setReadMoreDescription(!readMoreDescription)} className={`${!readMoreDescription ? 'less' : ''}`}>*/}
                    {/*            { readMoreDescription ? t('read.more') : t('read.less')}*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <br/>
                <br/>
                {
                    props.destination.similars.length &&
                    <FilterCarousel
                        type={'destination'}
                        regionParam={(props.match.params as any).region}
                        destinationParam={(props.match.params as any).destination}
                        filters={props.destination.similars.filter(x => x.name != props.destination.name && x.totalCount > 3).map(x =>  {
                        return {
                            label: x.name, url: x.code
                        }
                    })}></FilterCarousel>
                }
                {
                    !state.loading && <MasonryGrid experiences={props.destination.experiences} />
                }
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        region: state.region,
        destination: state.destination,
        breadcrumb: state.breadcrumb ?? {} as BreadcrumbsModel,
    }
}

export default connect(mapStateToProps)(Destination);
