import axios, {AxiosError} from 'axios';
import { API } from 'config';


export const addBookingService = (payload: any) => {
    return axios.post(`${API}/app/bookings`, payload);
};

export const payPreBookingService = (payload: any) => {
    return axios.post(`${API}/app/bookings/payment`, payload);
}


export const createPaymentIntent = (payload: any) => {
    return axios.post(`${API}/app/create-payment-intent`, payload);
}

export const fetchBookingAvailability = async (payload: any, experienceId: string, date: string, time: string) => {
    try {
        const response = await axios.put(`${API}/api/v2/experiences/booking/available`, payload, {
            params: {
                experienceId,
                date,
                time,
            }
        });

        return response.data;
    } catch (e) {
        let error: AxiosError = e as AxiosError;
        if (error.isAxiosError && error.response) {
            return error.response.status;
        } else {
            return 500;
        }
    }
};
