import { combineReducers } from 'redux';
import app from './app/reducer';
import hotel from "./reducers/hotel";
import experience from "./reducers/experience";
import guide from "./reducers/guide";
import host from "./reducers/host";
import region from "./reducers/region";
import destination from "./reducers/destination";
import search from "./reducers/search";
import category from "./reducers/category";
import breadcrumb from "store/reducers/breadcrumb";

const createRootReducer = () =>
    combineReducers({
        app,
        hotel,
        experience,
        guide,
        region,
        destination,
        host,
        search,
        category,
        breadcrumb
    });

export default createRootReducer;
