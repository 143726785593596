import * as React from 'react';
import '../../assets/css/v2/masonry-card.css';
import '../../assets/css/v2/masonry-categories-card.css';
import {useTranslation} from "react-i18next";

interface IMasonryCategoriesCardProps {
    experience?: any,
};

const MasonryCategoriesCard = (props: IMasonryCategoriesCardProps) => {
    const { t } = useTranslation();

    return (
        <div className={'masonry-card'}>
            <div className={'masonry-categories'}>
                <h2 className={'masonry-categories__title'}>{props.experience.categoryTitle}</h2>

                <div className={'masonry-categories__list'}>
                    {
                        props.experience.categories.map((item: any , index: number) => {
                            return (
                                <div className={'masonry-categories__list__item'} key={index}
                                     style={item.image ? {backgroundImage: `url('${item.image}')`} : {backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                                    <span className={'name'}>{item.name}</span>
                                    <span className={'count'}>{item.count} {t('Experiences')}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    );
}

export default MasonryCategoriesCard;
