import * as React from "react";

import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useContext} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {generateDateRangeSkipDays} from "../../../helpers/ExperienceHelper";
import {getLocale} from "../../../store/app/selectors";
import {useSelector} from "react-redux"; // theme css file
import * as rdrLocales from 'react-date-range/dist/locale';
import GiggleCalendar from "components/GiggleCalendar/GiggleCalendar";
import ResourceGiggleCalendar from "components/GiggleCalendar/ResourceGiggleCalendar";

interface IProps {
    goNextStep?: () => void
    goPrevStep?: () => void;
    times?: number[]
};

const getCalendarLocale = (language: string) => {
    switch (language) {
        case 'it':
            return rdrLocales.it;
        case 'de':
            return rdrLocales.de;
        default:
            return rdrLocales.enUS;
    }
};

export default function (props: IProps) {
    const locale = useSelector(getLocale);
    const {expState, setExpState} = useContext(ExperienceContext);
    const { t } = useTranslation();

    const onDateChanged = (date: Date) => {
        setExpState({
            ...expState,
            resourceBooking: {
                ...expState.resourceBooking,
                resourceDate: moment(date)
            }
        })
    };

    const getDisabledDates = () => {
        if (!expState.experience.resourceEvents) { return []; }
        if (!expState.experience.resourceEvents.length) { return []; }

        const resourceEvent: any = expState.experience.resourceEvents[0];

        let splittedItems = resourceEvent.rrule?.split(";")

        const disabledDates: any = {
            disabledDates: expState.experience.disabledDates,
            disabledStartDate: expState.experience.disabledStartDate,
            disabledEndDate: expState.experience.disabledEndDate,
        };

        for (let i in splittedItems) {
            const splittedItem = splittedItems[i];
            if (splittedItem.includes("BYDAY")) {
                return generateDateRangeSkipDays(moment(), moment().add(720, 'd'), splittedItem, disabledDates, expState.experience, expState.experience.pauseDates);
            }
        }

        return generateDateRangeSkipDays(moment(), moment().subtract(720, 'd'), '', disabledDates, expState.experience, expState.experience.pauseDates);
    };

    const goNextStep = () => {
        if (expState.resourceBooking.resourceDate) {
            // props.goNextStep();
            setExpState({
                ...expState,
                resourceStep: expState.resourceStep + 1
            })
        }
    };

    const onDateClicked = () => {

    };

    return (
        <div className="booking-calendar fade-v2">
            <div className="booking-calendar__header">
                <h3>{t('preferred date')}</h3>
                <div className="line"></div>
                <div className="info">
                    {t('preferred.date.and.time')}
                </div>
            </div>

            <ResourceGiggleCalendar
                times={props.times}
                dateClicked={onDateClicked}
                disabledDates={getDisabledDates()}
                goNextStep={goNextStep}
            />
     </div>
    );
};
