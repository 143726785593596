import  React, { useEffect } from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import '../assets/css/v2/overview-page.css'
import Header from "components/common/Header";
import '../assets/css/v2/homepage.css';
import RightArrow from '../assets/icons/homepage/arrow-e.svg';
import LineDesktop1 from '../assets/icons/homepage/arrows/giggle-lines-01-desktop.svg';
import LineDesktop2 from '../assets/icons/homepage/arrows/giggle-lines-02-desktop.svg';
import LineMobile1 from '../assets/icons/homepage/arrows/giggle-lines-03-mobile.svg';
import LineMobile2 from '../assets/icons/homepage/arrows/giggle-lines-04-mobile.svg';
import GiggleLogo from "../assets/giggle-logo-rgb-lumi.svg";
import { Helmet } from "react-helmet-async";
import {Link} from "react-router-dom";
import { getLocale } from "store/app/selectors";
import { useTranslation } from "react-i18next";
import { setLocale } from "store/app/actions";
import { is } from "immer/dist/internal";

interface IHomeProps {
    name: 'HomePage',
}

function HomePage(props: IHomeProps) {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    // useEffect(() => {
    //     detectBrowserLanguage();
    // }, [dispatch]);

    // const detectBrowserLanguage = () => {
    //     const browserLang: string = navigator.language.split('-')[0];

    //     if(!isSupportedLanguage(browserLang)){
    //         i18n.changeLanguage('en');
    //         dispatch(setLocale('en'));
    //         return;
    //     }

    //     if(browserLang){
    //         i18n.changeLanguage(browserLang);
    //         dispatch(setLocale(browserLang as any));
    //     }
    // }

    // const isSupportedLanguage = (langCode: string) => {
    //     return i18n.languages.includes(langCode)
    // }
    
    return (
        <div className={'homepage'} style={{backgroundColor: '#131414'}}>
            <Helmet>
                <title>{`Giggle.tips | Die Multi-Channel-Erlebnisplattform`}</title>
                <meta property="og:title" content={'Giggle.tips | Die Multi-Channel-Erlebnisplattform'}/>
                <meta name="description" content={'Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister.'}/>
                <meta
                    property="og:description"
                    content={'Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister.'}/>
                <meta property="og:type" content="website"/>
                <meta content="https://uploads-ssl.webflow.com/64b7092b3e5ab1466234de83/64f89f93311f233b13e2f14b_og-image%20(3).png" property="og:image"/>
                <meta content="Giggle.tips | Die Multi-Channel-Erlebnisplattform" property="twitter:title"/>
                <meta content="Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister." property="twitter:description"/>
                <meta content="https://uploads-ssl.webflow.com/64b7092b3e5ab1466234de83/64f89f93311f233b13e2f14b_og-image%20(3).png" property="twitter:image"/>
            </Helmet>

            <div className={'top-gradient'}></div>

            <Header />

            <div className={'homepage__top'}>
                <p className={'homepage__top__subtitle'}>{t('people.make.tourism')}</p>
                <h1 className={'homepage__top__title'}>{t('discover.beloved.experiences')}</h1>
                {/* <span className={'homepage__top__tagline'}>Start exploring today and find that one special experience for you.</span> */}

                <Link to={`/${locale}/experiences`} className={'homepage__top__link'}>{t('explore.experiences')}</Link>
                <br/>
                <br/>
                <br/>
            </div>

            <div className={'homepage__hero'} style={{backgroundImage: 
                `${locale === 'de' ? "url('https://s3.eu-central-1.amazonaws.com/giggle.storage/hero_DE.png')" : "url('https://s3.eu-central-1.amazonaws.com/giggle.storage/hero_EN.png')"}`}}>
            {/*   displays the background image */}
            </div>

            <div className={'homepage__blue'}>
                <p className={'homepage__blue__subtitle'}>{t('the.experience.platform.for.hosts')}</p>
                <h3 className={'homepage__blue__title'}>{t('start.experience.journey')}</h3>

                <div className={'homepage__blue__actions'}>
                    <a href="https://hotel.giggle.tips/" className={'homepage__blue__actions__btn-black'}>
                        <span>{t('learn.more')}</span>

                        <span className={'icon'}>
                            <img src={RightArrow} alt=""/>
                        </span>
                    </a>
                    {/*<a href="#" className={'homepage__blue__actions__btn-outline'}>*/}
                    {/*    <span className={'icon'}>*/}
                    {/*        <img src={BluePlay} alt=""/>*/}
                    {/*    </span>*/}
                    {/*    <span>See how it works</span>*/}
                    {/*</a>*/}
                </div>

                <div className={'homepage__blue__boxes'}>
                    <div className={'homepage__blue__boxes__box'}>
                        <div className={'homepage__blue__boxes__box__title'}>{t('create.first.experience')}</div>
                        <img className={'image'} 
                        
                            src={locale === 'de' ? 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_1_DE.png' : 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_1_EN.png'} 
                            alt=""/>
                        <img className={'line mobile left'} src={LineMobile1} />
                        <img className={'line desktop left'} src={LineDesktop1} />
                    </div>
                    <div className={'homepage__blue__boxes__box'}>
                        <div className={'homepage__blue__boxes__box__title'}>{t('share.experience')}</div>
                        <img className={'image'} 
                            src={locale === 'de' ? 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_2_DE.png' : 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_2_EN.png'}  
                            alt=""/>
                        <img className={'line mobile right'} src={LineMobile2} />
                        <img className={'line desktop right'} src={LineDesktop2} />
                    </div>
                    <div className={'homepage__blue__boxes__box'}>
                        <div className={'homepage__blue__boxes__box__title'}>{t('engage.more.customers')}</div>
                        <img className={'image'} 
                            src={locale === 'de' ? 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_3_DE.png' : 'https://s3.eu-central-1.amazonaws.com/giggle.storage/portal_3_EN.png'} 
                            alt=""/>
                    </div>
                </div>
            </div>

            <div className={'homepage__footer'}>
                <div className={'homepage__footer__top'}>
                    <a href="https://hotel.giggle.tips/" className={'link btn-black'}>{t('start.for.free')}</a>
                    <a href={void(0)} className={'link btn-outline'}>{t('30.day.free')}</a>
                </div>

                <div className={'homepage__footer__bottom'}>
                    <div className={'logo'}>
                        <img src={GiggleLogo} alt=""/>
                    </div>

                    <div className={'copyright'}>
                        © Giggle.tips GmbH. All rights reserved.
                    </div>

                    <ul className={'footer-menu'}>
                        <li className={'footer-menu__item'}>
                            <a href="https://hotel.giggle.tips/terms-of-use">Terms of Service</a>
                        </li>
                        <li className={'footer-menu__item'}>
                            <a href="https://hotel.giggle.tips/privacy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel,
    }
}

export default connect(mapStateToProps)(HomePage);
