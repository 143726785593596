import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";
import {RegionDestination} from "models/portal/Portal";


const initialState: RegionDestination = {} as RegionDestination;

export default (state = initialState, action: PortalAction) => {
    switch (action.type) {
        case PortalActionTypes.FETCH_REGION:
            const region = (action.payload as RegionDestination)
            const regionPreloaded = (action.payload as RegionDestination)

            return { ...region, ...regionPreloaded };
        default:
            return state;
    }
}
