import React from "react";
import {ActivityDate, ExperienceModel, Ticket} from "./models/ExperienceModel";
import Hotel from "./models/Hotel";
import {Moment} from "moment";

export interface ExperienceState {
    experience: ExperienceModel,
    hotel: Hotel,
    activityBooking: ActivityBooking,
    resourceBooking: ResourceBooking,
    tickets: Ticket[],
    bookingForm: BookingForm,
    redirectTo: string | null
    redirectHotelCode: string | null
    buttonColor: string | null
    fontColor: string | null
    whiteBg: string | null
    noWindowsEnabled: boolean | null
    activityStep: number
    resourceStep: number
}
export interface ActivityBooking {
    activityDate?: ActivityDate,
    tickets: Ticket[],
    bookingForm: BookingForm,
}
export interface BookingForm {
    name: string,
    phone: string,
    email: string,
    hotelName?: string,
    comment?: string,
    customField?: string,
    acceptedTerms: boolean
}
export interface ResourceBooking {
    resourceDate?: Moment,
    dayTime?: number,
    tickets: Ticket[],
    bookingForm: BookingForm
}
interface ExperienceContext {
    expState: ExperienceState,
    setExpState: (x: ExperienceState) => void,
    closeExperience: () => void,
}
const ExperienceContext = React.createContext({} as ExperienceContext);
export default ExperienceContext;
