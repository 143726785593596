import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useTranslation, Trans} from "react-i18next";
import {useContext} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {
    getResourceTimes,
    getTimeAsDayName,
    getTimeFromDateString,
    isRegistrationFormValid
} from "../../../helpers/ExperienceHelper";
import moment from "moment";
import {BookingFormSettings, Ticket} from "../../../models/ExperienceModel";
import Loader from "../../common/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import {getButtonColor} from "../../../helpers/GeneralHelper";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useSelector} from "react-redux";
import {getLocale} from "../../../store/app/selectors";
import enPhone from "../../../assets/custom-langs/phone/enPhone.json";
import dePhone from "../../../assets/custom-langs/phone/dePhone.json";
import esPhone from "../../../assets/custom-langs/phone/esPhone.json";
import itPhone from "../../../assets/custom-langs/phone/itPhone.json";
import frPhone from "../../../assets/custom-langs/phone/frPhone.json";


interface IProps {
    goNextStep: () => void
    goPrevStep?: () => void
    goToStep?: (name: string) => void,
    isSubmitted: boolean,
    loading: boolean
};

let phoneNumber = '';
export default function (props: IProps) {
    const {expState, setExpState} = useContext(ExperienceContext);
    const {t} = useTranslation();
    const locale = useSelector(getLocale);
    const [acceptedTerms, setAcceptedTerms] = useState(true);
    const [fieldActive, setFieldActive] = useState(0);
    const [form, setForm] = useState({phone: ''});
    const nameInputRef: any = useRef(null);
    const emailInputRef: any = useRef(null);
    const commentInputRef: any = useRef(null);
    const hotelNameInputRef: any = useRef(null);
    const customFieldInputRef: any = useRef(null);

    function getCookie(name: any) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            // @ts-ignore
            return parts.pop().split(';').shift();
        }
    }

    let preFillData = JSON.parse(localStorage.getItem('formData')) || {};
    const bookingFormSettingsInit : BookingFormSettings = {
        name: { show: true, required: true},
        phone: { show: true, required: false},
        email: { show: true, required: false},
        comment: { show: true, required: false},
        hotelName: { show: false, required: false},
        customField: { show: false, required: false, label: {}},
    }
    const [bookingFormSettings, setBookingFormSettings] = useState(expState.hotel.bookingFormSettings ? (JSON.parse(expState.hotel.bookingFormSettings) as BookingFormSettings ) : bookingFormSettingsInit);

    useEffect(() => {
        const cookieConsentStatus = getCookie('cookieconsent_status');

        if (cookieConsentStatus === 'allow') {
            if (preFillData.name && nameInputRef.current) {
                nameInputRef.current.value = preFillData.name;
            }
            if (preFillData.email && emailInputRef.current) {
                emailInputRef.current.value = preFillData.email;
            }
            if (preFillData.comment && commentInputRef.current) {
                commentInputRef.current.value = preFillData.comment;
            }

            let bookingForm = {
                name: nameInputRef.current.value,
                phone: phoneNumber,
                email: emailInputRef.current ? emailInputRef.current.value : null,
                comment: commentInputRef.current ? commentInputRef.current.value : null,
                hotelName: hotelNameInputRef.current ? hotelNameInputRef.current.value : null,
                customField: customFieldInputRef.current ? customFieldInputRef.current.value : null,
                acceptedTerms: true
            };

            setExpState({
                ...expState,
                bookingForm
            });
        }

    }, []);

    useEffect(() => {
        if (props.isSubmitted){
            submitForm();
        }
    }, [props.isSubmitted])

    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(preFillData));
    }, [preFillData]);

    const onChange = () => {
        let bookingForm = {
            name: nameInputRef.current.value,
            phone: phoneNumber,
            email: emailInputRef.current ? emailInputRef.current.value : null,
            comment: commentInputRef.current ? commentInputRef.current.value : null,
            hotelName: hotelNameInputRef.current ? hotelNameInputRef.current.value : null,
            customField: customFieldInputRef.current ? customFieldInputRef.current.value : null,
            acceptedTerms: !acceptedTerms
        };

        setExpState({
            ...expState,
            bookingForm
        });
        setAcceptedTerms(!acceptedTerms)
        // setAcceptedTerms(true)
    };

    const onTextChanged = () => {
        let bookingForm = {
            name: nameInputRef.current.value,
            phone: phoneNumber,
            email: emailInputRef.current ? emailInputRef.current.value : null,
            comment: commentInputRef.current ? commentInputRef.current.value : null,
            hotelName: hotelNameInputRef.current ? hotelNameInputRef.current.value : null,
            customField: customFieldInputRef.current ? customFieldInputRef.current.value : null,
            acceptedTerms: true
        };

        setExpState({
            ...expState,
            bookingForm
        });
    };

    const onPhoneChange = (value: any, data: any, event: any, formattedValue: any) => {
        phoneNumber = formattedValue;
        setForm({phone: formattedValue});
        onTextChanged();
    };

    const getEventInfo = () => {
        if (expState.experience.type == 'ACTIVITY') {
            const activityDate = expState.activityBooking.activityDate;
            if (activityDate) {
                const startDate = moment(new Date(activityDate.startDate));
                let openEnd = false;
                let date =
                    startDate.format('ddd, MMM D') + " | ";

                if (activityDate.endDate.startsWith('0001')) {
                    openEnd = true;
                }

                const startTime = getTimeFromDateString(activityDate.startDate);
                const endTime = openEnd ? '' : (' - ' +  getTimeFromDateString(activityDate.endDate));

                return date + startTime + endTime;
            }
            return '';
        } else {
            let date = (expState.resourceBooking.resourceDate || moment()).format('ddd, MMM D') + " | ";
            let time = t(getTimeAsDayName(expState.resourceBooking.dayTime || -1));
            return date + time;
        }
    };

    let totalPrice = 0;
    const getTicketsInfo = () => {
        let label = '';

        expState.tickets.forEach((ticket: Ticket) => {
            label += ticket.amountToBook + 'x' + ' ';
            label += ticket.translation.name + ', ';
            totalPrice += (ticket.price || 0) * ticket.amountToBook;
        });

        label = label.slice(0, -2);
        return label;
    };

    const onSelectInput = (fieldActive: number) => {
        setFieldActive(fieldActive)
    };

    const goToDates = () => {
       // props.goToStep(expState.experience.type == 'ACTIVITY' ? 'details-tickets' : 'calendar')
        expState.experience.type == 'ACTIVITY' ?
            setExpState({...expState, activityStep: 1}) :
            setExpState({...expState, resourceStep: 2})
    };

    const goToTickets = () => {
       // props.goToStep('details-tickets')
        expState.experience.type == 'ACTIVITY' ?
            setExpState({...expState, activityStep: 2}) :
            setExpState({...expState, resourceStep: 1})
    };

    const hotelTermsClick = () => {
        window.open(`https://giggle.tips/${expState.hotel.code}/exceptional-policies`, '_blank')
    };

    const submitForm = () => {
        setAcceptedTerms(true)
        let bookingForm = {
            name: nameInputRef.current.value,
            phone: phoneNumber,
            email: emailInputRef.current ? emailInputRef.current.value : null,
            comment: commentInputRef.current ? commentInputRef.current.value : null,
            hotelName: hotelNameInputRef.current ? hotelNameInputRef.current.value : null,
            customField: customFieldInputRef.current ? customFieldInputRef.current.value : null,
            acceptedTerms: true
        };

        setExpState({
            ...expState,
            bookingForm
        });


        if (isRegistrationFormValid(expState.bookingForm, bookingFormSettings)) {
            props.goNextStep();
            // setExpState({
            //     ...expState,
            //     activityStep: expState.activityStep + 1
            // })
        }

        let formData = {
            name: nameInputRef.current ? nameInputRef.current.value : null,
            email: emailInputRef.current ? emailInputRef.current.value : null,
            comment: commentInputRef.current ? commentInputRef.current.value : null,
        };
        localStorage.setItem('formData', JSON.stringify(formData));
    };

    const getPhoneLocale = () => {
        switch (locale) {
            case 'de':
                return dePhone;
            case 'en':
                return undefined;
            case 'it':
                return itPhone;
            case 'fr':
                return frPhone;
            case 'es':
                return esPhone;
            default:
                return undefined;
        }
    };

    const getCustomFieldLabel = () => {
        const localeLabel = bookingFormSettings.customField?.label[locale];
        const englishLabel = bookingFormSettings.customField?.label['en'];

        return localeLabel ?  localeLabel : ( englishLabel ?? '-')
    }

    let countryCode = 'at';
    const getCountryCode = (): string => {
        let countryCodeLang = Object.keys(enPhone).find((key: any) => (enPhone as any)[key] === expState.hotel.country);
        if (countryCodeLang) return countryCodeLang.toLowerCase();
        countryCodeLang = Object.keys(dePhone).find((key: any) => (dePhone as any)[key] === expState.hotel.country);
        if (countryCodeLang) return countryCodeLang;
        countryCodeLang = Object.keys(itPhone).find((key: any) => (itPhone as any)[key] === expState.hotel.country);
        if (countryCodeLang) return countryCodeLang;
        countryCodeLang = Object.keys(esPhone).find((key: any) => (esPhone as any)[key] === expState.hotel.country);
        if (countryCodeLang) return countryCodeLang;
        countryCodeLang = Object.keys(frPhone).find((key: any) => (frPhone as any)[key] === expState.hotel.country);
        if (countryCodeLang) return countryCodeLang;
        return 'at';
    };
    let firstAgreeHotel = <a className='pointer' href="https://www.giggle.tips/terms-of-use" target="_blank">Giggle GmbH</a>;
    let secondAgreeHotel = expState.hotel.privacyPolicy  ? <a className='pointer' href={expState.hotel.privacyPolicy} target="_blank">Giggle GmbH</a> : expState.hotel.name;

    return (
        <div className="summary-experience fade-v2">
            {/*<div className="summary-experience__header">*/}
            {/*    <h3>{t('overview')}</h3>*/}
            {/*    <div className={'line'}></div>*/}
            {/*    <div className={'info'}>{t('fill in your credentials to finish your booking request')}</div>*/}
            {/*</div>*/}

            <div className="summary-experience__header">
                <h3>{t('services.and.activities.details')}</h3>
                <div className={'line'}></div>
                <div className={'info'}>{expState.experience.type === "ACTIVITY" ? t('registration.almos.ready') : t('request.almost.ready')}</div>
            </div>

            <div className={'summary-experience__wrapper'}>
                <div>
                    <div className="summary-experience__form">
                        {
                            <div className="summary-experience__form__row flex fl-align-c">
                                {
                                    bookingFormSettings.name.show &&
                                    <div className="summary-experience__form__row--input-wrapper">
                                        <span className="input-label">
                                            {t('name')}
                                            {
                                                !bookingFormSettings.name.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                            }
                                        </span>

                                        <input
                                            ref={nameInputRef}
                                            className={fieldActive == 1 ? 'input__active' : ''}
                                            style={{
                                                borderBottomColor: fieldActive == 1 && expState.buttonColor ? `var(--link-color)` : '',
                                            }}
                                            type="text"
                                            placeholder={t('your name')}
                                            onClick={() => onSelectInput(1)}
                                            onChange={onTextChanged}/>
                                    </div>
                                }
                            </div>
                        }

                        {
                            (bookingFormSettings.phone.show || bookingFormSettings.hotelName.show) &&
                            <div className="summary-experience__form__row flex fl-align-c">
                                {
                                    bookingFormSettings.email.show &&
                                    <div className="summary-experience__form__row--input-wrapper">
                                        <span className="input-label">
                                            {t('phone')}
                                            {
                                                !bookingFormSettings.phone.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                            }
                                        </span>

                                        <PhoneInput
                                            country={getCountryCode()}
                                            value={form.phone}
                                            localization={getPhoneLocale()}
                                            placeholder={t('your mobile phone number')}
                                            searchPlaceholder={t('Suchen')}
                                            masks={{se: '(...) ...-....'}}
                                            enableSearch={true}
                                            onClick={() => onSelectInput(2)}
                                            onFocus={() => onSelectInput(2)}
                                            onChange={onPhoneChange}
                                        />
                                    </div>
                                }
                            </div>
                        }

                        {
                            (bookingFormSettings.email.show || bookingFormSettings.hotelName.show) &&
                            <div className="summary-experience__form__row flex fl-align-c">
                                {
                                    bookingFormSettings.email.show &&
                                    <div className="summary-experience__form__row--input-wrapper">
                                        <span className="input-label">
                                            {t('e-mail')}
                                            {
                                                !bookingFormSettings.email.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                            }
                                        </span>
                                        <input
                                            ref={emailInputRef}
                                            className={fieldActive == 3 ? 'input__active' : ''}
                                            style={{
                                                borderBottomColor: fieldActive == 3 && expState.buttonColor ? `var(--link-color)` : '',
                                            }}
                                            type="text" placeholder={t('your e-mail address')}
                                            onClick={() => onSelectInput(3)}
                                            onChange={onTextChanged}/>
                                    </div>
                                }

                                {
                                    bookingFormSettings.hotelName.show &&
                                    <div className="summary-experience__form__row--input-wrapper">
                                        <span className="input-label">
                                            {t('hotelname')}
                                            {
                                                !bookingFormSettings.hotelName.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                            }
                                        </span>
                                        <input
                                            ref={hotelNameInputRef}
                                            className={fieldActive == 11 ? 'input__active' : ''}
                                            style={{
                                                borderBottomColor: fieldActive == 11 && expState.buttonColor ? `var(--link-color)` : '',
                                            }}
                                            type="text"
                                            placeholder={t('hotelname')}
                                            onClick={() => onSelectInput(11)}
                                            onChange={onTextChanged}/>
                                    </div>
                                }
                            </div>
                        }
                        {
                            (bookingFormSettings.customField && bookingFormSettings.customField.show) &&
                            <div className="summary-experience__form__row">
                                <div className="summary-experience__form__row--input-wrapper">
                                    <span className="input-label">
                                        {getCustomFieldLabel()}
                                        {
                                            !bookingFormSettings.customField.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                        }
                                    </span>
                                    <input
                                        ref={customFieldInputRef}
                                        className={fieldActive == 15 ? 'input__active' : ''}
                                        style={{
                                            borderBottomColor: fieldActive == 15 && expState.buttonColor ? `var(--link-color)` : '',
                                        }}
                                        type="text"
                                        placeholder={getCustomFieldLabel()}
                                        onClick={() => onSelectInput(15)}
                                        onChange={onTextChanged}/>
                                </div>
                            </div>
                        }
                        {
                            bookingFormSettings.comment.show &&
                            <div className="summary-experience__form__row">
                                <div className="summary-experience__form__row--input-wrapper">
                                    <span className="input-label">
                                        {t('comment')}
                                        {
                                            !bookingFormSettings.comment.required && <span className={'optional-label'}>{t('optional.label')}</span>
                                        }
                                    </span>
                                    <input
                                        ref={commentInputRef}
                                        className={fieldActive == 4 ? 'input__active' : ''}
                                        style={{
                                            borderBottomColor: fieldActive == 4 && expState.buttonColor ? `var(--link-color)` : '',
                                        }}
                                        type="text"
                                        maxLength={500}
                                        placeholder={t('anything.to.add')}
                                        onClick={() => onSelectInput(4)}
                                        onChange={onTextChanged}/>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="summary-experience__agreement">
                        {/*<div className='animation-checkbox'*/}
                        {/*    // style={{*/}
                        {/*    //    borderColor: expState.buttonColor ? `var(--link-color)` : '',*/}
                        {/*    // }}*/}
                        {/*/>*/}
                        {/*<label className={`checkbox-container`}>*/}
                        {/*    <input type="checkbox" className="checkbox-input" onChange={onChange} checked={acceptedTerms}/>*/}
                        {/*    <span className="checkmark"*/}
                        {/*        // style={{*/}
                        {/*        //     backgroundColor: expState.buttonColor && acceptedTerms ? `var(--link-color)` : '',*/}
                        {/*        //     borderColor: expState.buttonColor && acceptedTerms ? `var(--link-color)` : '',*/}
                        {/*        // }}*/}
                        {/*    />*/}
                        {/*</label>*/}

                        <p className="agreement__txt">
                            <Trans
                                i18nKey="agree.to.terms"
                                values={{ firstHotel: 'Giggle GmbH', secondHotel: expState.hotel.name}}
                                components={[firstAgreeHotel, secondAgreeHotel]}
                            />
                            {/*<Interpolate i18nKey="agree.to.terms" useDangerouslySetInnerHTML={true} firstHotel={firstAgreeHotel} secondHotel={secondAgreeHotel} />*/}
                            {/*<div dangerouslySetInnerHTML={{ __html: t('agree.to.terms', { firstHotelink: `${<a className='pointer' href="https://www.giggle.tips/terms-of-use" target="_blank">Giggle GmbH</a>}`, secondHotel: `${<span className='pointer' onClick={hotelTermsClick}  style={{textTransform:'capitalize', textDecoration: 'underline'}}>{expState.hotel.name}</span>}` }) }} />*/}
                        </p>
                    </div>
                </div>
                <div>
                    {/*WHEN DETAILS*/}
                    <div className="summary-experience__infos">
                        <div className="summary-experience__infos-left">
                            <span className="summary-experience__infos--heading">{t('time.when')}</span>
                            <span className="summary-experience__infos--title">{getEventInfo()}</span>
                        </div>
                        <div className="summary-experience__infos-right" onClick={goToDates}>
                            <p
                                    style={{
                                    color: 'var(--link-color)',
                                    textDecorationColor: 'var(--link-color)',
                                }}
                            >{t('change')}</p>
                        </div>
                    </div>

                    {/*TICKETS DETAILS*/}
                    <div className="summary-experience__infos">
                        <div className="summary-experience__infos-left">
                            <span className="summary-experience__infos--heading">{t('options')}</span>
                            <span className="summary-experience__infos--title">{getTicketsInfo()}</span>

                            {
                                totalPrice > 0 &&
                                <span className="summary-experience__infos--subtitle">{getSymbolFromCurrency(expState.hotel.currency)}{(totalPrice.toFixed(2)) + ' total'}</span>
                            }

                        </div>
                        <div className="summary-experience__infos-right" onClick={goToTickets}>
                            <p
                                    style={{
                                        color: 'var(--link-color)',
                                        textDecorationColor: 'var(--link-color)',
                                }}
                            >{t('change')}</p>
                        </div>
                    </div>

                    {
                        totalPrice > 0 &&
                        <div className="summary-experience__infos bottom-mobile">
                            <div className="summary-experience__infos">
                                <div className="summary-experience__infos-left">
                                    <span className="summary-experience__infos--heading">{t('payment')}</span>
                                    <span className="summary-experience__infos--title">{t('cash or card on location')}</span>
                                </div>
                            </div>
                        </div>
                    }


                    {props.loading &&
                        <div className='booking-confirmation__loader'>
                            <Loader />
                        </div>
                    }
                </div>
            </div>

            {/*<div className='bottom-bar__new'>*/}
            {/*    /!*<div className="flex fl-direction-ro fl-align-c pointer" onClick={props.goPrevStep}>*!/*/}
            {/*    /!*    <div className="material-icons mrr-5px icon">arrow_back</div>*!/*/}
            {/*    /!*    <p className="bottom-bar__item-name">{t('close.experience')}</p>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    <div onClick={submitForm}*/}
            {/*         // style={{*/}
            {/*         //     background: expState.buttonColor && isRegistrationFormValid(expState.bookingForm, bookingFormSettings) ? `var(--link-color)` : '',*/}
            {/*         //     color: expState.hotel.fontColor && isRegistrationFormValid(expState.bookingForm, bookingFormSettings) ? `var(--link-font-color)` : '',*/}
            {/*         // }}*/}
            {/*         className={`bottom-bar__btn ${!isRegistrationFormValid(expState.bookingForm, bookingFormSettings) && 'bottom-bar__btn-inactive'}`}>*/}
            {/*        <span className='bottom-bar__btn-span'>{expState.hotel.stripeEnabled ? t('Proceed to payment') : t('confirm')}</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};
