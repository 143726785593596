import * as React from "react";
import Hotel from "../../shared/models/Hotel";
import {ExperienceModel} from "../../shared/models/ExperienceModel";
import {Helmet} from "react-helmet-async";
import {People} from "../../shared/models/People";
import {getImageSharingUrl, toSeoUrl} from "../../shared/helpers/GeneralHelper";
import {Host, HostExperience, PortalCategory, RegionDestination} from "models/portal/Portal";

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

export interface HelmetHelperModel {
    hotelNotFound: boolean;
    hostNotFound: boolean;
    experienceNotFound: boolean;
    guideNotFound: boolean;
    hotelName: string;
    regionCode: string;
    destinationCode: string;
    hotel: Hotel;
    host: Host;
    experiences: HostExperience[];
    region: RegionDestination;
    category: PortalCategory;
    destination: RegionDestination;
    experience: ExperienceModel;
    guide: People;
    home: boolean;
    error: boolean;
    lang: string;
}

export const helmetHelper: any = (helmetData: HelmetHelperModel) => {
    if (helmetData.home) {
        return (
            <Helmet>
                <title>{`Giggle.tips | Die Multi-Channel-Erlebnisplattform`}</title>
                <meta property="og:title" content={'Giggle.tips | Die Multi-Channel-Erlebnisplattform'}/>
                <meta name="description" content={'Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister.'}/>
                <meta
                    property="og:description"
                    content={'Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister.'}/>
                <meta property="og:type" content="website"/>
                <meta content="https://uploads-ssl.webflow.com/64b7092b3e5ab1466234de83/64f89f93311f233b13e2f14b_og-image%20(3).png" property="og:image"/>
                <meta content="Giggle.tips | Die Multi-Channel-Erlebnisplattform" property="twitter:title"/>
                <meta content="Fördere Direktbuchungen, heb dich von der Konkurrenz ab und steigere über deine Erlebnisse deine Sichtbarkeit. Für Touristiker und Dienstleister." property="twitter:description"/>
                <meta content="https://uploads-ssl.webflow.com/64b7092b3e5ab1466234de83/64f89f93311f233b13e2f14b_og-image%20(3).png" property="twitter:image"/>
            </Helmet>
        );
    }

    if (helmetData.category) {
        return (
            <Helmet>
                <title>{`Giggle.tips | ${helmetData.category.translation.name}`}</title>
                <meta property="og:title" content={`Giggle.tips | ${helmetData.category.translation.name}`}/>
            </Helmet>
        );
    }

    if (helmetData.experiences) {
        return (
            <Helmet>
                <title>{`Giggle.tips | Experiences`}</title>
                <meta property="og:title" content={`Giggle.tips | Experiences`}/>
            </Helmet>
        );
    }

    if (helmetData.destination) {
        return (
            <Helmet>
                <title>{`Giggle.tips | ${helmetData.destination.name}`}</title>
                <meta property="og:title" content={`Giggle.tips | ${helmetData.destination.name}`}/>
            </Helmet>
        );
    }

    if (helmetData.region) {
        return (
            <Helmet>
                <title>{`Giggle.tips | ${helmetData.region.name}`}</title>
                <meta property="og:title" content={`Giggle.tips | ${helmetData.region.name}`}/>
                </Helmet>
        );
    }


    if (helmetData.error) {
        return (
            <Helmet>
                <title>{`Error retrieving data`}</title>
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
        );
    }

    if (helmetData.hotelNotFound || helmetData.experienceNotFound || helmetData.guideNotFound) {
        return (
            <Helmet>
                <title>{`Not found`}</title>
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
        );
    }

    if (helmetData.experience) {
        const experience = helmetData.experience;
        const desc = stripHtml(experience.translation.description);
        return (
            <Helmet>
                <title>{`${experience.translation.title} - ${helmetData.hotel.name}`}</title>
                {
                    experience.noIndex && <meta name="robots" content="noindex,nofollow"/>
                }
                {/*<meta name="robots" content="all"/>*/}
                <meta property="fb:app_id" content={`186510558997154`}/>
                <meta property="og:title" content={`${experience.translation.title} - ${helmetData.hotel.name}`}/>
                <meta name="description" content={`${stripHtml(experience.translation.longDescription)}`}/>
                <meta
                    property="og:description"
                    content={`${stripHtml(experience.translation.longDescription)}`}/>
                <meta property="og:site_name" content="Giggle Hotels"/>
                <meta property="og:type" content="website"/>
                {/*<meta property="og:url" content={`https://giggle.tips/${helmetData.lang}/${helmetData.hotelName}/experience/${experience.id}`}/>*/}
                {/*<meta property="og:locale" content="de_DE"/>*/}
                {/*<meta property="og:type" content="website"/>*/}
                {/*<link rel="canonical" href={`https://giggle.tips/${helmetData.lang}/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="x-default" href={`https://giggle.tips/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="de" href={`https://giggle.tips/de/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="en" href={`https://giggle.tips/en/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="it" href={`https://giggle.tips/it/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="fr" href={`https://giggle.tips/fr/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<link rel="alternate" hrefLang="es" href={`https://giggle.tips/es/experience/${experience.id}/${toSeoUrl(experience.translation.title)}`} />*/}
                {/*<meta*/}
                {/*    property="og:image" itemProp="image"*/}
                {/*    content={getImageSharingUrl(experience.imageUrl, experience.hasSharingImage)}*/}
                {/*/>*/}
                {/*<meta*/}
                {/*    property="og:image:secure" itemProp="image"*/}
                {/*    content={getImageSharingUrl(experience.imageUrl, experience.hasSharingImage)}*/}
                {/*/>*/}
                {/*<meta property="og:image:width" content="600" />*/}
                {/*<meta property="og:image:height" content="600" />*/}
            </Helmet>
        )
    }
    if (helmetData.host || helmetData.hotel) {
        const hotel = helmetData.host ?? helmetData.hotel;
        return (
            <Helmet>
                <title>{getTitleTranslations(hotel.name, helmetData.lang)}</title>

                {
                    (helmetData.hotel || hotel.noIndex) && <meta name="robots" content="noindex,nofollow"/>
                }

                <meta property="fb:app_id" content={`186510558997154`}/>
                <meta property="og:title" content={getTitleTranslations(hotel.name, helmetData.lang)}/>
                <meta property="keywords"
                      content={`${hotel.name} ERLEBNISSE, ${hotel.name} Events, ${hotel.name} Hotel, ${hotel.name} Giggle`}/>
                <meta name="description" content={getDescriptionTranslations(hotel.name, helmetData.lang)}/>
                <meta
                    property="og:description"
                    content={getDescriptionTranslations(hotel.name, helmetData.lang)}
                />
                <meta property="og:site_name" content={hotel.name}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`https://giggle.tips/${hotel.code}`}/>
                {/*<meta property="og:locale" content="de_DE"/>*/}
                <meta property="og:type" content="website"/>
                <link rel="canonical" href={`https://giggle.tips/${helmetData.lang}/hosts/${hotel.code}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://giggle.tips/hosts/${hotel.code}`}/>
                <link rel="alternate" hrefLang="de" href={`https://giggle.tips/de/hosts/${hotel.code}`}/>
                <link rel="alternate" hrefLang="en" href={`https://giggle.tips/en/hosts/${hotel.code}`}/>
                <link rel="alternate" hrefLang="it" href={`https://giggle.tips/it/hosts/${hotel.code}`}/>
                <link rel="alternate" hrefLang="fr" href={`https://giggle.tips/fr/hosts/${hotel.code}`}/>
                <link rel="alternate" hrefLang="es" href={`https://giggle.tips/es/hosts/${hotel.code}`}/>
                <meta
                    property="og:image" itemProp="image"
                    content={hotel.coverImageUrl}
                />
                <meta
                    property="og:image:secure" itemProp="image"
                    content={hotel.coverImageUrl}
                />
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="600"/>
            </Helmet>
        )
    }
    if (helmetData.guide) {
        const guide = helmetData.guide;
        return (
            <Helmet>
                <title>{`Giggle - ${guide.firstName} ${guide.lastName}`}</title>
                <meta name="robots" content="noindex,nofollow"/>
                <meta property="fb:app_id" content={`186510558997154`}/>
                <meta property="og:title" content={`Giggle - ${guide.firstName} ${guide.lastName}`}/>
                {/*<meta name="description" content={`${guide.firstName} ${guide.lastName}, ${guide.translation.title} ${guide.translation.description}`} />*/}
                {/*<meta*/}
                {/*property="og:description"*/}
                {/*content={`${guide.firstName} ${guide.lastName}, ${guide.translation.title} ${guide.translation.description}`}*/}
                {/*/>*/}
                <meta property="og:site_name" content="Giggle Hotels"/>
                <meta property="og:type" content="website"/>
                <meta
                    property="og:image" itemProp="image"
                    content={guide.profilePicture}
                />
                <meta
                    property="og:image:secure" itemProp="image"
                    content={guide.profilePicture}
                />
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="600"/>
            </Helmet>
        )
    }
    return {};
};

export const stripHtml = (html: string) => {
    if (!html) return '';
    var regex = /(<([^>]+)>)/ig;
    return entities.decode(html.replace(regex, ""));
};

export const decodeHtml = (html: string) => {
    if (!html) return '';
    return entities.decode(html);
};

const getTitleTranslations = (hotelName: string | undefined, lang: string) => {
    switch (lang) {
        case 'en':
            return `${hotelName} Experiences`;
        case 'de':
            return `${hotelName} Erlebnisse`;
        case 'fr':
            return `${hotelName} Expériences`;
        case 'es':
            return `${hotelName} Experiencias`;
        case 'it':
            return `${hotelName} Esperienze`;
        default:
            return `${hotelName} Experiences`;
    }
};

const getDescriptionTranslations = (hotelName: string | undefined, lang: string) => {
    switch (lang) {
        case 'en':
            return `Discover the unique ${hotelName} experiences and the people behind them.`;
        case 'de':
            return `Entdecke die einzigartigen ${hotelName} Erlebnisse sowie die Menschen die dahinter stehen.`;
        case 'fr':
            return `Découvrez les expériences uniques de ${hotelName} et les personnes qui se cachent derrière elles.`;
        case 'es':
            return `Descubre las experiencias únicas de ${hotelName} y la gente detrás de ellas.`;
        case 'it':
            return `Scopri le esperienze uniche di ${hotelName} e le persone che ci sono dietro.`;
        default:
            return `Entdecke die einzigartigen ${hotelName} Erlebnisse sowie die Menschen die dahinter stehen.`;
    }
};
