import * as React from 'react';

interface ILoaderProps {
    transparentBg?: boolean
    style?: any
};

export default function (props: ILoaderProps) {

    return (
    <div>
        {
          props.transparentBg ? <div className="giggle-loader2"></div> :  <div className="giggle-loader" style={props.style}>
                  <svg className='giggle-loader__svg' viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="length" fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                  </svg>
                  <svg className='giggle-loader__svg' viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                  </svg>
                  <svg className='giggle-loader__svg' viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                  </svg>
                  <svg className='giggle-loader__svg' viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                  </svg>
              </div>
        }
    </div>
    );
};
