import { API } from 'config';

export const ExperienceVisited = async (experienceId: string, hotelCode: string) => {
    if (!experienceId) {
        return;
    }
    navigator.sendBeacon(API + `/app/gigglytics/experience-visited?experienceId=${experienceId}&hotelCode=${hotelCode}&fromApp=true`, "");
    if (window && window.location.href.includes('fbclid')) {
        (window as any).fbq('track', 'ViewContent',
            {
                content_ids: [experienceId],
                eventref: 'fb_oea' // or set to empty string
            }
        );
    }
};

export const ExperienceInitiateCheckout = async (experienceId: string) => {
    if (!experienceId) {
        return;
    }
    if (window && window.location.href.includes('fbclid')) {
        (window as any).fbq('track', 'InitiateCheckout',
            {
                content_ids: [experienceId],
                eventref: 'fb_oea' // or set to empty string
            }
        );
    }
};

export const ExperiencePurchase = async (experienceId: string, ticketsNo: number, totalPrice:  number) => {
    if (!experienceId) {
        return;
    }
    if (window && window.location.href.includes('fbclid')) {
        (window as any).fbq('track', 'Purchase',
            {
                content_ids: [experienceId],
                eventref: 'fb_oea', // or set to empty string
                currency: 'EUR',  // your currency string value goes here
                num_items: ticketsNo, // your number of tickets purchased value goes here
                value: totalPrice, // your total transaction value goes here
            }
        );
    }
};

export const HotelVisited = async (hotelId: string, experienceId: string | null = null, peopleId: string | null = null) => {
    if (!hotelId && !experienceId) {
        return;
    }
    const hotelVisited_ = sessionStorage.getItem(`GIGGLhotelVisited${hotelId}`);
    if (!hotelVisited_) {
        await hotelVisited(hotelId, experienceId);
        sessionStorage.setItem(`GIGGLhotelVisited${hotelId}`, 'true');
    }
};

export const HotelVisited2 = async (hotelCode: string) => {
    const hotelVisited_ = sessionStorage.getItem(`GIGGLhotelVisited${hotelCode}`);
    if (!hotelVisited_) {
        let queryParams = `hotelCode=${hotelCode}&fromApp=true`;
        navigator.sendBeacon(API + `/app/gigglytics/hotel-visited2?${queryParams}`, "");
        sessionStorage.setItem(`GIGGLhotelVisited${hotelCode}`, 'true');
    }
};

const hotelVisited = async (hotelId: string, experienceId: string | null = null, peopleId: string | null = null) => {
    let queryParams = `hotelId=${hotelId}&fromApp=true`;
    if (experienceId) {
        queryParams += `&experienceId=${experienceId}`;
    }
    if (peopleId) {
        queryParams += `&peopleId=${peopleId}`;
    }
    navigator.sendBeacon(API + `/app/gigglytics/hotel-visited?${queryParams}`, "");
};


export const PeopleVisited = async (peopleId: string, hotelCode: string) => {
    if (!peopleId) {
        return;
    }
    navigator.sendBeacon(API + `/app/gigglytics/people-visited?peopleId=${peopleId}&fromApp=true&hotelCode=${hotelCode}`, "");
};

export const timeSession = (hotelCode: string, seconds: number) => {
    navigator.sendBeacon(API + `/app/gigglytics/time-session?hotelCode=${hotelCode}&seconds=${seconds}&fromApp=true`, "");
};
