import * as React from "react";
import {connect, useSelector} from "react-redux";
import './../../assets/css/v2/filter-carousel.css';
import {Link} from "react-router-dom";
import {getLocale} from "store/app/selectors";
import {useLocation} from "react-router";

interface Filter {
    label: string,
    url: string
}
interface IProps {
    type: string,
    regionParam?: string,
    destinationParam?: string,
    filters: Filter[]
}

function FilterCarousel(props: IProps) {
    const locale = useSelector(getLocale);
    const location = useLocation();

    const getLink = (url: string) => {
        switch (props.type) {
            case 'category':
                return getCategoryLink(url);
            case 'region': return `/${locale}/experiences/r/${url}`;
            case 'destination': return getDestinationLink(url);
        }

        return '#'
    }

    const getCategoryLink = (url: string): string => {
        if (props.regionParam && props.destinationParam) {
            return `/${locale}/experiences/r/${props.regionParam}/${props.destinationParam}/c--${url}`;
        } else if (location.pathname.includes('/r/')) {
            return `/${locale}/experiences/r/${props.regionParam}/c--${url}`;
        } else if (location.pathname.includes('/d/')) {
            return `/${locale}/experiences/d/${props.destinationParam}/c--${url}`;
        } else {
            return `/${locale}/experiences/c/${url}`;
        }
    }

    const getDestinationLink = (url: string): string => {
        if (props.regionParam) {
            return `/${locale}/experiences/r/${props.regionParam}/${url}`;
        } else {
            return `/${locale}/experiences/d/${url}`;
        }
    }

    return (
        <div className='filter-carousel-container'>
            <div className="filter-carousel-label">REFINE:</div>
            <div className="filter-carousel">
                {
                    props.filters.map(filter => {
                        return (<Link className="filter-carousel-item" to={getLink(filter.url)}>{filter.label}</Link>);
                    })
                }
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(mapStateToProps)(FilterCarousel);
