import * as React from "react";
import {Stream} from "../../models/Stream";
import ChevronLeft from "../../assets/icons/chevron-left-white.svg";
import ChevronRight from "../../assets/icons/chevron-right-white.svg";
import {ExperienceMini} from "../../models/ExperienceModel";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getLocale} from "../../store/app/selectors";
import {toSeoUrl} from "../../helpers/GeneralHelper";
import Loader from "../common/Loader";
import {fetchStreamService} from "../../services/StreamService";
import getSymbolFromCurrency from "currency-symbol-map";

interface IStreamProps {
    stream: Stream,
    hotelCode: string,
    currency: string
};

export default function (props: IStreamProps) {
    const locale = useSelector(getLocale);
    let scrollerRef = useRef<HTMLDivElement>(null);
    const [loadingExperiences, setLoadingExperiences] = useState(false);

    useEffect(() => {
        if (props.stream && (props.stream.scrollLeft as number) > 0) {
            scroll(props.stream.scrollLeft as number, true);
        }
    }, []);

    const scrollLeft = () => {
        scroll(-700);
    };

    const scrollRight = () => {
        scroll(700);
    };


    const scroll = (number: number, immediate = false) => {
        let scroller = scrollerRef.current;
        if (!scroller) { return; }

        if (scroller.scrollLeft < scroller.scrollWidth) {
            scroller.scrollTo({
                left: scroller.scrollLeft + number,
                behavior: immediate ? 'auto' : 'smooth',
            });
            props.stream.scrollLeft = scroller.scrollLeft;
        }
    };

    const onScroll = () => {
        let scroller: HTMLDivElement | any = scrollerRef.current;
        props.stream.scrollLeft = scroller.scrollLeft;
        if (scroller.clientWidth + scroller.scrollLeft <= (scroller.scrollWidth - 100)) return;

        if (loadingExperiences) { return }
        if (props.stream.experiencesLoadedComplete) { return }

        setLoadingExperiences(true);

        const loadNextExperiences = async () => {
            let experiences = await fetchStreamService(props.stream.id || '', locale, props.stream.experiences.length, 15);

            if (experiences === 404) {
                props.stream.experiencesLoadedComplete = true;
            }
            if (experiences !== 404 && experiences !== 500) {
                if (props.stream.experiences) {
                    props.stream.currentOffset = (props.stream.currentOffset || props.stream.experiences.length) + 15;
                    props.stream.experiences.push(...(experiences as any));
                }
            }

            setLoadingExperiences(false);
        }

        loadNextExperiences();
    }

    return (
        <div className="stream stream-simple">
            <div className="stream__top ">
                <h2 className="stream__title">{props.stream.translation.name}</h2>
            </div>
            <div className="stream__activities" ref={scrollerRef} onScroll={onScroll}>
                <div className="stream__date-groups">
                    <div className="stream__group-activities">
                        {
                            props.stream.experiences.map((experience: ExperienceMini) => {
                                return (
                                    <Link key={`experience${experience.id}`}
                                          to={{
                                              pathname: `${locale == 'en' ? '' : '/'+locale}/experiences/${toSeoUrl(experience.translation.categoryName, true)}/${experience.id}/${toSeoUrl(experience.translation.title)}?hc=${props.hotelCode}`,
                                              state: {experienceId: experience.id}
                                          }}
                                          className="stream-activity">
                                            <img src={experience.imageUrl} loading={'lazy'} alt={`${experience.translation.title} image`}/>
                                            {(experience.minMaxPrice || 0) > 0 && <div className="stream-activity__top">{experience.minMaxPrice.includes('.') ? Number(experience.minMaxPrice).toFixed(2) : experience.minMaxPrice} {getSymbolFromCurrency(props.currency)}</div>}
                                            <h3 className="stream-activity__title">
                                                {experience.translation.title}
                                            </h3>
                                            <div className="stream-activity__shadow"/>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    loadingExperiences &&
                    <div style={{ width: 'fit-content', alignSelf: 'center'}}>
                        <Loader />
                    </div>
                }
            </div>

            <div className="stream__arrow" onClick={scrollLeft}>
                <img className='' src={ChevronLeft} width={30} height={30} alt=""/>
            </div>

            <div className="stream__arrow stream__arrow--right" onClick={scrollRight}>
                <img className='' src={ChevronRight} width={30} height={30} alt=""/>
            </div>
        </div>
    );
}
