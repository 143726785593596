import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ExperienceDetails from "../shared/ExperienceDetails";
import ExperienceGuidesAndRelated from "../shared/ExperienceGuidesAndRelated";
import ExperienceTickets from "../shared/ExperienceTickets";
import {useContext} from "react";
import ExperienceContext from "../../../ExperienceContext";
import {
    getRequestedTicketsTotalPrice,
    getTotalTicketsRequested,
    showBottomBar
} from "../../../helpers/ExperienceHelper";
import getSymbolFromCurrency from "currency-symbol-map";
interface IProps {
    goNextStep?: () => void
};

export default function (props: IProps) {
    const {expState} = useContext(ExperienceContext);
    const { t } = useTranslation();
    const [showAnimation, setAnimation] = useState(false);

    const bookingEndEl: any = useRef(null);

    useEffect(() => {
        if(__BROWSER__) {
            let scrollingBody = document.getElementById('parent-experience-id') as HTMLElement;
            let scrollingBodyMobile = document.getElementById('parent-experience-popup-id') as HTMLElement;

            const { offsetTop } = bookingEndEl.current;
            scrollingBody.addEventListener('scroll', () => {
                if (scrollingBody.scrollTop > offsetTop - 400) {
                    setAnimation(true)
                }
            });
            scrollingBodyMobile.addEventListener('scroll', () => {
                if (scrollingBodyMobile.scrollTop > offsetTop - 400) {
                    setAnimation(true)
                }
            });
        }
    }, []);

    const navigateToBookingPart = () => {
        bookingEndEl.current.scrollIntoView({behavior: 'smooth'})
    };

    return (
        <>
            <ExperienceDetails navigateToBookingPart={navigateToBookingPart} />

            <div ref={bookingEndEl}/>

            { expState.experience.registrationRequired && <ExperienceTickets showAnimation={showAnimation} /> }

            <ExperienceGuidesAndRelated
                hotel={expState.hotel}
                category={expState.experience.category ? expState.experience.category.translation.name: 'uncategorized'}
                people={expState.experience.people}
                relatedExperiences={expState.experience.relatedExperiences.filter(x => x.id != expState.experience.id)}/>

            {/*<div className={'bottom-bar ' + (showBottomBar(expState) ? 'bottom-bar--active' : '')}>*/}
            {/*    <div className="flex fl-direction-row fl-justify-start fl-align-c">*/}
            {/*        <div className={expState.experience.imageUrl ? 'bottom-bar__profile-picture' : 'd-none'}>*/}
            {/*            <img src={expState.experience.imageUrl} alt=""/>*/}
            {/*        </div>*/}
            {/*        <div className="flex fl-direction-co fl-align-s">*/}
            {/*            <div className='bottom-bar__item mrr-20px flex fl-direction-ro fl-align-c'>*/}
            {/*                <div className='bottom-bar__item-name mrr-5px'>{getTotalTicketsRequested(expState.tickets)}</div>*/}
            {/*                <div*/}
            {/*                    className='bottom-bar__item-name'>{getTotalTicketsRequested(expState.tickets) > 1 ? t('tickets.selected') : t('ticket.selected')}</div>*/}
            {/*            </div>*/}
            {/*            <div className="flex fl-direction-ro fl-align-c">*/}
            {/*                <div className='bottom-bar__item-value mrr-5px'><span className="mrr-5px">{getSymbolFromCurrency(expState.hotel.currency)}</span>{getRequestedTicketsTotalPrice(expState.tickets).toFixed(2)}</div>*/}
            {/*                <div className='bottom-bar__item-value'>{t('total')}</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'bottom-bar__btn'}*/}
            {/*         // style={{*/}
            {/*         //     background: expState.buttonColor ? `var(--link-color)` : '',*/}
            {/*         //     color: expState.hotel.fontColor ? `var(--link-font-color)` : '',*/}
            {/*         // }}*/}
            {/*         onClick={props.goNextStep}>*/}
            {/*        <span className='bottom-bar__btn-span'>{t('next')}</span>*/}
            {/*        <div className="material-icons mrl-10px">arrow_forward</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};
