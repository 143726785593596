import {PortalAction, PortalActionTypes} from "store/actions/PortalActions/PortalActions";
import {SearchModel} from "models/portal/Portal";


const initialState: SearchModel = {} as SearchModel;

export default (state = initialState, action: PortalAction) => {
    switch (action.type) {
        case PortalActionTypes.FETCH_SEARCH_SUGGESTIONS:
            const search = (action.payload as SearchModel)

            return { ...search };
        default:
            return state;
    }
}
