import {GuideActionTypes} from "./GuideActionTypes";
import {GuideAction} from "./GuideActions";
import {People} from "../../../models/People";

export const fetchGuideAction = (guide: People) => {
    const FetchGuideAction: GuideAction = {
        type: GuideActionTypes.FETCH_GUIDE,
        payload: guide
    };

    return FetchGuideAction;
};


export const guideNotFoundAction = () => {
    const GuideNotFoundAction: GuideAction = {
        type: GuideActionTypes.GUIDE_NOT_FOUND,
        payload: {}
    };

    return GuideNotFoundAction;
};


export const guideLoadingErrorAction = () => {
    const GuideLoadingErrorAction: GuideAction = {
        type: GuideActionTypes.GUIDE_LOADING_ERROR,
        payload: {}
    };

    return GuideLoadingErrorAction;
};
